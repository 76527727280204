import { Grid } from "@material-ui/core";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import ProfileLembaga from "../ProfileLembaga_Com";

export default function GridView(props) {
  const history = useHistory();
  return (
    <>
    {/* <Grid container spacing={2}> */}
      {props.dataLembaga.loading
        ? ""
        : props.dataLembaga.data.map((data, index) => {
            return (
              <Grid item xs={12} sm={4} md={6} lg={3} key={index}>
                <ProfileLembaga
                  name={data.lsm.namaOrganisasi}
                  image={data.lsm.logoProfile}
                  desc={data.desc}
                  city={data.lsm.kota}
                  link={data.id}
                  dataProfile={props.dataProfile}
                  panggilan={data.namaPanggilan}
                  index={index}
                  pages={props.dataLembaga.pages}
                />
              </Grid>
            );
          })}
    </>
  );
}
