import React, {useEffect, useState} from "react";
import {Button, CircularProgress, createStyles, Grid, InputAdornment, makeStyles, TextField,} from "@material-ui/core";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import {Controller, useForm} from "react-hook-form";
import {useStateMachine} from "little-state-machine";
import updateAction from "./UpdateAction_Form";
import Delete from "@material-ui/icons/Delete";
import {DropzoneArea} from "material-ui-dropzone";
import {AddCircleOutlined} from "@material-ui/icons";

import configData from '../../config.json'

const Step3 = ({nextStep, prevStep, className, dataLSM}) => {
        const initData = JSON.parse(localStorage.getItem('data'))
        const {handleSubmit, control, setValue, reset} = useForm();
        const [fieldsPembina, setFieldsPembina] = useState([
            {
                namaBadanPembina: "",
                identitasBadanPembina: null,
            },
        ]);
        const [fieldsPengawas, setFieldsPengawas] = useState([
            {
                namaBadanPengawas: "",
                identitasBadanPengawas: null,
            },
        ]);
        const [isLoading, setIsLoading] = useState(true);
        const [wait, setWait] = useState(false);
        const {actions, state} = useStateMachine({updateAction});

        const service = {
            uploadPembina: async (ex, pembina, files) => {
                return new Promise((resolve, reject) => {
                    if (files && files[0] && files[0].type !== "text/html") {
                        try {
                            const formData = new FormData();
                            formData.append("file", files[0]);
                            fetch(
                                `${configData.BASE_URL}/user/lsm/upload/${pembina.id}/identitas_badan_pembina`,
                                {
                                    method: "POST",
                                    credentials: "include",
                                    body: formData,
                                }
                            )
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    ex.lsm.pembina.forEach((pembina) => {
                                        if (pembina.namaBadanPembina === responseJson.namaBadanPembina)
                                            pembina.identitasBadanPembina = responseJson.identitasBadanPembina
                                    })
                                    localStorage.setItem('data', JSON.stringify(ex))
                                    resolve()
                                });
                        } catch (error) {
                            reject(error)
                        }
                    } else resolve()
                })
            },
            uploadPengawas: async (ex, pengawas, files) => {
                return new Promise(((resolve, reject) => {
                    if (files && files[0] && files[0].type !== "text/html") {
                        try {
                            const formData = new FormData();
                            formData.append("file", files[0]);
                            fetch(
                                `${configData.BASE_URL}/user/lsm/upload/${pengawas.id}/identitas_badan_pengawas`,
                                {
                                    method: "POST",
                                    credentials: "include",
                                    body: formData,
                                }
                            )
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    ex.lsm.pengawas.forEach((pengawas) => {
                                        if (pengawas.namaBadanPengawas === responseJson.namaBadanPengawas)
                                            pengawas.identitasBadanPengawas = responseJson.identitasBadanPengawas
                                    })
                                    localStorage.setItem('data', JSON.stringify(ex))
                                    resolve()
                                });
                        } catch (error) {
                            reject(error)
                        }
                    } else resolve()
                }))
            },
            identitasKetua: async (ex, files) => {
                return new Promise(((resolve, reject) => {
                    if (files && files[0] && files[0].type !== "text/html") {
                        try {
                            const formData = new FormData();
                            formData.append("file", files[0]);
                            fetch(
                                `${configData.BASE_URL}/user/lsm/upload/identitas_ketua`,
                                {
                                    method: "POST",
                                    credentials: "include",
                                    body: formData,
                                }
                            )
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    ex.lsm.identitasKetua = responseJson.lsm.identitasKetua
                                    localStorage.setItem('data', JSON.stringify(ex))
                                    resolve()
                                });
                        } catch (error) {
                            reject(error)
                        }
                    } else resolve()
                }))
            },
            identitasSekertaris: async (ex, files) => {
                return new Promise(((resolve, reject) => {
                    if (files && files[0] && files[0].type !== "text/html") {
                        try {
                            const formData = new FormData();
                            formData.append("file", files[0]);
                            fetch(
                                `${configData.BASE_URL}/user/lsm/upload/identitas_sekertaris`,
                                {
                                    method: "POST",
                                    credentials: "include",
                                    body: formData,
                                }
                            )
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    ex.lsm.identitasSekertaris = responseJson.lsm.identitasSekertaris
                                    localStorage.setItem('data', JSON.stringify(ex))
                                    resolve()
                                });
                        } catch (error) {
                            reject(error)
                        }
                    } else resolve()
                }))
            },
            identitasBendahara: async (ex, files) => {
                return new Promise(((resolve, reject) => {
                    if (files && files[0] && files[0].type !== "text/html") {
                        try {
                            const formData = new FormData();
                            formData.append("file", files[0]);
                            fetch(
                                `${configData.BASE_URL}/user/lsm/upload/identitas_bendahara`,
                                {
                                    method: "POST",
                                    credentials: "include",
                                    body: formData,
                                }
                            )
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    ex.lsm.identitasBendahara = responseJson.lsm.identitasBendahara
                                    localStorage.setItem('data', JSON.stringify(ex))
                                    resolve()
                                });
                        } catch (error) {
                            reject(error)
                        }
                    } else resolve()
                }))
            },
            strukturOrganisasi: async (ex, files) => {
                return new Promise(((resolve, reject) => {
                    if (files && files[0] && files[0].type !== "text/html") {
                        try {
                            const formData = new FormData();
                            formData.append("file", files[0]);
                            fetch(
                                `${configData.BASE_URL}/user/lsm/upload/struktur_organisasi`,
                                {
                                    method: "POST",
                                    credentials: "include",
                                    body: formData,
                                }
                            )
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    ex.lsm.strukturOrganisasi = responseJson.lsm.strukturOrganisasi
                                    localStorage.setItem('data', JSON.stringify(ex))
                                    resolve()
                                });
                        } catch (error) {
                            reject(error)
                        }
                    } else resolve()
                }))
            },
            put: async (ex) => {
                return new Promise(((resolve, reject) => {
                    try {
                        fetch(`${configData.BASE_URL}/user/lsm`, {
                            method: "PUT",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(ex),
                        })
                            .then((response) => response.json())
                            .then((responseJson) => {
                                localStorage.setItem('data', JSON.stringify(responseJson))
                                resolve(responseJson)
                            })

                    } catch (error) {
                        reject(error)
                    }
                }))

            }
        }

        // for continue event listener
        const onSubmit = async (data) => {
            setWait(true);
            let ex = JSON.parse(localStorage.getItem('data'))

            let dataPembina = fieldsPembina.map((pembina) => {
                let exPembina = ex.lsm.pembina.find((el) => {
                    return el.namaBadanPembina === pembina.namaBadanPembina;
                });
                if (exPembina === undefined) {
                    return {
                        namaBadanPembina: pembina.namaBadanPembina
                    };
                } else {
                    exPembina.namaBadanPembina = pembina.namaBadanPembina
                    return exPembina
                }
            })

            let dataPengawas = fieldsPengawas.map((pengawas) => {
                let exPengawas = ex.lsm.pengawas.find((el) => {
                    return el.namaBadanPengawas === pengawas.namaBadanPengawas;
                })

                if (exPengawas === undefined) {
                    return {
                        namaBadanPengawas: pengawas.namaBadanPengawas
                    }
                } else {
                    exPengawas.namaBadanPengawas = pengawas.namaBadanPengawas;
                    return exPengawas
                }
            })

            Promise.resolve()
                .then(() => service.identitasKetua(ex, data.identitasKetua))
                .then(() => service.identitasSekertaris(ex, data.identitasSekertaris))
                .then(() => service.identitasBendahara(ex, data.identitasBendahara))
                .then(() => service.strukturOrganisasi(ex, data.strukturOrganisasi))
                .then(() => {
                    ex.lsm.namaKetua = data.namaKetua ? data.namaKetua : ex.lsm.namaKetua;
                    ex.lsm.namaSekertaris = data.namaSekertaris ? data.namaSekertaris : ex.lsm.namaSekertaris;
                    ex.lsm.namaBendahara = data.namaBendahara ? data.namaBendahara : ex.lsm.namaBendahara;
                    ex.lsm.pembina = dataPembina ? dataPembina : ex.lsm.pembina
                    ex.lsm.pengawas = dataPengawas ? dataPengawas : ex.lsm.pengawas
                    return service.put(ex)
                })
                .then((response) => {
                    let run = response.lsm.pembina.map((pembina) => {
                        let files = fieldsPembina.find((upload) => {
                            return upload.namaBadanPembina === pembina.namaBadanPembina;
                        })
                        return service.uploadPembina(ex, pembina, files.identitasBadanPembina)
                    });

                    Promise.all(run)
                    return response;
                })
                .then((response) => {
                    let run = response.lsm.pengawas.map((pengawas) => {
                        let files = fieldsPengawas.find((upload) => {
                            return upload.namaBadanPengawas === pengawas.namaBadanPengawas;
                        })
                        return service.uploadPengawas(ex, pengawas, files.identitasBadanPengawas);
                    })

                    return Promise.all(run)
                })
                .then(() => {
                    return new Promise((resolve => {
                        actions.updateAction({});
                        window.scrollTo(0, 0);
                        nextStep();
                        setWait(false);
                        resolve()
                    }))
                })
        };

        const Previous = (e) => {
            e.preventDefault();
            prevStep();
        };

        const useStyles = makeStyles((theme) =>
            createStyles({
                previewChip: {
                    minWidth: 160,
                    maxWidth: 210,
                },
            })
        );

        const setProfile = () => {
            let ex = JSON.parse(localStorage.getItem('data'))
            setValue("namaKetua", ex.lsm.namaKetua);
            setValue("namaSekertaris", ex.lsm.namaSekertaris);
            setValue("namaBendahara", ex.lsm.namaBendahara);


            let pembina = ex.lsm.pembina.map((pembina) => {
                return {
                    namaBadanPembina: pembina.namaBadanPembina,
                    identitasBadanPembina: pembina.identitasBadanPembina
                }
            })

            if (pembina.length > 0) {
                setFieldsPembina(pembina)
            }

            let pengawas = ex.lsm.pengawas.map((pengawas) => {
                return {
                    namaBadanPengawas: pengawas.namaBadanPengawas,
                    identitasBadanPengawas: pengawas.identitasBadanPengawas
                }
            })

            if (pengawas.length > 0) {
                setFieldsPengawas(pengawas)
            }

        };

        useEffect(() => {
            setProfile();
        }, [dataLSM]);

        const classes = useStyles();

        const handleInputChangePembina = (index, event) => {
            const values = [...fieldsPembina];
            values[index].namaBadanPembina = event.target.value;
            setFieldsPembina(values);
        };

        const handleUploadChangePembina = (index, files) => {
            const values = [...fieldsPembina];
            values[index].identitasBadanPembina = files;
            setFieldsPembina(values);
        };

        const handleInputChangePengawas = (index, event) => {
            const values = [...fieldsPengawas];
            values[index].namaBadanPengawas = event.target.value;
            setFieldsPengawas(values);
        };

        const handleUploadChangePengawas = (index, files) => {
            const values = [...fieldsPengawas];
            values[index].identitasBadanPengawas = files;
            setFieldsPengawas(values);
        };

        return (
            <form onSubmit={handleSubmit(onSubmit)} className={className + " struktur"}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item={true} xs={12} md={6}>
                        <h3>Ketua</h3>
                        <Controller
                            name="namaKetua"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="outlined"
                                    type="text"
                                    placeholder="Nama Ketua"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <Controller
                            control={control}
                            name="identitasKetua"
                            render={({field: {onChange}}) => (
                                <DropzoneArea
                                    initialFiles={[
                                        dataLSM.lsm.identitasKetua ? dataLSM.lsm.identitasKetua : null,
                                    ]}
                                    showPreviews={false}
                                    showPreviewsInDropzone={true}
                                    filesLimit={1}
                                    dropzoneText="Pilih file identitas ketua"
                                    onChange={onChange}
                                    useChipsForPreview
                                    previewGridProps={{
                                        container: {spacing: 1, direction: "row"},
                                    }}
                                    previewChipProps={{
                                        classes: {root: classes.previewChip},
                                    }}
                                    previewText="File yang dipilih :"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <h3>Sekretaris</h3>
                        <Controller
                            name="namaSekertaris"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="outlined"
                                    type="text"
                                    placeholder="Nama Sekretaris"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <Controller
                            control={control}
                            name="identitasSekertaris"
                            render={({field: {onChange}}) => (
                                <DropzoneArea
                                    initialFiles={[
                                        dataLSM.lsm.identitasSekertaris ? dataLSM.lsm.identitasSekertaris : null,
                                    ]}
                                    showPreviews={false}
                                    showPreviewsInDropzone={true}
                                    filesLimit={1}
                                    dropzoneText="Pilih file identitas sekretaris"
                                    onChange={onChange}
                                    useChipsForPreview
                                    previewGridProps={{
                                        container: {spacing: 1, direction: "row"},
                                    }}
                                    previewChipProps={{
                                        classes: {root: classes.previewChip},
                                    }}
                                    previewText="File yang dipilih :"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <h3>Bendahara</h3>
                        <Controller
                            name="namaBendahara"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="outlined"
                                    type="text"
                                    placeholder="Nama Bendahara"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                        <Controller
                            control={control}
                            name="identitasBendahara"
                            render={({field: {onChange}}) => (
                                <DropzoneArea
                                    initialFiles={[
                                        dataLSM.lsm.identitasBendahara ? dataLSM.lsm.identitasBendahara : null,
                                    ]}
                                    showPreviews={false}
                                    showPreviewsInDropzone={true}
                                    filesLimit={1}
                                    dropzoneText="Pilih file identitas bendahara"
                                    onChange={onChange}
                                    useChipsForPreview
                                    previewGridProps={{
                                        container: {spacing: 1, direction: "row"},
                                    }}
                                    previewChipProps={{
                                        classes: {root: classes.previewChip},
                                    }}
                                    previewText="File yang dipilih :"
                                />
                            )}
                        />

                    </Grid>

                    {fieldsPembina.map((data, index) => (
                        <>
                            <Grid item={true} xs={12} md={6}>
                                <h3>
                                    {initData.lsm.badanHukum === "Perkumpulan" ? `Pendiri ${index + 1}` : `Pembina ${index + 1}`}
                                    <Delete
                                        onClick={() => {
                                            let newFields = [...fieldsPembina];
                                            newFields.splice(index, 1);
                                            setFieldsPembina(newFields);
                                            reset();
                                        }}
                                        style={{cursor: "pointer"}}
                                    />
                                </h3>
                                <Controller
                                    name={"namaBadanPembina"}
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            className={"input-login"}
                                            variant="outlined"
                                            type="text"
                                            value={data.namaBadanPembina}
                                            onChange={(event) => handleInputChangePembina(index, event)}
                                            placeholder={initData.lsm.badanHukum === "Perkumpulan" ? `Pendiri ${index + 1}` : 'Pembina'}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonOutlineIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"identitasBadanPembina"}
                                    render={({field}) => (
                                        <DropzoneArea
                                            initialFiles={[
                                                initData.lsm.pembina && initData.lsm.pembina.length > 0 ?
                                                    initData.lsm.pembina[index] !== undefined ?
                                                        initData.lsm.pembina[index].identitasBadanPembina
                                                        : null
                                                    : null
                                            ]}
                                            showPreviews={false}
                                            showPreviewsInDropzone={true}
                                            filesLimit={1}
                                            dropzoneText={
                                                initData.lsm.badanHukum === "Perkumpulan" ? `Pilih file identitas Pendiri ${index + 1}` : "Pilih file identitas pembina" + `${index + 1}`
                                            }
                                            onChange={(files) => handleUploadChangePembina(index, files)}
                                            useChipsForPreview
                                            previewGridProps={{
                                                container: {spacing: 1, direction: "row"},
                                            }}
                                            previewChipProps={{
                                                classes: {root: classes.previewChip},
                                            }}
                                            previewText="File yang dipilih :"
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    ))}

                    {fieldsPembina.length < 5 ? (
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} className="add-identitas">
                                <h2>{initData.lsm.badanHukum === "Perkumpulan" ? `Pilih file identitas Pendiri` : "Pilih file identitas pembina"}</h2>
                                <AddCircleOutlined
                                    onClick={() =>
                                        setFieldsPembina([
                                            ...fieldsPembina,
                                            {
                                                namaBadanPembina: "",
                                                identitasBadanPembina: null,
                                            },
                                        ])
                                    }
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        ""
                    )}

                    {fieldsPengawas.map((data, index) => (
                        <>
                            <Grid item={true} xs={12} md={6}>
                                <h3>
                                    {`Pengawas ${index + 1}`}
                                    <Delete
                                        onClick={() => {
                                            let newFields = [...fieldsPengawas];
                                            newFields.splice(index, 1);
                                            setFieldsPengawas(newFields);
                                            reset();
                                        }}
                                        style={{cursor: "pointer"}}
                                    />
                                </h3>

                                <Controller
                                    name={"namaBadanPengawas"}
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            className={"input-login"}
                                            variant="outlined"
                                            type="text"
                                            value={data.namaBadanPengawas}
                                            onChange={(event) => handleInputChangePengawas(index, event)}
                                            placeholder={"Pengawas" + `${index + 1}`}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonOutlineIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"identitasBadanPengawas"}
                                    render={({field}) => (
                                        <DropzoneArea
                                            initialFiles={[
                                                initData.lsm.pengawas && initData.lsm.pengawas.length > 0 ?
                                                    initData.lsm.pengawas[index] !== undefined ?
                                                        initData.lsm.pengawas[index].identitasBadanPengawas
                                                        : null
                                                    : null
                                            ]}
                                            showPreviews={false}
                                            showPreviewsInDropzone={true}
                                            filesLimit={1}
                                            dropzoneText={
                                                "Pilih file identitas pengawas" + `${index + 1}`
                                            }
                                            onChange={(files) => handleUploadChangePengawas(index, files)}
                                            useChipsForPreview
                                            previewGridProps={{
                                                container: {spacing: 1, direction: "row"},
                                            }}
                                            previewChipProps={{
                                                classes: {root: classes.previewChip},
                                            }}
                                            previewText="File yang dipilih :"
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    ))}

                    {fieldsPengawas.length < 5 ? (
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} className="add-identitas">
                                <h2>Tambah Pengawas</h2>
                                <AddCircleOutlined
                                    onClick={() =>
                                        setFieldsPengawas([
                                            ...fieldsPengawas,
                                            {
                                                namaBadanPengawas: "",
                                                identitasBadanPengawas: null,
                                            },
                                        ])
                                    }
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item={true} xs={12}>
                        <h3>Struktur Organisasi</h3>
                        <Controller
                            control={control}
                            name="strukturOrganisasi"
                            render={({field: {onChange}}) => (
                                <DropzoneArea
                                    initialFiles={[
                                        dataLSM.lsm.strukturOrganisasi ? dataLSM.lsm.strukturOrganisasi : null,
                                    ]}
                                    showPreviews={false}
                                    showPreviewsInDropzone={true}
                                    filesLimit={1}
                                    maxFileSize={800000}
                                    dropzoneText="Pilih file (Maksimal 8 MB)"
                                    onChange={onChange}
                                    useChipsForPreview
                                    previewGridProps={{
                                        container: {spacing: 1, direction: "row"},
                                    }}
                                    previewChipProps={{
                                        classes: {root: classes.previewChip},
                                    }}
                                    previewText="File yang dipilih :"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12} className="btn-login">
                        <Button
                            onClick={Previous}
                            variant="contained"
                            color="primary"
                            disabled={wait}>
                            Prev
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={wait}>
                            Next
                            {wait ? (
                                <CircularProgress size={10} style={{marginLeft: 10}}/>
                            ) : (
                                ""
                            )}
                        </Button>{" "}
                    </Grid>
                </Grid>
            </form>
        );
    }
;

export default Step3;
