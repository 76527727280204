import React, {useEffect, useState} from "react";
import Navbar from "../Components/NavigationBar_Com";
import UserData from "../Components/UserData_Com";
import {Grid} from "@material-ui/core";
import CardBerita from "../Components/CardBerita_Com";
import Footer from "../Components/Footer_Com";

import configData from "../config.json";
import ReactPaginate from "react-paginate";


function Berita_Page(props) {
    let limit = 6;
    const [dataBerita, setDataBerita] = useState([]);
    const [pages, setPages] = useState(props.history.location.state?.pages || 0)
    useEffect(() => {
        getDataBerita();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [pageCount, setpageCount] = useState(0);
    const getDataBerita = async () => {
        try {
            let res = await fetch(
                `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=Berita&page=${pages}&size=${limit}&sort=createdDate,desc`
            );
            const data = await res.json();
            const total = res.headers.get("x-total-count");
            setpageCount(Math.ceil(total / limit));
            setDataBerita(data);
            setPages(0)
        } catch (error) {
        }
    };

    const fetchData = async (currentPage) => {
        const res = await fetch(
            `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=Berita&page=${currentPage}&size=${limit}`,
            {
                method: "GET",
            }
        );
        return await res.json();
    };

    const handlePageClick = async (data) => {

        let currentPage = data.selected + 1 - 1;
     
        const commentsFormServer = await fetchData(currentPage);
        setPages(currentPage);
        setDataBerita(commentsFormServer);
    };
    return (
      <>
        <Navbar />
        <div className="main-database">
          <div className="container">
            <div className="database-inner">
              <Grid container spacing={5}>
                <Grid item xs={12} md={3}>
                  <UserData />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={2}>
                    {dataBerita.map((data, index) => {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <CardBerita
                            headline={data.headLine}
                            slug={data.slug}
                            desc={data.deskripsi}
                            image={data.image}
                            artikelTag={data.artikelTag}
                            id={data.id}
                            pages={pages}
                          />
                        </Grid>
                      );
                    })}
                    <Grid container justifyContent="center" alignItems="center">
                      <ReactPaginate
                        previousLabel={<p>&laquo;</p>}
                        nextLabel={<p>&raquo;</p>}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        initialPage={props.history.location.state?.pages || 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <footer style={{ background: "white", width: "100%", float: "left" }}>
          <div className="footer-sec">
            <div className="container">
              <Footer />
            </div>
          </div>
        </footer>
      </>
    );
}

export default Berita_Page;
