import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Navbar from "../Components/NavigationBar_Com";
import UserData from "../Components/UserData_Com";
import Footer from "../Components/Footer_Com";
import PostTop from "../Components/PostTop_Com";
import Article from "../Components/Article_Com";
import DaftarLembaga from "../Components/DaftarLembaga_Com";

import configData from "../config.json";
import ReactPaginate from "react-paginate";
import { getSumberDaya } from "../Logic/APIHandler_Logic";
import Lkpp from "../Assets/Logo/LKPP.png";
import Ksi from "../Assets/Logo/KSI.png";
import SDM from "../Components/SDM_Com";

function LinkLSM_Page(props) {
  const [dataSumberDaya, setDataSumberDaya] = useState([]);
  const [dataBerita, setDataBerita] = useState([]);
  const [pages, setPages] = useState(props.history.location.state?.pages || 0);
  const [dataBeritaPin, setDataBeritaPin] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [poster, setDataPoster] = useState({
    dataSide: [],
    dataTop: [],
    loading: true,
  });
  const [konsorsium, setDataKonsorsium] = useState([]);
  let limit = 5;

  useEffect(() => {
    getSumberDaya.then((result) => setDataSumberDaya(result));
    getDataKonsorsium().then((response) => setDataKonsorsium(response));
    getDataBeritaPin();
    getDataBerita();
    getPoster().then((response) => {
      setDataPoster({
        dataSide: response.filter((o) => o.location === "Side"),
        dataTop: response.filter((o) => o.location === "Top"),
        loading: false,
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataBeritaPin = async () => {
    try {
      let res = await fetch(
        `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=LinkLSM&isPin.equals=true`
      );
      const data = await res.json();
      setDataBeritaPin(data);
    } catch (error) {}
  };

  const getDataBerita = async () => {
    try {
      let res = await fetch(
        `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=LinkLSM&isPin.equals=false&page=${pages}&size=${limit}&sort=createdDate,desc`
      );
      const data = await res.json();
      const total = res.headers.get("x-total-count");
      setpageCount(Math.ceil(total / limit));
      setDataBerita(data);
      setPages(0);
    } catch (error) {}
  };

  const fetchData = async (currentPage) => {
    const res = await fetch(
      `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=LinkLSM&isPin.equals=false&page=${currentPage}&size=${limit}&sort=createdDate,desc`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1 - 1;
    const commentsFormServer = await fetchData(currentPage);
    setDataBerita(commentsFormServer);
  };

  const getPoster = async () => {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${configData.BASE_URL}/api/poster?laman.equals=LinkLSM`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((responseJson) => {
            resolve(responseJson);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="main-linklsm">
        <div className="container">
          <div className="linklsm-inner">
            <Grid container spacing={5}>
              <Grid item xs={12} lg={3} md={4}>
                <Grid item xs={12}>
                  <UserData />
                  <SDM data={konsorsium} />
                  <div className="post-topbar">
                    <div className="cover-post">
                      <h3>Bekerjasama dengan</h3>
                      <a
                        href="http://www.lkpp.go.id/v3/id/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Lkpp} alt="" style={{marginTop: 10}} />
                      </a>
                    </div>
                    <div className="cover-post" style={{marginTop: 90}}>
                      <h3>Didukung Oleh</h3>
                      <a
                        href="https://www.ksi-indonesia.org/id"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Ksi} alt="" style={{marginTop: 10}} />
                      </a>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} md={8}>
                <Grid item xs={12} style={{ marginTop: -48 }}>
                  {poster.loading
                    ? ""
                    : poster.dataTop.map((o) => {
                        return (
                          <PostTop
                            cover={`${configData.BASE_URL}/api/misc/${o.image}`}
                          />
                        );
                      })}
                  {dataBeritaPin.map((data, index) => {
                    return (
                      <Article
                        isPin={true}
                        author={data.author}
                        headLine={data.headLine}
                        slug={data.slug}
                        image={data.image}
                        deskripsi={data.deskripsi}
                        artikelTag={data.artikelTag}
                        lastModifiedDate={data.createdDate}
                        index={index}
                        id={data.id}
                        pages={pages}
                      />
                    );
                  })}
                  {dataBerita.map((data, index) => {
                    return (
                      <Article
                        author={data.author}
                        headLine={data.headLine}
                        slug={data.slug}
                        image={data.image}
                        deskripsi={data.deskripsi}
                        artikelTag={data.artikelTag}
                        lastModifiedDate={data.createdDate}
                        index={index}
                        id={data.id}
                        pages={pages}
                      />
                    );
                  })}
                  <div style={{ marginTop: 50 }}>
                    <Grid container justifyContent="center" alignItems="center">
                      <ReactPaginate
                        previousLabel={<p>&laquo;</p>}
                        nextLabel={<p>&raquo;</p>}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={3} md={12}>
                <Grid item xs={12}>
                  <DaftarLembaga data={dataSumberDaya} />
                  {poster.loading ? (
                    ""
                  ) : poster.dataSide > 0 ? (
                    <div className="widget widget-img">
                      {poster.dataSide.map((o) => {
                        return (
                          <img
                            src={`${configData.BASE_URL}/api/misc/${o.image}`}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <footer style={{ background: "white", width: "100%", float: "left" }}>
        <div className="footer-sec">
          <div className="container">
            <Footer />
          </div>
        </div>
      </footer>
    </>
  );
}

export default LinkLSM_Page;

const getDataKonsorsium = async () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${configData.BASE_URL}/api/konsorsium`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        });
    } catch (error) {
      reject(error);
    }
  });
};
