import configData from "../config.json";
import Cookies from "universal-cookie";
import objectToQuery from "./queryParser";

export const getProfile = async () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(configData.BASE_URL + "/user/lsm", {
        method: "GET",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const loginData = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(configData.BASE_URL + "/auth/authenticate", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          const cookies = new Cookies();
          cookies.set("Profile", responseJson.token, { path: "/" });
          resolve(responseJson);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const loginIdentitas = async (data) => {
  var tokenData;
  const data2 = {
    email: localStorage.getItem("Email"),
    namaPengguna: localStorage.getItem("Nama"),
    lsm: {
      alamat: data.alamat,
      kecamatan: data.kecamatan,
      kotaAtauKabupaten: data.kotaAtauKabupaten,
      namaOrganisasi: data.namaOrganisasi,
      statusKantor: data.statusKantor,
    },
  };
  try {
    await fetch(configData.BASE_URL + "/user/lsm", {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data2),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        tokenData = responseJson;
      });
  } catch (error) {
    alert(error);
  }
  return tokenData;
};

export const fileUpload = async (data) => {
  var tokenData;
  let formData = new FormData();
  formData.append("file", data);
  try {
    await fetch(configData.BASE_URL + "/user/lsm/upload_akta", {
      method: "POST",
      credentials: "include",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        tokenData = responseJson;
      });
  } catch (error) {}
  return tokenData;
};

export const faq = async () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(configData.BASE_URL + "/api/faq", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export async function GetDataLSM(query = {}, toJson = false) {
  query = objectToQuery(
    Object.assign(
      {
        page: 0,
        size: 10,
      },
      query
    )
  );
  const response = await fetch(`${configData.BASE_URL}/api/lsm${query}`, {
    method: "GET",
  });

  if (toJson) return await response.json();
  return response;
}


export async function GetDataSwakelola(query = {}, toJson = false) {
  query = objectToQuery(
    Object.assign(
      {
        page: 0,
        size: 8,
      },
      query
    )
  );
  const response = await fetch(
    `${configData.BASE_URL}/api/klpd${query}`,
    {
      method: "GET",
    }
  );

  if (toJson) return await response.json();
  return response;
}



export const getSumberDaya = fetch(`${configData.BASE_URL}/api/sumber_daya`, {
  method: "GET",
}).then((response) => response.json());
