import React, { useEffect, useState } from "react";
import TruncateMarkup from "react-truncate-markup";
import { Link } from "react-router-dom";
import "../Styles/CardBanner_Styles.css";
import test from "../Assets/Gallery/1.jpg";
import { Button } from "@material-ui/core";
import configData from "../config.json";

function CardBanner_Com(props) {
  const [dataImage, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    getDataBanner();
  }, []);
  const getDataBanner = async () => {
    try {
      await fetch(
        `${configData.BASE_URL}/api/misc/${props.image}`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          setImages(responseJson);
          setIsLoading(false);
        });
    } catch (error) {}
  };
  const readMoreEllipsis = (
    <span>
      ...{" "}
      <Link to="#" className="expand-button">
        selengkapnya
      </Link>
    </span>
  );
  return isLoading ? (
    ""
  ) : (
    <div className="card-slider" key={props.key}>
      <div className="slider-legend" />
      <div className="slider-content">
        <div
          className={
            props.change ? "slider-content-text out" : "slider-content-text"
          }
        >
          <h2>{props.judul}</h2>
          <TruncateMarkup lines={4} ellipsis={readMoreEllipsis}>
            <div
              className="post__description"
              dangerouslySetInnerHTML={{ __html: props.deskripsi }}
            />
          </TruncateMarkup>

          <Button
            onClick={() => {
              window.open(props.url);
            }}
          >
            <div className="post__description" style={{ color: "white", fontSize: 10, marginLeft: 23, marginTop: 5}}>{props.label}</div>
          </Button>
        </div>
      </div>
      <div className="slider-image">
        {props.image === null ? (
          <img src={test} alt="" />
        ) : (
          <img
            src={`${configData.BASE_URL}/api/misc/${props.image}`}
            alt=""
          />
        )}
      </div>
    </div>
  );
}

export default CardBanner_Com;
