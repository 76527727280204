import React, {useState} from "react";
import {Button, Grid} from "@material-ui/core";
import {Link, NavLink} from "react-router-dom";
import LinkLogo from "../../src/Assets/Logo/cm-logo.png";
import LogoLogin from "../../src/Assets/Logo/cm-main-img.png";
import CopyIcon from "../../src/Assets/Logo/copy-icon.png";
import {Form1, Form2} from "../Components/Form_Com";
import "../Styles/Login_Styles.css";
import {useWindowDimensions} from "../Logic/Dimension_Logic";
import "react-responsive-modal/styles.css";
import {Modal} from "react-responsive-modal";
import FAQCom from "../Components/FAQ_Com";
import ModalLogo from "../Assets/Logo/wd-logo.png";

export default function Login_Page() {
    const [activeLogin, setActiveLogin] = useState(true);
    const [activeRegister, setActiveRegister] = useState(false);
    const [activeDaftar, setActiveDaftar] = useState(false);
    const [activeIdentitas, setActiveIdentitas] = useState(false);
    const [modalFaq, setModalFaq] = useState(false);
    const {width} = useWindowDimensions();

    const handleChangeLogin = (type) => {
        if (type === "identitas") {
            setActiveIdentitas(true);
            setActiveLogin(false);
            setActiveRegister(false);
            setActiveDaftar(false);
        } else {
            setActiveLogin(true);
            setActiveIdentitas(false);
            setActiveRegister(false);
            setActiveDaftar(false);
        }
    };
    const handleChangeRegister = () => {
        setActiveLogin(false);
        setActiveRegister(true);
        setActiveDaftar(true);
        setActiveIdentitas(false);
    };
    return (
        <>
            <Modal
                open={modalFaq}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ''}>
                <div className="modal-header" style={{textAlign: "center"}}>
                    <img src={ModalLogo} alt=""/>
                </div>
                <div className="modal-body">
                    <FAQCom/>
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={() => setModalFaq(false)} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
            <div className="login">
                <div className="login-page">
                    <Grid container className="login-page-inner">
                        <Grid item={true} xs={12} lg={6} md={6} className="grid-left">
                            <Grid item={true} xs={12} className="cm-info">
                                <NavLink to="/"><img src={LinkLogo} alt="logo-linklmsid"/></NavLink>
                                <p>LinkLSM.id, merupakan platform jejaring LSM di Indonesia.</p>
                            </Grid>
                            {width < 992 ? (
                                ""
                            ) : (
                                <Grid item={true} xs={12} className="cm-img">
                                    <img src={LogoLogin} alt="logo-login"/>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item={true} lg={6} xs={12} md={6} className="grid-right">
                            <div className="login-container">
                                <ul className="login-control">
                                    <li>
                                        <Button
                                            variant="contained"
                                            className={activeLogin || activeIdentitas ? "active" : ""}
                                            onClick={() => handleChangeLogin("login")}>
                                            Masuk
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            variant="contained"
                                            className={activeRegister ? "active" : ""}
                                            onClick={handleChangeRegister}>
                                            Mendaftar
                                        </Button>
                                    </li>
                                </ul>
                                <div className="login-form">
                                    <h3 className={activeLogin ? "active-form" : "hide"}>Masuk</h3>
                                    <Form1
                                        handleChangeLogin={() => handleChangeLogin("identitas")}
                                        className={activeLogin ? "active-form" : "hide"}
                                    />
                                    <h3 className={activeDaftar ? "active-form" : "hide"}>
                                        Mendaftar
                                    </h3>
                                    <Form2
                                        handleChangeLogin={() => handleChangeLogin("login")}
                                        className={activeDaftar ? "active-form" : "hide"}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" className="footer-login"
                          style={{padding: "0px 150px"}}>
                        <ul>
                            <li>
                                <Link to="/tentang-linklsm">Tentang</Link>
                            </li>
                            <li>
                                <Link to="/kontak-kami">Bantuan</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={() => setModalFaq(true)}>FAQ</Link>
                            </li>
                        </ul>
                        <p>
                            <img src={CopyIcon} alt="copyright"/>
                            2021 | LinkLSM.id
                        </p>
                    </Grid>
                </div>
            </div>
        </>
    );
}
