import React, {useState, useEffect} from "react";
import {StateMachineProvider, createStore} from "little-state-machine";
import {
    Grid,
    Stepper,
    Step,
    StepLabel,
    MobileStepper,
    Link,
    CircularProgress, TextareaAutosize, Button,
} from "@material-ui/core";
import Identitas from "./Step1_Form";
import Legalitas from "./Step2_Form";
import Struktur from "./Step3_Form";
import Kompetensi from "./Step4_Form";
import Dana from "./Step5_Form";
import "../../Styles/Stepper_Styles.css";
import {useWindowDimensions} from "../../Logic/Dimension_Logic";
import {usePromiseTracker, trackPromise} from "react-promise-tracker";
import Result from "./Result_Form";
import Sukses_Form from "./Sukses_Form";
import {NavLink} from "react-router-dom";

import configData from "../../config.json";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {DropzoneArea} from "material-ui-dropzone";
import {CloseOutlined} from "@material-ui/icons";
import ImageIcon from "@material-ui/icons/Image";
import Modal from "react-responsive-modal";
import LinkLSM from "../../Assets/Logo/wd-logo.png";

createStore({});

function Register_Com(props) {
    const [data, setData] = useState({
        data: '',
        loading: true
    });
    const [step, setStep] = useState(0);
    const [state, setState] = useState({
        step1: true,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
    });
    const {width} = useWindowDimensions();
    const [modal, setModal] = useState(false);

    const closeModal = () => {
        setModal(false);
    };

    useEffect(() => {
        handleChange();
    }, [step]);

    // go back to previous step
    const prevStep = () => {
        setStep(step - 1);
    };

    // proceed to the next step
    const nextStep = () => {
        setStep(step + 1);
    };

    // handle state for step
    const handleChange = () => {
        if (step === 0) {
            setState({
                step1: true,
                step2: false,
                step3: false,
                step4: false,
                step5: false,
            });
        } else if (step === 1) {
            setState({
                step1: false,
                step2: true,
                step3: false,
                step4: false,
                step5: false,
            });
        } else if (step === 2) {
            setState({
                step1: false,
                step2: false,
                step3: true,
                step4: false,
                step5: false,
            });
        } else if (step === 3) {
            setState({
                step1: false,
                step2: false,
                step3: false,
                step4: true,
                step5: false,
            });
        } else if (step === 4) {
            setState({
                step1: false,
                step2: false,
                step3: false,
                step4: false,
                step5: true,
            });
        }
    };

    function getSteps() {
        if (width > 767) {
            return [
                "Identitas Organisasi",
                "Legalitas Organisasi",
                "Struktur Organisasi",
                "Kompetensi Organisasi",
                "Akuntabilitas & Transparansi",
            ];
        } else {
            return [
                "Identitas",
                "Legalitas",
                "Struktur",
                "Kompetensi",
                "Akuntabilitas",
            ];
        }
    }

    // Get Data Profile
    useEffect(() => {
        setData({
            data: JSON.parse(localStorage.getItem('data')),
            loading: false
        })
    }, []);

    const steps = getSteps();
    return (
        <div className="login">
            <div className="login-page">
                <Grid container className="login-page-inner">
                    <Grid item={true} xs={12} className="stepper">
                        <StateMachineProvider>
                            <div className="login-form register">
                                {data.data ? (
                                    <p style={{float: "right"}}>{
                                        data.data.lsm.statusLSM === "VerifiedFailed" ?
                                            (
                                                <div>
                                                    <strong style={{color: "red"}}>Gagal Verifikasi</strong>
                                                    <p style={{marginTop: 5}}>Klik <Link style={{cursor: "pointer"}}
                                                                                         to="#"
                                                                                         onClick={() => setModal(true)}><strong>disini</strong></Link> untuk
                                                        melihat hasil review</p>
                                                </div>
                                            )
                                            : ""
                                    }</p>
                                ) : ""}
                                <h1><NavLink to="/" style={{color: "black"}}>Form LinkLSM.id</NavLink></h1>
                                <p style={{marginBottom: 20}}>Silahkan lengkapi formulir dibawah ini</p>
                                {width > 767 ? (
                                    <Stepper
                                        activeStep={step}
                                        alternativeLabel
                                        className="stepper"
                                    >
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                ) : (
                                    <MobileStepper
                                        activeStep={step}
                                        steps={5}
                                        variant="text"
                                        position="top"
                                    >
                                    </MobileStepper>
                                )}
                                {step === 5 ? (
                                    <Result/>
                                ) : (
                                    data.loading ? '' : (
                                        <>
                                            <Identitas
                                                nextStep={nextStep}
                                                className={state.step1 ? "active-form" : "hide"}
                                                handleChange={handleChange}
                                                dataLSM={data.data}
                                            />
                                            <Legalitas
                                                nextStep={nextStep}
                                                className={state.step2 ? "active-form" : "hide"}
                                                prevStep={prevStep}
                                                handleChange={handleChange}
                                                dataLSM={data.data}
                                            />
                                            <Struktur
                                                nextStep={nextStep}
                                                className={state.step3 ? "active-form" : "hide"}
                                                prevStep={prevStep}
                                                handleChange={handleChange}
                                                dataLSM={data.data}
                                            />
                                            <Kompetensi
                                                nextStep={nextStep}
                                                className={state.step4 ? "active-form" : "hide"}
                                                prevStep={prevStep}
                                                handleChange={handleChange}
                                                dataLSM={data.data}
                                            />
                                            <Dana
                                                nextStep={nextStep}
                                                className={state.step5 ? "active-form" : "hide"}
                                                prevStep={prevStep}
                                                handleChange={handleChange}
                                                dataLSM={data.data}
                                            />
                                        </>
                                    )
                                )}
                            </div>
                        </StateMachineProvider>
                    </Grid>
                </Grid>
            </div>
            <Modal
                classNames={{
                    modal: "modal-profile",
                }}
                open={modal}
                center
                showCloseIcon={false}
                onOverlayClick={closeModal}
                onClose={closeModal}
                closeOnOverlayClick={true}>
                <div className="modal-header" style={{textAlign: "center"}}>
                    <img src={LinkLSM} alt=""/>
                </div>
                <div className="modal-body syarat">
                    {data.data.lsm ? <p className="post__description" dangerouslySetInnerHTML={{ __html: data.data.lsm.review}}  /> : ""}
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={() => setModal(false)} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default Register_Com;
