import {
    Button,
    CircularProgress,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextareaAutosize,
    TextField,
} from "@material-ui/core/";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import Person from "@material-ui/icons/Person";
import Timer from "@material-ui/icons/Timer";
import Work from "@material-ui/icons/Work";
import Phone from "@material-ui/icons/Phone";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import React, {createElement, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Modal} from "react-responsive-modal";
import {v4 as uuidV4} from "uuid";
import {DropzoneArea} from "material-ui-dropzone";
import {Delete} from "@material-ui/icons";
import {useWindowDimensions} from "../Logic/Dimension_Logic";
import "../Styles/PostProfile_Styles.css";
import {Link, useLocation} from "react-router-dom";

import configData from "../config.json";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10
    },
}));

export default function PostProfile(props) {
    const location = useLocation();
    const [data, setData] = useState({
        profile: "",
        isLoading: true,
    });
    const classes = useStyles();
    const [isSubmit, setIsSubmit] = useState(false);
    const [value, setValue] = React.useState("1");
    const [typeModal, setTypeModal] = useState("");
    const [modal, setModal] = useState(false);
    const [defaultSdm, setDefaultSdm] = useState("TenagaAhli");
    const [openMore, setOpenMore] = useState(false);
    const {width} = useWindowDimensions();
    const [setting, setSetting] = useState(
        props.data.namaPanggilan === null ? "" : props.data.namaPanggilan
    );

    const [organisasi, setOrganisasi] = useState(
        props.data.lsm.namaOrganisasi === null ? "" : props.data.lsm.namaOrganisasi
    );

    const [email, setEmail] = useState(
        props.data.email === null ? "" : props.data.email
    );
    const [alamat, setAlamat] = useState(
        props.data.lsm.alamat === null ? "" : props.data.lsm.alamat
    );
    const [inputList, setInputList] = useState([
        {
            id: uuidV4(),
            nama: "",
            pendidikanTerakhir: "D3",
            bidangKeahlian: "",
            pengalamanKerjaMinimal: "",
            pengalamanKerjaMaksimal: "",
            tipeTenagaAhli: "",
            pengalaman: "1 5",
            website: "",
            facebook: "",
            instagram: "",
            twitter: "",
            linkedin: "",
        },
    ]);
    const [inputListManajerial, setInputListManajerial] = useState([
        {
            id: uuidV4(),
            nama: "",
            pendidikanTerakhir: "D3",
            bidangKeahlian: "",
            pengalamanKerjaMinimal: "",
            pengalamanKerjaMaksimal: "",
            tipeTenagaAhli: "",
            pengalaman: "1 5",
            website: "",
            facebook: "",
            instagram: "",
            twitter: "",
            linkedin: "",
        },
    ]);
    const [bodyProfile, setBodyProfile] = useState(
        props.data
            ? props.data.lsm.profil !== null
                ? props.data.lsm.profil
                : ""
            : ""
    );

    const pathEqualAliasOrId = useMemo(() => {
        const result = [
            "/profile",
            `/${data.profile.namaPanggilan}`,
            `/${data.profile.id}`,
        ].includes(location.pathname);
        if (location.state && location.state.guest) return true;
        return result;
    }, [data.profile.id, data.profile.namaPanggilan, location.pathname, location.state]);

    const {
        handleSubmit,
        control,
        reset,
    } = useForm();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [inputListPortofolio, setInputListPortofolio] = useState([
        {
            id: uuidV4(),
            namaKegiatan: "",
            ringkasan: "",
            fotoKegiatan: "",
            lokasi: "",
            tahunTandaTanganKontrak: "",
            lamaKontrak: "",
            sumberPemberiKerja: "",
            kontakPemberiKerja: "",
        },
    ]);

    useEffect(() => {
        if (props.data !== undefined) {
            setData({
                profile: props.data,
                isLoading: false,
            });

            if (props.data.lsm.portfolio.length > 0)
                setInputListPortofolio(props.data.lsm.portfolio);

            let ahli = props.data.lsm.tenagaAhli
                .filter((ahli) => ahli.tipeTenagaAhli === "TenagaAhli")
                .map((ahli) => {
                    ahli.pengalaman = `${ahli.pengalamanKerjaMinimal} ${ahli.pengalamanKerjaMaksimal}`;
                    return ahli;
                });

            let manajerial = props.data.lsm.tenagaAhli
                .filter((ahli) => ahli.tipeTenagaAhli === "TenagaManajerial")
                .map((ahli) => {
                    ahli.pengalaman = `${ahli.pengalamanKerjaMinimal} ${ahli.pengalamanKerjaMaksimal}`;
                    return ahli;
                });

            if (ahli.length > 0) setInputList(ahli);
            if (manajerial.length > 0) setInputListManajerial(manajerial);
        }
    }, [props.data]);

    const openSetting = () => {
        setModal(true);
        setTypeModal("setting");
    };

    // Handle modal profile
    const openProfile = () => {
        setModal(true);
        setTypeModal("profile");
    };

    // Handle modal portofolio
    const openPortofolio = () => {
        setModal(true);
        setTypeModal("portofolio");
    };

    // Handle modal kontak
    const openKontak = () => {
        setModal(true);
        setTypeModal("kontak");
    };

    // Handle modal SDM
    const openSdm = () => {
        setModal(true);
        setTypeModal("SDM");
    };

    // Handle close modal
    const closeModal = () => {
        setModal(false);
        reset();
        setTypeModal("");
    };

    // Handle click open more
    const handleClickOpenMore = () => {
        setOpenMore(!openMore);
    };

    //remove and add btn logic
    const handleRemoveClick = (id) => {
        setInputList((list) => list.filter((el) => el.id !== id));
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([
            {
                id: uuidV4(),
                nama: "",
                pendidikanTerakhir: "D3",
                bidangKeahlian: "",
                pengalamanKerjaMinimal: "",
                pengalamanKerjaMaksimal: "",
                tipeTenagaAhli: "",
                pengalaman: "1 5",
                website: "",
                facebook: "",
                instagram: "",
                twitter: "",
                linkedin: "",
            },
            ...inputList,
        ]);
    };

    const handleRemoveClickManajerial = (id) => {
        setInputListManajerial((list) => list.filter((el) => el.id !== id));
    };
    // handle click event of the Add button
    const handleAddClickManajerial = () => {
        setInputListManajerial([
            {
                id: uuidV4(),
                nama: "",
                pendidikanTerakhir: "D3",
                bidangKeahlian: "",
                pengalamanKerjaMinimal: "",
                pengalamanKerjaMaksimal: "",
                tipeTenagaAhli: "",
                pengalaman: "1 5",
                website: "",
                facebook: "",
                instagram: "",
                twitter: "",
                linkedin: "",
            },
            ...inputListManajerial,
        ]);
    };

    const handleRemoveClickPortofolio = (id) => {
        setInputListPortofolio((list) => {
            let a = list.filter((el) => {
                return el.id !== id;
            });
            return a;
        });
    };

    // handle click event of the Add button
    const handleAddClickPortoFolio = () => {
        setInputListPortofolio([
            {
                id: uuidV4(),
                namaKegiatan: "",
                ringkasan: "",
                kegiatan: "",
                fotoKegiatan: "",
                lokasi: "",
                tahunTandaTanganKontrak: "",
                lamaKontrak: "",
                sumberPemberiKerja: "",
                kontakPemberiKerja: "",
            },
            ...inputListPortofolio,
        ]);
    };

    // handle input change
    const handleInputChange = (id) => (evt) => {
        const {value} = evt.target;
        setInputList((list) =>
            list.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        [evt.target.name]: value,
                    }
                    : el
            )
        );
    };

    const handleInputChangeManajerial = (id) => (evt) => {
        const {value} = evt.target;
        setInputListManajerial((list) =>
            list.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        [evt.target.name]: value,
                    }
                    : el
            )
        );
    };

    const handleInputChangePortofolio = (id) => (evt) => {
        const {value} = evt.target;
        setInputListPortofolio((list) =>
            list.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        [evt.target.name]: value,
                    }
                    : el
            )
        );
    };

    const handleInputChangeFotoPortofolio = (id) => (evt) => {
        setInputListPortofolio((list) =>
            list.map((el) =>
                el.id === id
                    ? {
                        ...el,
                        fotoKegiatan: evt,
                    }
                    : el
            )
        );
    };

    const service = {
        put: async (data) => {
            return new Promise((resolve, reject) => {
                try {
                    fetch(`${configData.BASE_URL}/user/lsm`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        credentials: "include",
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((response) => resolve(response));
                } catch (error) {
                    reject(error);
                }
            });
        },
        uploadPortfolio: async (id, files) => {
            return new Promise((resolve, reject) => {
                try {
                    const formData = new FormData();
                    formData.append("file", files);
                    fetch(
                        `${configData.BASE_URL}/user/lsm/upload/${id}/kegiatan_portfolio`,
                        {
                            method: "POST",
                            credentials: "include",
                            body: formData,
                        }
                    )
                        .then((response) => response.json())
                        .then((response) => resolve(response));
                } catch (error) {
                    reject(error);
                }
            });
        },
    };

    const updateSDM = async (data) => {
        setIsSubmit(true);
        let state = props.data;

        inputList.map((ahli) => {
            ahli.tipeTenagaAhli = "TenagaAhli";
            let pengalaman = ahli.pengalaman.split(" ");
            if (pengalaman.length > 1) {
                ahli.pengalamanKerjaMinimal = pengalaman[0];
                ahli.pengalamanKerjaMaksimal = pengalaman[1];
            } else ahli.pengalamanKerjaMinimal = pengalaman[0];
            return ahli;
        });

        inputListManajerial.map((ahli) => {
            ahli.tipeTenagaAhli = "TenagaManajerial";
            let pengalaman = ahli.pengalaman.split(" ");
            if (pengalaman.length > 1) {
                ahli.pengalamanKerjaMinimal = pengalaman[0];
                ahli.pengalamanKerjaMaksimal = pengalaman[1];
            } else ahli.pengalamanKerjaMinimal = pengalaman[0];
            return ahli;
        });

        state.lsm.tenagaAhli = inputListManajerial.concat(inputList);

        state.lsm.tenagaAhli = state.lsm.tenagaAhli.filter((t) => t.nama !== "");

        await service.put(state).then((response) => {
            state = response;
            setIsSubmit(false);
            closeModal();
        });
    };

    const updateSetting = async (data) => {
        setIsSubmit(true);
        let state = props.data;
        state.namaPanggilan = setting;
        state = await service.put(state);
        setIsSubmit(false);
        closeModal();
    };

    const updatePortfolio = async (data) => {
        setIsSubmit(true);
        let state = props.data;

        let uploadFile = [
            ...inputListPortofolio
                .filter((o) => {
                    if (o.fotoKegiatan !== null) {
                        return o.fotoKegiatan.length > 0;
                    } else return false;
                })
                .map((o) => {
                    return {
                        id: o.namaKegiatan,
                        fotoKegiatan: o.fotoKegiatan,
                    };
                }),
        ];

        Promise.resolve()
            .then(() => {
                state.lsm.portfolio = inputListPortofolio.map((o) => {
                    o.fotoKegiatan = null;
                    return o;
                });
                return service.put(state).then((response) => {
                    state = response;
                    return state;
                });
            })
            .then((statePut) => {
                let run = uploadFile.map((upload) => {
                    let id = statePut.lsm.portfolio.find(
                        (o) => o.namaKegiatan === upload.id
                    ).id;
                    return service
                        .uploadPortfolio(id, upload.fotoKegiatan[0])
                        .then((response) => {
                            state.lsm.portfolio.map((o) => {
                                if (o.id === response.id)
                                    o.fotoKegiatan = response.fotoKegiatan;
                                return o;
                            });
                        });
                });
                return Promise.all(run);
            })
            .then(() => {
                return new Promise((resolve) => {
                    setData({
                        profile: state,
                        isLoading: false,
                    });
                    setIsSubmit(false);
                    closeModal();
                    resolve();
                });
            });
    };

    const updateProfile = async (data) => {
        data.preventDefault();
        setIsSubmit(true);
        let state = props.data;
        state.lsm.profil = bodyProfile;
        state = await service.put(state);
        setIsSubmit(false);
        closeModal();
    };

    const updateKontak = async (data) => {
        setIsSubmit(true);
        let state = props.data;
        state.email = email;
        state.lsm.alamat = alamat;
        state.lsm.namaOrganisasi = organisasi;
        state = await service.put(state);
        setIsSubmit(false);
        closeModal();
    };

    return data.isLoading ? (
        "Loading..."
    ) : (
        <div className={classes.root + " tabs"}>
            <Modal
                classNames={{
                    modal: "modal-profile",
                }}
                open={modal}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ""}
            >
                {typeModal === "profile" ? (
                    <>
                        <form onSubmit={updateProfile}>
                            <div className="modal-body login-form">
                                <h3>Profil</h3>
                                <div className="text-about">
                                    <ReactQuill
                                        value={bodyProfile}
                                        onChange={setBodyProfile}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer btn-login" style={{marginTop: 20}}>
                                <Button onClick={closeModal} variant="contained">
                                    Batal
                                </Button>
                                <Button
                                    disabled={isSubmit}
                                    variant="contained"
                                    type="submit"
                                    style={{marginLeft: 20}}
                                >
                                    Simpan{" "}
                                    {isSubmit ? (
                                        <CircularProgress size={10} style={{marginLeft: 10}}/>
                                    ) : (
                                        ""
                                    )}
                                </Button>
                            </div>
                        </form>
                    </>
                ) : typeModal === "portofolio" ? (
                    <div className="login-form">
                        <form onSubmit={handleSubmit(updatePortfolio)}>
                            <h3>
                                Portofolio{" "}
                                <AddCircleIcon
                                    style={{
                                        float: "right",
                                        cursor: "pointer",
                                        fontSize: "2rem",
                                    }}
                                    onClick={handleAddClickPortoFolio}
                                />
                            </h3>
                            {inputListPortofolio.length === 5
                                ? alert(
                                    "Sudah maksimun penambahan portofolio",
                                    setInputListPortofolio([
                                        {
                                            id: uuidV4(),
                                            namaKegiatan: "",
                                            ringkasan: "",
                                            fotoKegiatan: "",
                                            lokasi: "",
                                            tahunTandaTanganKontrak: "",
                                            lamaKontrak: "",
                                            sumberPemberiKerja: "",
                                            kontakPemberiKerja: "",
                                        },
                                    ])
                                )
                                : inputListPortofolio.map((w, i) => (
                                    <Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="namaKegiatan"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Nama Kegiatan"
                                                        value={w.namaKegiatan}
                                                        required
                                                        onChange={handleInputChangePortofolio(w.id)}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Work/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextareaAutosize
                                                style={{
                                                    marginTop: 10,
                                                    borderColor: "lightgray",
                                                    backgroundColor: "transparent",
                                                    outline: "none",
                                                }}
                                                multiline={true}
                                                rows={5}
                                                variant="outlined"
                                                name="ringkasan"
                                                required
                                                placeholder="Ringkasan Kegiatan"
                                                className="text-area"
                                                value={w.ringkasan}
                                                onChange={handleInputChangePortofolio(w.id)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="lokasi"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Lokasi Kegiatan"
                                                        value={w.lokasi}
                                                        required
                                                        onChange={handleInputChangePortofolio(w.id)}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="tahunTandaTanganKontrak"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Tahun Mulai Kontrak"
                                                        type="number"
                                                        required
                                                        value={w.tahunTandaTanganKontrak}
                                                        onChange={handleInputChangePortofolio(w.id)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Timer/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        inputProps={{
                                                            maxLength: 4,
                                                            minLength: 4,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="lamaKontrak"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Lama Kontrak"
                                                        value={w.lamaKontrak}
                                                        required
                                                        onChange={handleInputChangePortofolio(w.id)}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Timer/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="sumberPemberiKerja"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Sumber Pemberi Kerja"
                                                        required
                                                        value={w.sumberPemberiKerja}
                                                        onChange={handleInputChangePortofolio(w.id)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Person/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="kontakPemberiKerja"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Nama Kontak Pemberi Kerja"
                                                        required
                                                        value={w.kontakPemberiKerja}
                                                        onChange={handleInputChangePortofolio(w.id)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Phone/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12} style={{marginTop: 10}}>
                                            <Controller
                                                control={control}
                                                name="fotoKegiatan"
                                                render={({field}) => (
                                                    <DropzoneArea
                                                        showPreviews={true}
                                                        showPreviewsInDropzone={false}
                                                        filesLimit={1}
                                                        dropzoneText={"Pilih Foto Kegiatan "}
                                                        onChange={handleInputChangeFotoPortofolio(w.id)}
                                                        useChipsForPreview
                                                        previewGridProps={{
                                                            container: {spacing: 1, direction: "row"},
                                                        }}
                                                        previewChipProps={{
                                                            classes: {root: classes.previewChip},
                                                        }}
                                                        previewText="File yang dipilih :"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-around"
                                            style={{marginTop: 15, marginBottom: 15}}
                                        >
                                            {inputListPortofolio.length === 1 ? (
                                                ""
                                            ) : (
                                                <Delete
                                                    style={{cursor: "pointer", fontSize: "2rem"}}
                                                    onClick={() => handleRemoveClickPortofolio(w.id)}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            <div className="modal-footer btn-login">
                                <Button onClick={closeModal} variant="contained">
                                    Batal
                                </Button>
                                <Button variant="contained" type="submit" disabled={isSubmit}>
                                    Simpan{" "}
                                    {isSubmit ? (
                                        <CircularProgress size={10} style={{marginLeft: 20}}/>
                                    ) : (
                                        ""
                                    )}
                                </Button>
                            </div>
                        </form>
                    </div>
                ) : typeModal === "SDM" ? (
                    <div className="login-form">
                        <h3>
                            SDM{" "}
                            {defaultSdm === "TenagaAhli" ? (
                                <AddCircleIcon
                                    style={{
                                        float: "right",
                                        cursor: "pointer",
                                        fontSize: "2rem",
                                    }}
                                    onClick={handleAddClick}
                                />
                            ) : (
                                <AddCircleIcon
                                    style={{
                                        float: "right",
                                        cursor: "pointer",
                                        fontSize: "2rem",
                                    }}
                                    onClick={handleAddClickManajerial}
                                />
                            )}
                        </h3>
                        <form onSubmit={handleSubmit(updateSDM)}>
                            <Controller
                                rules={{required: true}}
                                control={control}
                                defaultValue={defaultSdm}
                                name="statusSdm"
                                render={({field: {onChange, value}}) => (
                                    <RadioGroup
                                        value={value}
                                        onChange={(_, data) => {
                                            onChange(data);
                                            setDefaultSdm(data);
                                        }}
                                        className="on-off-status"
                                    >
                                        <FormControlLabel
                                            value="TenagaManajerial"
                                            control={<Radio/>}
                                            label="Tenaga Manajerial"
                                        />
                                        <FormControlLabel
                                            value="TenagaAhli"
                                            control={<Radio/>}
                                            label="Tenaga Ahli"
                                        />
                                    </RadioGroup>
                                )}
                            />
                            {defaultSdm === "TenagaAhli"
                                ? inputList.map((x, i) => (
                                    <Grid key={x.id}>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="nama"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Nama Tenaga Ahli"
                                                        value={x.nama}
                                                        onChange={handleInputChange(x.id)}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                style={{marginTop: 10}}
                                            >
                                                Pengalaman
                                            </InputLabel>
                                            <Select
                                                name="pengalaman"
                                                value={x.pengalaman}
                                                onChange={handleInputChange(x.id)}
                                            >
                                                <MenuItem value={"1 5"}>1 - 5 Tahun</MenuItem>
                                                <MenuItem value={"6 10"}>6 - 10 Tahun</MenuItem>
                                                <MenuItem value={"11 15"}>11 - 15 Tahun</MenuItem>
                                                <MenuItem value={"16 20"}>16 - 20 Tahun</MenuItem>
                                                <MenuItem value={"20"}>20 Tahun Lebih</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="bidangKeahlian"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Bidang Keahlian Tenaga Ahli"
                                                        value={x.bidangKeahlian}
                                                        onChange={handleInputChange(x.id)}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                style={{marginTop: 20}}
                                            >
                                                Pendidikan Terakhir
                                            </InputLabel>
                                            <Select
                                                name="pendidikanTerakhir"
                                                value={x.pendidikanTerakhir}
                                                onChange={handleInputChange(x.id)}
                                            >
                                                <MenuItem value={"D3"}> D3</MenuItem>
                                                <MenuItem value={"D4"}>D4</MenuItem>
                                                <MenuItem value={"S1"}>S1</MenuItem>
                                                <MenuItem value={"S2"}>S2</MenuItem>
                                                <MenuItem value={"S3"}>S3</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Controller
                                                name="linkedin"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="filled"
                                                        value={x.linkedin}
                                                        onChange={handleInputChange(x.id)}
                                                        label="Link Alamat Linkedin"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <p>https://</p>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Controller
                                                name="website"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="filled"
                                                        value={x.website}
                                                        onChange={handleInputChange(x.id)}
                                                        label="Link Alamat Website atau Social Media"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <p>https://</p>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-around"
                                            style={{marginTop: 15, marginBottom: 15}}
                                        >
                                            {inputList.length === 1 ? (
                                                ""
                                            ) : (
                                                <Delete
                                                    style={{cursor: "pointer", fontSize: "2rem"}}
                                                    onClick={() => handleRemoveClick(x.id)}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                ))
                                : inputListManajerial.map((x, i) => (
                                    <Grid key={x.id}>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="nama"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Nama Tenaga Manajerial"
                                                        value={x.nama}
                                                        onChange={handleInputChangeManajerial(x.id)}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                style={{marginTop: 10}}
                                            >
                                                Pengalaman
                                            </InputLabel>
                                            <Select
                                                name="pengalaman"
                                                value={x.pengalaman}
                                                onChange={handleInputChangeManajerial(x.id)}
                                            >
                                                <MenuItem value={"1 5"}>1 - 5 Tahun</MenuItem>
                                                <MenuItem value={"6 10"}>6 - 10 Tahun</MenuItem>
                                                <MenuItem value={"11 15"}>11 - 15 Tahun</MenuItem>
                                                <MenuItem value={"16 20"}>16 - 20 Tahun</MenuItem>
                                                <MenuItem value={"20"}>20 Tahun Lebih</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="bidangKeahlian"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="outlined"
                                                        placeholder="Bidang Keahlian Manajerial"
                                                        value={x.bidangKeahlian}
                                                        onChange={handleInputChangeManajerial(x.id)}
                                                        type="text"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocationCityIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                id="demo-simple-select-label"
                                                style={{marginTop: 20}}
                                            >
                                                Pendidikan Terakhir
                                            </InputLabel>
                                            <Select
                                                name="pendidikanTerakhir"
                                                value={x.pendidikanTerakhir}
                                                onChange={handleInputChangeManajerial(x.id)}
                                            >
                                                <MenuItem value={"D3"}> D3</MenuItem>
                                                <MenuItem value={"D4"}>D4</MenuItem>
                                                <MenuItem value={"S1"}>S1</MenuItem>
                                                <MenuItem value={"S2"}>S2</MenuItem>
                                                <MenuItem value={"S3"}>S3</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Controller
                                                name="linkedin"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="filled"
                                                        value={x.linkedin}
                                                        onChange={handleInputChangeManajerial(x.id)}
                                                        label="Link Alamat Linkedin"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <p>https://</p>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Controller
                                                name="website"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        className={"input-login"}
                                                        variant="filled"
                                                        value={x.website}
                                                        onChange={handleInputChangeManajerial(x.id)}
                                                        label="Link Alamat Website atau Social Media"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <p>https://</p>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-around"
                                            style={{marginTop: 15, marginBottom: 15}}
                                        >
                                            {inputListManajerial.length === 1 ? (
                                                ""
                                            ) : (
                                                <Delete
                                                    style={{cursor: "pointer", fontSize: "2rem"}}
                                                    onClick={() => handleRemoveClickManajerial(x.id)}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            <div className="modal-footer btn-login" style={{marginTop: 20}}>
                                <Button onClick={closeModal} variant="contained">
                                    Batal
                                </Button>
                                <Button
                                    disabled={isSubmit}
                                    variant="contained"
                                    type="submit"
                                    style={{marginLeft: 20}}
                                >
                                    Simpan{" "}
                                    {isSubmit ? (
                                        <CircularProgress size={10} style={{marginLeft: 10}}/>
                                    ) : (
                                        ""
                                    )}
                                </Button>
                            </div>
                        </form>
                    </div>
                ) : typeModal === "setting" ? (
                    <form onSubmit={handleSubmit(updateSetting)}>
                        <Grid item xs={12}>
                            <Controller
                                name="nama"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        className={"input-login"}
                                        variant="outlined"
                                        placeholder="Nama Panggilan"
                                        value={setting}
                                        onChange={(e) => setSetting(e.target.value)}
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocationCityIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <div className="modal-footer btn-login" style={{marginTop: 20}}>
                            <Button onClick={closeModal} variant="contained">
                                Batal
                            </Button>
                            <Button
                                disabled={isSubmit}
                                variant="contained"
                                type="submit"
                                style={{marginLeft: 20}}
                            >
                                Simpan{" "}
                                {isSubmit ? (
                                    <CircularProgress size={10} style={{marginLeft: 10}}/>
                                ) : (
                                    ""
                                )}
                            </Button>
                        </div>
                    </form>
                ) : typeModal === "kontak" ? (
                    <form onSubmit={handleSubmit(updateKontak)}>
                        <Grid item xs={12}>
                            <Controller
                                name="namaOrganisasi"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        className={"input-login"}
                                        variant="outlined"
                                        placeholder="Nama Organisasi"
                                        value={organisasi}
                                        onChange={(e) => setOrganisasi(e.target.value)}
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocationCityIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <br/>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        className={"input-login"}
                                        variant="outlined"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Person/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <br/>
                        <Grid item xs={12}>
                            <Controller
                                name="alamat"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        className={"input-login"}
                                        variant="outlined"
                                        placeholder="Alamat"
                                        value={alamat}
                                        onChange={(e) => setAlamat(e.target.value)}
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Work/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <div className="modal-footer btn-login" style={{marginTop: 20}}>
                            <Button onClick={closeModal} variant="contained">
                                Batal
                            </Button>
                            <Button
                                disabled={isSubmit}
                                variant="contained"
                                type="submit"
                                style={{marginLeft: 20}}
                            >
                                Simpan{" "}
                                {isSubmit ? (
                                    <CircularProgress size={10} style={{marginLeft: 10}}/>
                                ) : (
                                    ""
                                )}
                            </Button>
                        </div>
                    </form>
                ) : (
                    ""
                )}
            </Modal>
            <TabContext value={value}>
                <AppBar position="static">
                    <TabList
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={width > 599 ? false : "auto"}
                        aria-label="simple tabs example"
                        indicatorColor="primary"
                    >
                        <Tab label="Tentang" value="1"/>
                        <Tab label="Profil" value="2"/>
                        <Tab label="SDM" value="5"/>
                        <Tab label="Portofolio" value="3"/>
                        <Tab label="Kontak" value="4"/>
                        {props.guest ? <></> : <Tab label="Setting" value="6"/>}
                    </TabList>
                </AppBar>
                <TabPanel value="1" className="tabs-tentang tab-section tab-pertama">
                    <>
                        <div className="tab-content-tentang">
                            <div className="tab-post">
                                <table
                                    className="blueTable"
                                    style={{wordBreak: "break-word"}}
                                >
                                    <thead>
                                    <tr>
                                        <th style={{width: "30%"}}>Jenis Lembaga</th>
                                        <th colSpan={2}>LSM</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{width: "30%"}}>Badan Hukum</td>
                                        <td colSpan={2}>{data.profile.lsm.badanHukum}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "30%"}}>Pengesahan Kemenkumham</td>
                                        <td colSpan={2}>
                                            {data.profile.lsm.skPenegasan === null
                                                ? "Belum Ada"
                                                : "Ada"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "30%"}}>AD/ART</td>
                                        <td colSpan={2}>Ada</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "30%"}}>Pengurus</td>
                                        <td style={{widht: "25%"}}> Ketua</td>
                                        <td>{data.profile.lsm.namaKetua}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "30%"}}></td>
                                        <td style={{width: "25%"}}>Sekretaris</td>
                                        <td>{data.profile.lsm.namaSekertaris}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "30%"}}></td>
                                        <td style={{width: "25%"}}>Bendahara</td>
                                        <td>{data.profile.lsm.namaBendahara} </td>
                                    </tr>
                                    {data.profile.lsm.bidangKerja.length === 0 ? (
                                        <tr>
                                            <td style={{width: "30%"}}>Jenis Kegiatan</td>
                                            <td colSpan={2}></td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td style={{width: "30%"}}>Jenis Kegiatan</td>
                                            <td colSpan={2} style={{wordBreak: "break-word"}}>
                                                <ul
                                                    style={{
                                                        paddingLeft: "20px",
                                                        listStyle: "initial",
                                                    }}
                                                >
                                                    {data.profile.lsm.bidangKerja.map((a) => {
                                                        return <li>{a.bidangKerja}</li>;
                                                    })}
                                                </ul>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td style={{width: "30%"}}>Sektor Kegiatan</td>
                                        <td colSpan={2} style={{wordBreak: "break-word"}}>
                                            <ul
                                                style={{paddingLeft: "20px", listStyle: "initial"}}
                                            >
                                                {data.profile.lsm.kompetensi.map((a) => {
                                                    return <li>{a.kompetensi}</li>;
                                                })}
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "30%"}}>NPWP</td>
                                        <td colSpan={2}>
                                            {data.profile.lsm.npwp === null ? "Belum Ada" : "Ada"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "30%"}}>Laporan Pajak Tahunan</td>
                                        <td colSpan={2}>
                                            {" "}
                                            {data.profile.lsm.danaKelola === null
                                                ? "Belum Ada"
                                                : "Ada"}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                </TabPanel>
                <TabPanel value="2" className="tabs-profile tab-section">
                    {createElement(TabProfile, {
                        data,
                        handleClickOpenMore,
                        setModal,
                        openMore,
                        setTypeModal,
                        setOpenMore,
                        openProfile,
                        asguest: pathEqualAliasOrId,
                    })}
                </TabPanel>
                <TabPanel value="3" className="tabs-portofolio tab-section">
                    {createElement(TabPortofolio, {
                        data,
                        setModal,
                        setOpenMore,
                        handleClickOpenMore,
                        openMore,
                        setTypeModal,
                        openPortofolio,
                        asguest: pathEqualAliasOrId,
                    })}
                </TabPanel>
                <TabPanel value="4" className="tabs-kontak tab-section">
                    {createElement(TabContacts, {
                        data,
                        openKontak,
                        handleClickOpenMore,
                        setModal,
                        openMore,
                        setOpenMore,
                        setTypeModal,
                        asguest: pathEqualAliasOrId,
                    })}
                </TabPanel>

                <TabPanel value="5" className="tabs-sdm tab-section">
                    {createElement(TabSdm, {
                        data,
                        handleClickOpenMore,
                        openMore,
                        setModal,
                        setOpenMore,
                        setTypeModal,
                        openSdm,
                        asguest: pathEqualAliasOrId,
                    })}
                </TabPanel>

                <TabPanel value="6" className="tabs-tentang tab-section">
                    <>
                        {data.profile.namaPanggilan !== null ? (
                            <div className="tab-content">
                                <div className="user-more">
                                    <MoreVertIcon onClick={handleClickOpenMore}/>
                                    <ul className={openMore ? "menu-more active" : "menu-more"}>
                                        <li
                                            onClick={() => {
                                                setModal(true);
                                                setOpenMore(false);
                                                setTypeModal("setting");
                                            }}
                                        >
                                            Edit
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-post">
                                    <Grid container justifyContent="space-around" spacing={2}>
                                        <Grid item xs={3}>
                                            <strong>
                                                <p>Nama Panggilan</p>
                                            </strong>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <strong>:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <p>{data.profile.namaPanggilan}</p>
                                        </Grid>
                                    </Grid>
                                    {/* <p>{data.profile.lsm.tentang}</p> */}
                                </div>
                            </div>
                        ) : (
                            <AddCircleIcon onClick={openSetting}/>
                        )}
                    </>
                </TabPanel>
            </TabContext>
        </div>
    );
}

function TabPortofolio({
                           data,
                           setModal,
                           setOpenMore,
                           handleClickOpenMore,
                           openMore,
                           setTypeModal,
                           openPortofolio,
                           asguest = false,
                       }) {
    return (
        <>
            {data.profile.lsm.portfolio.length !== 0 ? (
                <div className="tab-content">
                    {!asguest && (
                        <div className="user-more">
                            <MoreVertIcon onClick={handleClickOpenMore}/>
                            <ul className={openMore ? "menu-more active" : "menu-more"}>
                                <li
                                    onClick={() => {
                                        setModal(true);
                                        setOpenMore(false);
                                        setTypeModal("portofolio");
                                    }}
                                >
                                    Edit
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="tab-post portfolio">
                        <Grid container spacing={5}>
                            {data.profile.lsm.portfolio.map((e, i) => (
                                <Grid item xs={12} key={e.id}>
                                    {e.fotoKegiatan === null ? (
                                        ""
                                    ) : (
                                        <Grid item xs={12} className="child-grid">
                                            <img
                                                src={`${configData.BASE_URL}/api/misc/${e.fotoKegiatan}`}
                                                alt=""
                                                className="img-portfolio"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} className="child-grid">
                                        <p>
                                            <strong>{e.namaKegiatan ? e.namaKegiatan : "-"}</strong>
                                        </p>
                                        <p>{e.ringkasan ? e.ringkasan : "-"}</p>
                                    </Grid>
                                    <Grid item xs={12} className="child-grid">
                                        <p>
                                            <strong>Lokasi</strong>
                                        </p>
                                        <p>{e.lokasi ? e.lokasi : "-"}</p>
                                    </Grid>
                                    <Grid item xs={12} className="child-grid">
                                        {" "}
                                        <p>
                                            <strong>Tahun Tanda Tangan Kontrak</strong>
                                        </p>
                                        <p>
                                            {e.tahunTandaTanganKontrak
                                                ? e.tahunTandaTanganKontrak
                                                : "-"}
                                        </p>
                                    </Grid>
                                    <Grid item xs={12} className="child-grid">
                                        <p>
                                            <strong>Lama Kontrak</strong>
                                        </p>
                                        <p>{e.lamaKontrak ? e.lamaKontrak : "-"}</p>
                                    </Grid>
                                    <Grid item xs={12} className="child-grid">
                                        <p>
                                            <strong>Lembaga Sumber Pendanaan</strong>
                                        </p>
                                        <p>{e.sumberPemberiKerja ? e.sumberPemberiKerja : "-"}</p>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            ) : (
                !asguest && <AddCircleIcon onClick={openPortofolio}/>
            )}
        </>
    );
}

function TabContacts({
                         data,
                         asguest = false,
                         openKontak,
                         handleClickOpenMore,
                         openMore,
                         setModal,
                         setOpenMore,
                         setTypeModal,
                     }) {
    return (
        <>
            {data.profile.lsm ? (
                <div className="tab-content">
                    {!asguest && (
                        <div className="user-more">
                            <MoreVertIcon onClick={handleClickOpenMore}/>
                            <ul className={openMore ? "menu-more active" : "menu-more"}>
                                <li
                                    onClick={() => {
                                        setModal(true);
                                        setOpenMore(false);
                                        setTypeModal("kontak");
                                    }}
                                >
                                    Edit
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="tab-post">
                        <Grid container justifyContent="space-around" spacing={2}>
                            <Grid item xs={3}>
                                <strong>
                                    <p>Nama Organisasi</p>
                                </strong>
                            </Grid>
                            <Grid item xs={1}>
                                <strong>:</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <p>{data.profile.lsm.namaOrganisasi}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <strong>
                                    <p>Email</p>
                                </strong>
                            </Grid>
                            <Grid item xs={1}>
                                <strong>:</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <p>{data.profile.email}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <strong>
                                    <p>Alamat</p>
                                </strong>
                            </Grid>
                            <Grid item xs={1}>
                                <strong>:</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <p>{data.profile.lsm.alamat}</p>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                !asguest && <AddCircleIcon onClick={openKontak}/>
            )}
        </>
    );
}

function TabSdm({
                    data,
                    asguest = false,
                    handleClickOpenMore,
                    openMore,
                    setModal,
                    setOpenMore,
                    setTypeModal,
                    openSdm,
                }) {
    return (
        <>
            {data.profile.lsm.tenagaAhli.length > 0 ? (
                <div className="tab-content">
                    {!asguest && (
                        <div className="user-more">
                            <MoreVertIcon onClick={handleClickOpenMore}/>
                            <ul className={openMore ? "menu-more active" : "menu-more"}>
                                <li
                                    onClick={() => {
                                        setModal(true);
                                        setOpenMore(false);
                                        setTypeModal("SDM");
                                    }}
                                >
                                    Edit
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="tab-post sdm">
                        <Grid container spacing={5} style={{padding: 20}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h3>
                                        {data.profile.lsm.tenagaAhli.filter(
                                            (ahli) => ahli.tipeTenagaAhli === "TenagaAhli"
                                        ).length !== 0
                                            ? "Tenaga Ahli"
                                            : ""}
                                    </h3>
                                </Grid>
                                {data.profile.lsm.tenagaAhli.length !== 0
                                    ? data.profile.lsm.tenagaAhli
                                        .filter((ahli) => ahli.tipeTenagaAhli === "TenagaAhli")
                                        .map((e, index) => (
                                            <Grid item={true} xs={12} sm={6} key={index}>
                                                <p>
                                                    <strong>Nama :</strong> {e.nama}
                                                </p>
                                                <p>
                                                    <strong>Pendidikan Terakhir :</strong>{" "}
                                                    {e.pendidikanTerakhir}
                                                </p>
                                                <p>
                                                    <strong>Bidang Keahlian :</strong>{" "}
                                                    {e.bidangKeahlian}
                                                </p>
                                                <p>
                                                    <strong>Pengalaman Kerja :</strong>{" "}
                                                    {e.pengalamanKerjaMinimal === "20"
                                                        ? "> " + e.pengalamanKerjaMinimal
                                                        : e.pengalamanKerjaMinimal +
                                                        " - " +
                                                        e.pengalamanKerjaMaksimal +
                                                        " Tahun"}
                                                </p>
                                                <p>
                                                    <strong>LinkedIn : </strong>{" "}
                                                    <Link
                                                        onClick={() =>
                                                            window.open(`https://${e.linkedin}`, "_blank")
                                                        }
                                                    >
                                                        {e.linkedin === null ? "-" : e.linkedin}
                                                    </Link>
                                                </p>
                                                <p>
                                                    <strong>Website atau Social Media : </strong>{" "}
                                                    <Link
                                                        onClick={() =>
                                                            window.open(`https://${e.website}`, "_blank")
                                                        }
                                                    >
                                                        {e.website === null ? "-" : e.website}
                                                    </Link>
                                                </p>
                                            </Grid>
                                        ))
                                    : ""}
                                <Grid item xs={12}>
                                    <h3>
                                        {data.profile.lsm.tenagaAhli.filter(
                                            (ahli) => ahli.tipeTenagaAhli === "TenagaManajerial"
                                        ).length !== 0
                                            ? "Tenaga Manajerial"
                                            : ""}
                                    </h3>
                                </Grid>
                                {data.profile.lsm.tenagaAhli.length !== 0
                                    ? data.profile.lsm.tenagaAhli
                                        .filter(
                                            (ahli) => ahli.tipeTenagaAhli === "TenagaManajerial"
                                        )
                                        .map((e) => (
                                            <Grid item xs={12} sm={6}>
                                                <p>
                                                    <strong>Nama :</strong> {e.nama}
                                                </p>
                                                <p>
                                                    <strong>Pendidikan Terakhir :</strong>{" "}
                                                    {e.pendidikanTerakhir}
                                                </p>
                                                <p>
                                                    <strong>Bidang Keahlian :</strong>{" "}
                                                    {e.bidangKeahlian}
                                                </p>
                                                <p>
                                                    <strong>Pengalaman Kerja :</strong>{" "}
                                                    {e.pengalamanKerjaMinimal === "20"
                                                        ? "> " + e.pengalamanKerjaMinimal
                                                        : e.pengalamanKerjaMinimal +
                                                        " - " +
                                                        e.pengalamanKerjaMaksimal +
                                                        " Tahun"}
                                                </p>
                                                <p>
                                                    <strong>LinkedIn : </strong>{" "}
                                                    <Link
                                                        onClick={() =>
                                                            window.open(`https://${e.linkedin}`, "_blank")
                                                        }
                                                    >
                                                        {e.linkedin === null ? "-" : e.linkedin}
                                                    </Link>
                                                </p>
                                                <p>
                                                    <strong>Website atau Social Media : </strong>{" "}
                                                    <Link
                                                        onClick={() =>
                                                            window.open(`https://${e.website}`, "_blank")
                                                        }
                                                    >
                                                        {e.website === null ? "-" : e.website}
                                                    </Link>
                                                </p>
                                            </Grid>
                                        ))
                                    : ""}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            ) : (
                !asguest && <AddCircleIcon onClick={openSdm}/>
            )}
        </>
    );
}

function TabProfile({
                        data,
                        asguest = false,
                        handleClickOpenMore,
                        setModal,
                        openMore,
                        setTypeModal,
                        setOpenMore,
                        openProfile,
                    }) {
    return (
        <>
            {data.profile.lsm.profil !== null ? (
                <div className="tab-content">
                    {!asguest && (
                        <div className="user-more">
                            <MoreVertIcon onClick={handleClickOpenMore}/>
                            <ul className={openMore ? "menu-more active" : "menu-more"}>
                                <li
                                    onClick={() => {
                                        setModal(true);
                                        setOpenMore(false);
                                        setTypeModal("profile");
                                    }}
                                >
                                    Edit
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="tab-post">
                        <p
                            className="post__description"
                            dangerouslySetInnerHTML={{
                                __html: data.profile.lsm.profil,
                            }}
                        />
                    </div>
                </div>
            ) : (
                !asguest && <AddCircleIcon onClick={openProfile}/>
            )}
        </>
    );
}
