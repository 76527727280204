import { React, useState } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import CopyrightIcon from "../Assets/Logo/cp.png";
import LogoFooter from "../Assets/Logo/logo2.png";
import "../Styles/Footer_Styles.css";
import {Modal} from "react-responsive-modal";
import FAQCom from "./FAQ_Com";
import ModalLogo from "../Assets/Logo/wd-logo.png";

function Footer_Com(props) {
  const [modalFaq, setModalFaq] = useState(false);

  return (
    <>
    <Modal
          open={modalFaq}
          center
          showCloseIcon={false}
          closeOnOverlayClick={false}
          onClose={() => ''}>
          <div className="modal-header" style={{textAlign: "center"}}>
              <img src={ModalLogo} alt=""/>
          </div>
          <div className="modal-body">
              <FAQCom />
          </div>
          <div className="modal-footer btn-login">
              <Button onClick={() => setModalFaq(false)} variant="contained">
                  Tutup
              </Button>
          </div>
      </Modal>
      <div className="footer-section">
        <ul>
          <li className="first">
            {" "}
            <Link to="/tentang-linklsm">Tentang</Link>
          </li>
          <li>
            {" "}
            <Link to="/kontak-kami">Pusat Bantuan</Link>{" "}
          </li>
          <li>
            {" "}
            <Link to="#" onClick={() => setModalFaq(true)}>FAQ</Link>{" "}
          </li>
        </ul>
        <div className={"copyright foot"}>
          <p>
            <img src={CopyrightIcon} alt="" />
            2021
          </p>
          <img src={LogoFooter} alt="" />
        </div>
      </div>
    </>
  );
}

export default Footer_Com;
