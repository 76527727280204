import React from 'react';
import "../Styles/SDM_Styles.css"
import ProfilePic from "../Assets/Logo/profil90x90.png";
import configData from "../config.json";

function SDM_Com(props) {
    return (
        <div className="widget sdm-user">
            <div className="sd-title">
                <h3>Konsorsium LinkLSM.id</h3>
            </div>
            <ul>
                {props.data.map((data,index)=>{
                    return(
                        <li>
                            <div className="user-message-detail">
                                <div className="user-message-img">
                                {data.logo === null ? 
                                    <img src={ProfilePic} alt="" style={{
                                        "width": "30px",
                                        "height": "30px",
                                        "-webkit-border-radius": "60px",
                                        "-webkit-background-clip": "padding-box",
                                        "-moz-border-radius": "60px",
                                        "-moz-background-clip": "padding",
                                        "border-radius": "60px",
                                        "background-clip": "padding-box"
                                    }}/> :
                                    <img src={`${configData.BASE_URL}/api/misc/${data.logo}`} alt="" style={{
                                        "width": "30px",
                                        "height": "30px",
                                        "-webkit-border-radius": "60px",
                                        "-webkit-background-clip": "padding-box",
                                        "-moz-border-radius": "60px",
                                        "-moz-background-clip": "padding",
                                        "border-radius": "60px",
                                        "background-clip": "padding-box"
                                    }}/>
                                }
                                </div>
                                <div className="user-message-info">
                                    <h3>{data.nama}</h3>
                                    <p>{data.tagLine}</p>
                                </div>
                            </div>
                            <span>
                                {/* <img src={data.image} alt="" /> */}
                                {/* {data.value} */}
                            </span>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default SDM_Com;