import ProfilePic from "../Assets/Logo/profil90x90.png";
import Label1 from "../Assets/Logo/price1.png";
import Label2 from "../Assets/Logo/price2.png";
import Label3 from "../Assets/Logo/price3.png";
import Label4 from "../Assets/Logo/price4.png";
import Slide1 from "../Assets/Gallery/1.jpg";
import Slide2 from "../Assets/Gallery/2.jpg";
import Slide3 from "../Assets/Gallery/3.jpg";
import Slide4 from "../Assets/Gallery/4.jpg";
import Background from "../Assets/Logo/network.jpg";
import bgImage from "../Assets/Logo/pre.jpg";
import moment from "moment";

export const listLembaga = [
    {
        nama: "Laha",
        desc: "Bantuan Hukum",
    },
    {
        nama: "Kebun 1000",
        desc: "Lingkungan Hidup",
    },
    {
        nama: "AKATIGA",
        desc: "Penelitian",
    },
    {
        nama: "Konsil LSM",
        desc: "Pengembangan LSM",
    },
    {
        nama: "Seknas FITRA",
        desc: "Transparansi Anggaran",
    },
    {
        nama: "Yasmib Sulawesi",
        desc: "Riset & Kajian",
    },
];

export const profileLembaga = [
    {
        name: "AKATIGA",
        desc: "Penelitian",
        image: ProfilePic,
        link: "/profile",
        city: "Bandung",
    },
    {
        name: "Konsil LSM",
        desc: "Pengembangan LSM",
        image: ProfilePic,
        link: "#",
        city: "DKI Jakarta",
    },
    {
        name: "Seknas FITRA",
        desc: "Transparansi Anggaran",
        image: ProfilePic,
        link: "#",
        city: "DKI Jakarta",
    },
    {
        name: "Yasmin Sulawesi",
        desc: "Riset & Kajian",
        image: ProfilePic,
        link: "#",
        city: "Makasar - Sulsel",
    },
    {
        name: "Universitas Antah Berantah",
        desc: "Pendidikan & Riset",
        image: ProfilePic,
        link: "#",
        city: "Bandung",
    },
    {
        name: "Lembaga G",
        desc: "Pendidikan & Penelitian",
        image: ProfilePic,
        link: "#",
        city: "Bandung",
    },
    {
        name: "Paguyuban H",
        desc: "Penelitian",
        image: ProfilePic,
        link: "#",
        city: "Bandung",
    },
    {
        name: "AKATIGA",
        desc: "Penelitian",
        image: ProfilePic,
        link: "#",
        city: "Bandung",
    },
];

export const listSDM = [
    {
        name: "Jessica William",
        job: "Graphic Designer",
        value: "1185",
        image: Label1,
    },
    {
        name: "John Doe",
        job: "PHP Developer",
        value: "1165",
        image: Label2,
    },
    {
        name: "Poonam",
        job: "Wordpress Developer",
        value: "1120",
        image: Label3,
    },
    {
        name: "Bill Gates",
        job: "C & C++ Developer",
        value: "1009",
        image: Label4,
    },
];

export const dataBerita = [
    {
        title: "PELUANG (SEKALIGUS JUGA) TANTANGAN SWAKELOLA TIPE III",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Etiam id magna sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Etiam id magna sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Etiam id magna sit amet",
        image: Background,
        tags: "Swakelola Type III",
    },
    {
        title: "PELUANG (SEKALIGUS JUGA) TANTANGAN SWAKELOLA TIPE III",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Etiam id magna sit amet",
        image: Background,
        tags: "Swakelola Type III",
    },
    {
        title: "PELUANG (SEKALIGUS JUGA) TANTANGAN SWAKELOLA TIPE III",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Etiam id magna sit amet",
        image: Background,
        tags: "Swakelola Type III",
    },
    {
        title: "PELUANG (SEKALIGUS JUGA) TANTANGAN SWAKELOLA TIPE III",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Etiam id magna sit amet...",
        image: Background,
        tags: "Swakelola Type III",
    },
    {
        title: "PELUANG (SEKALIGUS JUGA) TANTANGAN SWAKELOLA TIPE III",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Etiam id magna sit amet...",
        image: Background,
        tags: "Swakelola Type III",
    },
    {
        title: "PELUANG (SEKALIGUS JUGA) TANTANGAN SWAKELOLA TIPE III",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam luctus hendrerit metus, ut ullamcorper quam finibus at. Etiam id magna sit amet...",
        image: Background,
        tags: "Swakelola Type III",
    },
];

export const dataArticle = [
    {
        title: "INI ADALAH JUDUL ARTIKEL/NEWS/INFO DI SECTION INI",
        image: bgImage,
        desc: "Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan",
    },
    {
        title: "INI ADALAH JUDUL ARTIKEL/NEWS/INFO DI SECTION INI",
        image: bgImage,
        desc: "Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan",
    },
];

export const dataSlider = [
    {
        title: "INI ADALAH JUDUL",
        image: Slide1,
        desc: "Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan",
    },
    {
        title: "INI ADALAH",
        image: Slide2,
        desc: "Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan",
    },
    {
        title: "INI ADALAH JUDUL",
        image: Slide3,
        desc: "Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan",
    },
    {
        title: "INI ADALAH JUDUL ARTIKEL/NEWS/INFO",
        image: Slide4,
        desc: "Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan",
    },
    {
        title: "INI ADALAH JUDUL",
        image: Slide1,
        desc: "Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan",
    },
    {
        title: "INI ADALAH JUDUL",
        image: Slide2,
        desc: "Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan Dan ini adalah konten dari section tersebut. Ini merupakan preview konten untuk section ini, dan section ini cukup berisi sekitar maksimal 5 paragraf sebagai preview konten sebelum di teruskan dengan",
    },
];

export const dataYayasan = [
    "",
    "Yayasan",
    "Perkumpulan"
];

export const dataTahunKerja = [
    "",
    "1 - 5 Tahun",
    "6 - 10 Tahun",
    "11 - 15 Tahun",
    "16 - 20 Tahun",
    "> 20 Tahun"
];

export const dataPengalaman = [
    {
        tahun: "3 Tahun",
    },
    {
        tahun: "4 Tahun",
    },
    {
        tahun: "5 Tahun",
    },
];

export const dummyBerita = [
    {
        artikelTag: [
            {
                tag: "Swayana",
            },
            {
                tag: "Swayana",
            },
            {
                tag: "Swayana",
            },
        ],
        author: "Ricky",
        createdDate: moment("2021-09-26T12:09:19.208Z").format("DD/MMMM/YYYY"),
        deskripsi:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sit officia eveniet fugiat libero error veniam ad. Repudiandae magni unde nisi ducimus enim dolorum harum. Quisquam adipisci voluptate, ipsa praesentium perspiciatis incidunt exercitationem! Numquam fugit nam velit, esse officiis provident, eaque ab laborum ex, quo vel aspernatur distinctio quas voluptatum magnam.",
        headLine: "Lorem ipsum dolor sit amet.",
        image: Slide1,
        lokasi: "Bandung",
    },
];
