import { Button, FormGroup } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Multiselect } from "multiselect-react-dropdown";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import configData from "../config.json";
import { dataYayasan } from "../Data/Dummy_Data";
import { kota } from "../Data/Kota_Data";
import "../Styles/Filter_Styles.css";
import { CustomInput } from "./InputField_Com";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Filter_Com(props) {
  const { handleSubmit } = useForm();
  const multiselectRef = useRef();
  const { parameter } = props;
  const history = useHistory();
  const [state, setState] = useState({
    namaLembaga: "",
    kompetensi: "",
    bidangKerja: [],
    wilayah: "",
    yayasan: "",
    pengalaman: "",
  });
  const [multiSelectKompetensi, setMultiSelectKompetensi] = useState([]);
  const [multiSelectedKompetensi, setMultiSelectedKompetensi] = useState([]);
  const [kompetensi, setKompetensi] = useState([]);
  const wilayah = {
    options: kota.map((o) => o.nama),
    getOptionLabel: (option) => option || "",
    getOptionSelected: (option, value) => option === value,
  };
  const yayasan = {
    options: dataYayasan,
    getOptionLabel: (option) => option || "",
    getOptionSelected: (option, value) => option === value,
  };
  const [expend, setExpend] = useState(props.isMobile)

  const handleClear = (type) => {
    if (type === "namaLembaga") {
      setState({ ...state, namaLembaga: "" });
    } else if (type === "kompetensi") {
      setState({ ...state, kompetensi: "" });
    } else if (type === "bidangKerja") {
      state.bidangKerja.map((o) => {
        o.checked = false;
        return o;
      });
      state.namaLembaga = "";
      state.kompetensi = "";
      state.wilayah = "";
      state.yayasan = "";
      state.pengalaman = "";
      setState({
        ...state,
      });
      setMultiSelectedKompetensi([]);
      multiselectRef.current.resetSelectedValues();
    } else if (type === "wilayah") {
      setState({ ...state, wilayah: "" });
    } else if (type === "yayasan") {
      setState({ ...state, yayasan: "" });
    } else if (type === "pengalaman") {
      setState({ ...state, pengalaman: "" });
    } else {
      state.bidangKerja.map((o) => {
        o.checked = false;
        return o;
      });
      state.namaLembaga = "";
      state.kompetensi = "";
      state.wilayah = "";
      state.yayasan = "";
      state.pengalaman = "";
      setState({
        ...state,
      });
      setMultiSelectedKompetensi([]);
      multiselectRef.current.resetSelectedValues();
    }
  };
  
  const handleChange = (event) => {
    let kompetensi = event.target.value;
    setState({
      ...state,
      kompetensi: kompetensi,
    });
  };
  
  const onExpend = () => {
    setExpend(!expend)
  }

  const handleChangeNamaLembaga = (event) => {
    let namaLembaga = event.target.value;
    setState({
      ...state,
      namaLembaga: namaLembaga,
    });
  };

  const generateKompetensi = () => {
    fetchKompetensi.then((response) => {
      let multiSelect = response.map((o) => {
        return o.kompetensi;
      });
      setMultiSelectKompetensi(multiSelect);
      let bidangKerja = response.map((o) => {
        return {
          value: o.kompetensi,
          checked: false,
        };
      });
      setKompetensi(bidangKerja);
      setState((o) => {
        o.bidangKerja = bidangKerja;
        return o;
      });
    });
  };

  useEffect(() => {
    setExpend(props.isMobile)
    if (props.pushData === undefined) generateKompetensi();
    else if (
      props.pushData.location.state === null ||
      props.pushData.location.state === "" ||
      props.pushData.location.state === undefined
    )
      generateKompetensi();
    else if (![null, undefined, void 0].includes(props.pushData.location.state.pages))
      generateKompetensi();
    else {
      setState(props.pushData.location.state);
      setKompetensi(props.pushData.location.state.bidangKerja);
      let multiSelect = props.pushData.location.state.bidangKerja.map(
        (o) => o.value
      );
      setMultiSelectKompetensi(multiSelect);
      let multiSelected = props.pushData.location.state.bidangKerja
        .filter((o) => o.checked === true)
        .map((o) => o.value);
      setMultiSelectedKompetensi(multiSelected);
      parameter.setParameter(props.pushData.location.state);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (data) => {
    if (props.database) parameter.setParameter(state)
    else history.push("/database-lembaga", state);
  };

  const onSelect = (a, b) => {
    state.bidangKerja.map((o) => {
      if (o.value === b) {
        o.checked = true;
        return o;
      } else return o;
    });
    setState({
      ...state,
    });
  };

  const onRemove = (a, b) => {
    state.bidangKerja.map((o) => {
      if (o.value === b) {
        o.checked = false;
        return o;
      } else return o;
    });
    setState({
      ...state,
    });
  };

  return (
    <div className="filter-data">
      <div className={expend ? "filter-header" : "filter-header-expend" }>
        <h3>Advanced Filter</h3>
        <p onClick={() => handleClear()}>Bersihkan Filter</p>
        {!props.isMobile ? '' : (
          <ExpandMoreIcon onClick={() => onExpend()} />
        )}
      </div>
      {expend ? '' : (
        <form onSubmit={handleSubmit(search)}>
        <div className="filter-content">
          <div className="input-inner">
            <div className="label-header">
              <p>Nama Organisasi</p>
              <p onClick={() => handleClear("namaLembaga", state.namaLembaga)}>
                Clear
              </p>
            </div>
            <CustomInput
              fullWidth
              name="kompentensi"
              type="text"
              variant="outlined"
              placeholder="Nama Organisasi"
              onChange={handleChangeNamaLembaga}
              value={state.namaLembaga}
            />
          </div>
          <div className="input-inner">
            <div className="label-header">
              <p>Jenis Kegiatan</p>
              <p onClick={() => handleClear("kompetensi", state.kompetensi)}>
                Clear
              </p>
            </div>
            <CustomInput
              fullWidth
              name="kompentensi"
              type="text"
              variant="outlined"
              placeholder="Mis. Penelitian, dsb"
              onChange={handleChange}
              value={state.kompetensi}
            />
          </div>
          {kompetensi.length > 0 ? (
            <div className="input-inner">
              <div className="label-header">
                <p>Sektor Kegiatan</p>
                <p onClick={() => handleClear("bidangKerja")}>Clear</p>
              </div>
              <FormGroup className="on-off-status">
                <Multiselect
                  ref={multiselectRef}
                  isObject={false}
                  options={multiSelectKompetensi} // Options to display in the dropdown
                  selectedValues={multiSelectedKompetensi} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </FormGroup>
            </div>
          ) : (
            ""
          )}
          <div className="input-inner">
            <div className="label-header">
              <p>Wilayah</p>
              <p onClick={() => handleClear("wilayah", state.wilayah)}>Clear</p>
            </div>
            <Autocomplete
              {...wilayah}
              id="disable-clearable"
              disableClearable
              className="autocomplete-input"
              value={state.wilayah}
              openOnFocus
              onChange={(event, newValue) => {
                setState({
                  ...state,
                  wilayah: newValue,
                });
              }}
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  fullWidth
                  name="wilayah"
                  type="text"
                  placeholder="Kota atau Kabupaten"
                  variant="outlined"
                  value={state.wilayah}
                  margin="normal"
                />
              )}
            />
          </div>
          <div className="input-inner">
            <div className="label-header">
              <p>Badan Hukum</p>
              <p onClick={() => handleClear("yayasan", state.yayasan)}>Clear</p>
            </div>
            <Autocomplete
              {...yayasan}
              id="disable-clearable"
              disableClearable
              className="autocomplete-input"
              value={state.yayasan}
              openOnFocus
              onChange={(event, newValue) => {
                setState({
                  ...state,
                  yayasan: newValue,
                });
              }}
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  fullWidth
                  name="yayasan"
                  type="text"
                  placeholder="Yayasan"
                  variant="outlined"
                  value={state.yayasan}
                  margin="normal"
                />
              )}
            />
          </div>
          <div className="input-inner">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ width: "100%" }}
            >
              Cari
            </Button>
          </div>
        </div>
      </form>
      )}
    </div>
  );
}

export default Filter_Com;

const fetchKompetensi = fetch(`${configData.BASE_URL}/api/kompetensi/all`, {
  method: "GET",
}).then((response) => response.json());
