import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Grid,
    TextField,
    InputAdornment,
    Checkbox,
    CircularProgress,
    Radio,
    RadioGroup,
    FormControlLabel,
} from "@material-ui/core";
import "react-responsive-modal/styles.css";
import {Autocomplete} from "@material-ui/lab";
import {Modal} from "react-responsive-modal";
import {Controller, useForm} from "react-hook-form";
import {provinsi} from "../Data/Provinsi_Data";
import {useHistory} from "react-router-dom";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PublicIcon from "@material-ui/icons/Public";
import EmailIcon from "@material-ui/icons/Email";
import LinkLSM from "../Assets/Logo/wd-logo.png";
import "../Styles/Modal_Styles.css";
import {kota} from "../Data/Kota_Data";
import {
    fileUpload,
    getProfile,
    loginData,
    loginIdentitas,
} from "../Logic/APIHandler_Logic";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {DropzoneArea} from "material-ui-dropzone";

import configData from "../config.json"

export function Form1(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [lupaPassword, setLupaPassword] = useState("");
    const [modal, setModal] = useState(false);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();
    const history = useHistory();
    const closeModal = () => {
        setModal(false);
    };
    const onSubmit = async (data) => {
        setError(false);
        setLoading(true);
        let token = await loginData(data);
        if (token) {
            if (token.status === 400) {
                setError(true);
                setErrorMessage("Email atau password Anda salah.");
                setLoading(false);
            } else if (token.status === 500) {
                setError(true);
                setErrorMessage("Email atau password Anda salah.");
                setLoading(false);
            } else {
                let response = await getProfile();
                if (response) {
                    if (response.status === 401) {
                        setError(true);
                        setErrorMessage("Email atau password Anda salah.");
                        setLoading(false);
                    } else if (response.status === 500) {
                        setError(true);
                        setErrorMessage("Email atau password Anda salah.");
                        setLoading(false);
                    } else {
                        if (
                            response.lsm.statusLSM === "Draft" ||
                            response.lsm.statusLSM === "VerifiedFailed" ||
                            response.lsm.statusLSM === null
                        ) {
                            setModal(true);
                            history.push("/register");
                            localStorage.setItem("Email", response.email);
                            localStorage.setItem("Organisasi", response.lsm.namaOrganisasi);
                            localStorage.setItem("Nama", response.namaPengguna);
                            localStorage.setItem('data', JSON.stringify(response))
                        } else if (response.lsm.statusLSM === "Submit") {
                            localStorage.setItem('data', JSON.stringify(response))
                            history.push("/verification");
                        } else {
                            history.push("/");
                            localStorage.setItem("data", JSON.stringify(response));
                            localStorage.setItem("Email", data.username);
                            localStorage.setItem("Login", true);
                        }
                    }
                } else {
                    setError(true);
                    setErrorMessage("Mohon menunggu beberapa saat lagi");
                }
            }
            setLoading(false);
        }
    };

    const submitLupaPassword = async () => {
        setLoading(true);
        try {
            await fetch(`${configData.BASE_URL}/api/lsm/reset_password`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    email: lupaPassword,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        alert("Email Berhasil Dikirim");
                        setModal(false);
                        setLupaPassword(" ");
                        setLoading(false);
                    } else {
                        return response.json();
                    }
                })
                .then((responseJson) => {
                    if (responseJson.status === 400) {
                        alert("Email Tidak Ditemukan");
                        setLoading(false);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        setLupaPassword(e.target.value);
    };
    return (
        <>
            <Modal
                classNames={{
                    modal: "customModal",
                }}
                open={modal}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ''}>
                <form noValidate key={4}>
                    <div className="modal-header">
                        <img src={LinkLSM} alt=""/>
                    </div>
                    <div className="modal-body">
                        <Grid item={true} xs={12}>
                            <TextField
                                variant="outlined"
                                name="usernameLupaPassword"
                                placeholder="Email"
                                type="email"
                                value={lupaPassword}
                                onChange={handleChange}
                                disabled={loading}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonOutlineIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                className={"input-login"}
                            />
                        </Grid>
                    </div>
                    <div className="modal-footer btn-login">
                        <Button
                            onClick={closeModal}
                            variant="contained"
                            disabled={loading}
                            style={{marginRight: 20}}>
                            Tutup
                        </Button>
                        <Button
                            onClick={submitLupaPassword}
                            variant="contained"
                            disabled={loading}>
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className={props.className}
                noValidate
                key={3}>
                <Grid container>
                    <Grid item={true} xs={12}>
                        <TextField
                            variant="outlined"
                            name="username"
                            placeholder="Email"
                            type="email"
                            disabled={loading}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonOutlineIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            className={
                                errors.username || error ? "input-login error" : "input-login"
                            }
                            {...register("username", {
                                required: "Mohon masukan Email.",
                                pattern: {
                                    value:
                                        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                                    message: "Mohon masukan email dengan benar",
                                },
                            })}
                        />
                        {errors.username || error ? (
                            <p
                                className={
                                    errors.username || error ? "error-msg" : "error-msg exit"
                                }
                                id="hidden"
                            >
                                {error ? errorMessage : errors.username.message}
                            </p>
                        ) : (
                            <p
                                className={errors.username ? "error-msg" : "error-msg exit"}
                                id="hidden"
                            >
                                Mohon masukan email dengan benar
                            </p>
                        )}
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextField
                            className={
                                errors.password || error ? "input-login error" : "input-login"
                            }
                            type="password"
                            variant="outlined"
                            name="password"
                            disabled={loading}
                            placeholder="Password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HttpsOutlinedIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            {...register("password", {
                                required: "Mohon masukan Password.",
                            })}
                        />
                        <p
                            className={errors.password ? "error-msg" : "error-msg exit"}
                            id="hidden"
                        >
                            Mohon masukan Password.
                        </p>
                    </Grid>
                    <Grid item={true} xs={12} className="password-control">
                        <p
                            onClick={() => {
                                setModal(true);
                            }}
                            style={{cursor: "pointer"}}
                        >
                            Lupa Password?
                        </p>
                    </Grid>
                    <Grid item={true} xs={12} className="btn-login">
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={loading}>
                            Masuk
                            {loading ? (
                                <CircularProgress size={10} style={{marginLeft: 10}}/>
                            ) : (
                                ""
                            )}
                        </Button>{" "}
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export function Form2(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [modal, setModal] = useState(false);
    const [emailValidasi, setEmailValidasi] = useState("");
    const [modalValidasi, setModalValidasi] = useState(false);
    const [modalSyarat, setModalSyarat] = useState(false);
    const [syarat, setSyarat] = useState(true);
    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
        reset,
    } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    // Handle Modal
    const closeModal = () => {
        setModal(false);
        props.handleChangeLogin("login");
        reset();
    };
    const onSubmit = async (data) => {
        setError(false);
        setLoading(true);
        try {
            await fetch(`${configData.BASE_URL}/api/lsm`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === 400) {
                        setError(true);
                        setErrorMessage("Email atau Organisasi sudah terdaftar.");
                        setLoading(false);
                    } else if (responseJson.status === 500) {
                        setError(true);
                        setErrorMessage(
                            "Terjadi kesalahan pada server. Mohon mencoba dalam beberapa saat lagi."
                        );
                        setLoading(false);
                    } else {
                        setModal(true);
                        setLoading(false);
                    }
                });
        } catch (error) {
            alert(error);
        }
    };
    const submitValidasi = async () => {
        setLoading(true);
        const data = {
            email: emailValidasi,
        };
        try {
            await fetch(
                `${configData.BASE_URL}/api/lsm/activation/resend_activation`,
                {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.status === 200) {
                        alert("Email Berhasil Dikirim");
                        setModalValidasi(false);
                        setEmailValidasi(" ");
                        setLoading(false);
                    } else {
                        return response.json();
                    }
                })
                .then((responseJson) => {
                    if (responseJson.status === 400) {
                        alert("Email Tidak Ditemukan");
                        setLoading(false);
                    }
                });
        } catch (error) {
        }
    };
    const handleChangeEmail = (e) => {
        setEmailValidasi(e.target.value);
    };
    const handleChangeSyarat = (e) => {
        setSyarat(!syarat);
    };
    return (
        <>
            <Modal
                open={modalSyarat}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ''}>
                <div className="modal-header" style={{textAlign: "center"}}>
                    <img src={LinkLSM} alt=""/>
                </div>
                <div className="modal-body syarat">
                    <h1>Kebijakan Penggunaan Aplikasi Database oleh Member</h1>
                    <p>
                        Untuk menjaga agar database dan aplikasi web linklsm.id dapat berjalan sesuai fungsinya,
                        diharapkan para member mematuhi kebijakan penggunaan platform ini. Beberapa kebijakan yang perlu
                        dipahami dan dipatuhi oleh para calon member yang akan mengisi database ini antara lain :
                    </p>
                    <ol type="1">
                        <li>
                            Individu yang akan membuat dan mengelola akun sudah disetujui oleh organisasinya,
                            dibuktikan dengan surat tugas resmi dari organisasi.
                        </li>
                        <li>
                            Akun yang sudah mendaftar akan ditunggu untuk menyelesaikan tahap pengisian form
                            database. Jika tidak diselesaikan, akan ada konfirmasi dari admin melalui email dan telepon
                            apakah akun akan dilanjutkan atau tidak. Secara berkala dalam satu bulan, setiap minggu
                            admin akan menghubungi kontak dari akun untuk menanyakan progress pendaftaran. Selama tidak
                            ada gugatan dari pihak lain, akun akan tetap dapat digunakan member meskipun tidak ada
                            respon.
                        </li>
                        <li>
                            Jika terjadi laporan atau gugatan dari suatu organisasi bahwa mereka tidak bisa mendaftar
                            karena username mereka telah dipakai, akan dilakukan konfirmasi kepada dua belah pihak.
                            Pihak yang dapat menunjukkan surat resmi dari organisasi yang akan diberikan hak untuk
                            membuat akun organisasi yang dimaksud.
                        </li>
                        <li>
                            Pemegang akun harus mengisi dan mengupload semua kebutuhan informasi dan dokumen
                            verifikasi yang diminta dalam rangka mengisi database ini.
                        </li>
                        <li>
                            Setiap akun saat sudah diverifikasi akan bisa mengakses serta mengedit laman profil
                            pengguna dan membuat feed. Pengguna harus bijak dalam memberikan informasi di dalam profil
                            dan feed sehingga tidak melanggar UU ITE yang berkaitan dengan penyebaran informasi palsu,
                            ujaran kebencian, dan hal-hal berbau SARA.
                        </li>
                        <li>
                            Member yang melanggar kebijakan penggunaan aplikasi akan ditindak secara bertahap, dari
                            pemberian peringatan, penonaktifan sementara tampilan profil, hingga penonaktifan akun
                            secara tetap.
                        </li>
                    </ol>
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={() => setModalSyarat(false)} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
            <Modal
                classNames={{
                    modal: "customModal",
                }}
                open={modalValidasi}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
            >
                <form key={1} noValidate>
                    <div className="modal-header">
                        <img src={LinkLSM} alt=""/>
                    </div>
                    <div className="modal-body">
                        <Grid item={true} xs={12}>
                            <TextField
                                variant="outlined"
                                name="emailValidasi"
                                placeholder="Email"
                                type="email"
                                value={emailValidasi}
                                onChange={handleChangeEmail}
                                disabled={loading}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonOutlineIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                className={"input-login"}
                            />
                        </Grid>
                    </div>
                    <div className="modal-footer btn-login">
                        <Button onClick={() => setModalValidasi(false)} variant="contained" style={{marginRight: 10}}>
                            Tutup
                        </Button>
                        <Button onClick={submitValidasi} variant="contained">
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={props.className}
                noValidate
                key={2}
            >
                <Modal
                    classNames={{
                        modal: "customModal",
                    }}
                    open={modal}
                    center
                    showCloseIcon={false}
                    closeOnOverlayClick={false}
                >
                    <div className="modal-header">
                        <img src={LinkLSM} alt=""/>
                    </div>
                    <div className="modal-body">
                        {error ? (
                            errorMessage
                        ) : (
                            <>
                                <h2>Terima Kasih.</h2>{" "}
                                <h2>Mohon menunggu verifikasi dari kami melalui email, mohon cek email spam jika email
                                    kami tidak muncul di pesan utama</h2>{" "}
                            </>
                        )}
                    </div>
                    <div className="modal-footer btn-login">
                        <Button onClick={closeModal} variant="contained">
                            Kembali
                        </Button>
                    </div>
                </Modal>
                <Grid container>
                    <Grid item={true} xs={12}>
                        <TextField
                            variant="outlined"
                            name="namaPengguna"
                            placeholder="Nama Pengguna"
                            disabled={loading}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonOutlineIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            className={
                                errors.namaPengguna ? "input-login error" : "input-login"
                            }
                            {...register("namaPengguna", {
                                required: "Mohon masukan Nama Pengguna.",
                            })}
                        />
                        <p
                            className={errors.namaPengguna ? "error-msg" : "error-msg exit"}
                            id="hidden"
                        >
                            Mohon masukan Nama Pengguna.
                        </p>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextField
                            className={
                                errors.namaOrganisasi || error
                                    ? "input-login error"
                                    : "input-login"
                            }
                            variant="outlined"
                            name="namaOrganisasi"
                            disabled={loading}
                            placeholder="Nama Organisasi"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationCityIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            {...register("namaOrganisasi", {
                                required: "Mohon masukan Nama Organisasi.",
                            })}
                        />
                        <p
                            className={errors.namaOrganisasi ? "error-msg" : "error-msg exit"}
                            id="hidden"
                        >
                            Mohon masukan Nama Organisasi.
                        </p>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextField
                            className={
                                errors.email || error ? "input-login error" : "input-login"
                            }
                            variant="outlined"
                            type="email"
                            name="email"
                            disabled={loading}
                            placeholder="Email Organisasi"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            {...register("email", {
                                required: "Mohon masukan Email Organisasi.",
                                pattern: {
                                    value:
                                        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                                    message: "Mohon masukan email dengan benar",
                                },
                            })}
                        />
                        <p
                            className={errors.email ? "error-msg" : "error-msg exit"}
                            id="hidden"
                        >
                            {errors.email
                                ? errors.email.message
                                : "Mohon masukan email dengan benar"}
                        </p>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextField
                            className={errors.password ? "input-login error" : "input-login"}
                            type="password"
                            disabled={loading}
                            variant="outlined"
                            name="password"
                            placeholder="Password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HttpsOutlinedIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            {...register("password", {
                                required: "Mohon masukan Password.",
                                minLength: {
                                    value: 8,
                                    message: "Password kurang dari 8 karakter.",
                                },
                            })}
                        />
                        <p
                            className={errors.password ? "error-msg" : "error-msg exit"}
                            id="hidden"
                        >
                            {errors.password
                                ? errors.password.message
                                : "Password kurang dari 8 karakter."}
                        </p>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextField
                            className={
                                errors.confirmPassword ? "input-login error" : "input-login"
                            }
                            type="password"
                            variant="outlined"
                            name="confirmPassword"
                            disabled={loading}
                            placeholder="Ulangi Password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HttpsOutlinedIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            {...register("confirmPassword", {
                                validate: (value) =>
                                    value === password.current || "Mohon masukan Password.",
                            })}
                        />
                        <p
                            className={
                                errors.confirmPassword ? "error-msg" : "error-msg exit"
                            }
                            id="hidden"
                        >
                            Password tidak sesuai.
                        </p>
                    </Grid>
                    <Grid item={true} xs={12} className="password-control">
                        <div className="remember-me">
                            <Checkbox value={syarat} onChange={handleChangeSyarat}/>
                            <p>
                                Ya, saya telah membaca dan setuju dengan{" "}
                                <span
                                    style={{cursor: "pointer", color: "blue"}}
                                    onClick={() => setModalSyarat(true)}>
                                      ketentuan dan kebijakan
                                </span>
                                LinkLSM.id.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: 15}}>
                        <div className="password-control">
                            <p>
                                Belum menerima email ?{" "}
                                <span
                                    style={{color: "blue", cursor: "pointer"}}
                                    onClick={() => {
                                        setModalValidasi(true);
                                    }}
                                >
                  {" "}
                                    Klik Disini{" "}
                </span>
                            </p>
                        </div>
                    </Grid>
                    <Grid item={true} xs={12} className="btn-login">
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={loading || syarat}
                        >
                            Daftar
                            {loading ? (
                                <CircularProgress size={10} style={{marginLeft: 10}}/>
                            ) : (
                                ""
                            )}
                        </Button>{" "}
                        <p
                            className={error ? "error-msg" : "error-msg exit"}
                            style={{margin: 0, marginLeft: 10}}
                            id="hidden"
                        >
                            {errorMessage}
                        </p>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export function Form3(props) {
    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
    } = useForm();
    const history = useHistory();
    const password = useRef({});
    password.current = watch("password", "");
    const onSubmit = async (data) => {
        history.push("/");
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={props.className}>
            <Grid container>
                <Grid item={true} xs={12}>
                    <TextField
                        variant="outlined"
                        name="name"
                        placeholder="Nama Pengguna"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonOutlineIcon/>
                                </InputAdornment>
                            ),
                        }}
                        className="input-login"
                        {...register("name", {
                            required: "Mohon masukan Nama Pengguna.",
                        })}
                    />
                    <p
                        className={errors.name ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon masukan Nama Pengguna.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        className="input-login"
                        variant="outlined"
                        name="lembaga"
                        placeholder="Nama Organisasi"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LocationCityIcon/>
                                </InputAdornment>
                            ),
                        }}
                        {...register("lembaga", {
                            required: "Mohon masukan Nama Organisasi.",
                        })}
                    />
                    <p
                        className={errors.lembaga ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon masukan Nama Organisasi.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        className="input-login"
                        variant="outlined"
                        type="email"
                        name="email"
                        placeholder="Email Lembaga"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon/>
                                </InputAdornment>
                            ),
                        }}
                        {...register("email", {
                            required: "Mohon masukan Email Lembaga.",
                        })}
                    />
                    <p
                        className={errors.email ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon masukan Email Lembaga.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        className="input-login"
                        variant="outlined"
                        name="wilayah"
                        placeholder="Wilayah"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PublicIcon/>
                                </InputAdornment>
                            ),
                        }}
                        {...register("wilayah", {
                            required: "Mohon masukan Wilayah.",
                        })}
                    />
                    <p
                        className={errors.wilayah ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon masukan Wilayah.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        className="input-login"
                        type="password"
                        variant="outlined"
                        name="password"
                        placeholder="Password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HttpsOutlinedIcon/>
                                </InputAdornment>
                            ),
                        }}
                        {...register("password", {
                            required: "Mohon masukan Password.",
                        })}
                    />
                    <p
                        className={errors.password ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon masukan Password.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        className="input-login"
                        type="password"
                        variant="outlined"
                        name="password_repeat"
                        placeholder="Ulangi Password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HttpsOutlinedIcon/>
                                </InputAdornment>
                            ),
                        }}
                        {...register("password_repeat", {
                            validate: (value) =>
                                value === password.current || "Mohon masukan Password.",
                        })}
                    />
                    <p
                        className={errors.password_repeat ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Password tidak sesuai.
                    </p>
                </Grid>
                <Grid item={true} xs={12} className="password-control">
                    <div className="remember-me">
                        <Checkbox/>
                        <p>
                            Ya, saya telah membaca dan setuju dengan ketentuan dan kebijakan
                            LinkLSM.id.
                        </p>
                    </div>
                </Grid>
                <Grid item={true} xs={12} className="btn-login">
                    <Button variant="contained" type="submit">
                        Simpan
                    </Button>{" "}
                </Grid>
            </Grid>
        </form>
    );
}

export function Form4(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [modal, setModal] = useState(false);
    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
        setValue,
    } = useForm();
    const [state, setState] = useState({
        kecamatan: "",
        kota: "",
    });
    const history = useHistory();
    useEffect(() => {
        localStorage.getItem("Organisasi");
    }, []);
    const kecamatan = {
        options: provinsi,
        getOptionLabel: (option) => option.nama,
    };
    const kotaAtauKabupaten = {
        options: kota,
        getOptionLabel: (option) => option.nama,
    };

    const useStyles = makeStyles((theme) =>
        createStyles({
            previewChip: {
                minWidth: 160,
                maxWidth: 210,
            },
        })
    );
    const classes = useStyles();
    // Handle Modal
    const closeModal = () => {
        if (error) {
            setModal(false);
        } else {
            setModal(false);
            history.push("/");
        }
    };
    useEffect(() => {
        setValue("namaOrganisasi", localStorage.getItem("Organisasi"));
    }, [setValue]);

    const wait = (ms) =>
        new Promise((resolve, reject) => setTimeout(resolve, ms));

    const onSubmit = async (data) => {
        setError(false);
        setLoading(true);
        let dataLSM = await loginIdentitas(data);
        await wait(5000);
        await fileUpload(data.buktiKantor[0]);
        if (dataLSM.status === 500) {
            setError(true);
            setModal(true);
            setErrorMessage(
                "Terjadi kesalahan pada server. Mohon mencoba dalam beberapa saat lagi."
            );
            setLoading(false);
        } else {
            setModal(true);
            setLoading(false);
        }
        setLoading(false);
    };
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={props.className}
            noValidate
        >
            <Modal
                classNames={{
                    modal: "customModal",
                }}
                open={modal}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
            >
                <div className="modal-header">
                    <img src={LinkLSM} alt=""/>
                </div>
                <div className="modal-body">
                    {error ? errorMessage : <p>Data berhasil disimpan.</p>}
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={closeModal} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
            <Grid container>
                <Grid item={true} xs={12}>
                    <TextField
                        className={"input-login"}
                        variant="outlined"
                        name="namaOrganisasi"
                        value={localStorage.getItem("Organisasi")}
                        {...register("namaOrganisasi")}
                        disabled={true}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LocationCityIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p
                        className={errors.namaOrganisasi ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon masukan Nama Organisasi.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        className={errors.alamat ? "input-login error" : "input-login"}
                        variant="outlined"
                        type="text"
                        name="alamat"
                        disabled={loading}
                        placeholder="Alamat Lengkap Lembaga"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PublicIcon/>
                                </InputAdornment>
                            ),
                        }}
                        {...register("alamat", {
                            required: "Mohon masukan Alamat Lembaga.",
                        })}
                    />
                    <p
                        className={errors.alamat ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        {errors.alamat
                            ? errors.alamat.message
                            : "Mohon masukan Alamat Lembaga"}
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <Autocomplete
                        {...kecamatan}
                        id="disable-clearable"
                        disableClearable
                        className="autocomplete-input"
                        value={state.kecamatan}
                        openOnFocus
                        onChange={(event, newValue) => {
                            setState({
                                ...state,
                                kecamatan: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="kecamatan"
                                type="text"
                                placeholder="Kecamatan"
                                variant="outlined"
                                disabled={loading ? true : false}
                                value={state.kecamatan}
                                className={
                                    errors.kecamatan ? "input-login error" : "input-login"
                                }
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            style={{paddingLeft: 15}}
                                        >
                                            <PublicIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register("kecamatan", {
                                    required: "Mohon masukan Kecamatan.",
                                })}
                            />
                        )}
                    />
                    <p
                        className={errors.kecamatan ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon masukan Kecamatan.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <Autocomplete
                        {...kotaAtauKabupaten}
                        id="disable-clearable"
                        disableClearable
                        className="autocomplete-input"
                        value={state.kota}
                        openOnFocus
                        onChange={(event, newValue) => {
                            setState({
                                ...state,
                                kota: newValue,
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="kotaAtauKabupaten"
                                type="text"
                                placeholder="Kota/Kab"
                                variant="outlined"
                                disabled={loading}
                                value={state.kota}
                                className={
                                    errors.kotaAtauKabupaten ? "input-login error" : "input-login"
                                }
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            style={{paddingLeft: 15}}
                                        >
                                            <PublicIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register("kotaAtauKabupaten", {
                                    required: "Mohon masukan Kota/Kabupaten.",
                                })}
                            />
                        )}
                    />
                    <p
                        className={
                            errors.kotaAtauKabupaten ? "error-msg" : "error-msg exit"
                        }
                        id="hidden"
                    >
                        Mohon masukan Kota/Kabupaten.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        className={errors.kodePos ? "input-login error" : "input-login"}
                        variant="outlined"
                        name="kodePos"
                        placeholder="Kode Pos"
                        disabled={loading ? true : false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PublicIcon/>
                                </InputAdornment>
                            ),
                        }}
                        {...register("kodePos", {
                            required: "Mohon masukan Kode Pos.",
                        })}
                    />
                    <p
                        className={errors.kodePos ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon masukan Kode Pos.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className="label-header">
                        <p>Status Kantor</p>
                    </div>
                    <Controller
                        rules={{required: true}}
                        control={control}
                        defaultValue="KantorPusat"
                        name="statusKantor"
                        render={({field: {onChange, value}}) => (
                            <RadioGroup
                                value={value}
                                onChange={onChange}
                                className="on-off-status"
                            >
                                <FormControlLabel
                                    value="KantorPusat"
                                    control={<Radio/>}
                                    label="Kantor Pusat"
                                />
                                <FormControlLabel
                                    value="KantorCabang"
                                    control={<Radio/>}
                                    label="Kantor Cabang"
                                />
                            </RadioGroup>
                        )}
                    />
                    <p
                        className={errors.statusKantor ? "error-msg" : "error-msg exit"}
                        id="hidden"
                    >
                        Mohon mengisi Status Kantor.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className="label-header">
                        <p>Kepemilikan Kantor</p>
                    </div>
                    <Controller
                        rules={{required: true}}
                        control={control}
                        defaultValue="sendiri"
                        name="kepemilikanKantor"
                        render={({field: {onChange, value}}) => (
                            <RadioGroup
                                value={value}
                                onChange={onChange}
                                className="on-off-status"
                            >
                                <FormControlLabel
                                    value="sendiri"
                                    control={<Radio/>}
                                    label="Sendiri"
                                />
                                <FormControlLabel
                                    value="Sewa"
                                    control={<Radio/>}
                                    label="Sewa"
                                />
                            </RadioGroup>
                        )}
                    />
                    <p
                        className={
                            errors.kepemilikanKantor ? "error-msg" : "error-msg exit"
                        }
                        id="hidden"
                    >
                        Mohon mengisi Status Kantor.
                    </p>
                </Grid>
                <Grid item={true} xs={12}>
                    <h3>Bukti Status Kantor</h3>
                    <Controller
                        rules={{required: true}}
                        control={control}
                        name="buktiKantor"
                        render={({field: {onChange}}) => (
                            <DropzoneArea
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                                onChange={onChange}
                                useChipsForPreview
                                previewGridProps={{
                                    container: {spacing: 1, direction: "row"},
                                }}
                                previewChipProps={{
                                    classes: {root: classes.previewChip},
                                }}
                                previewText="File yang dipilih :"
                            />
                        )}
                    />
                </Grid>
                <Grid item={true} xs={12} className="btn-login">
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                    >
                        Submit
                        {loading ? (
                            <CircularProgress size={10} style={{marginLeft: 10}}/>
                        ) : (
                            ""
                        )}
                    </Button>{" "}
                </Grid>
            </Grid>
        </form>
    );
}
