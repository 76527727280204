import React, {useEffect, useState} from "react";
import Icon1 from "../Assets/Logo/icon8.png";
import Icon2 from "../Assets/Logo/icon9.png";
import {Link} from "react-router-dom";
import {Modal} from "react-responsive-modal";
import {Button, CircularProgress, Grid, makeStyles} from "@material-ui/core";
import "../Styles/CardProfile_Styles.css";
import LinkLSM from "../Assets/Logo/icon3.png";
import {Controller, useForm} from "react-hook-form";
import {DropzoneArea} from "material-ui-dropzone";
import ProfilePic from "../Assets/Logo/profil90x90.png";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGlobe} from '@fortawesome/free-solid-svg-icons'
import {faFacebook} from "@fortawesome/free-brands-svg-icons"
import {faLinkedin} from "@fortawesome/free-brands-svg-icons"
import {faTwitter} from "@fortawesome/free-brands-svg-icons"
import {faInstagram} from "@fortawesome/free-brands-svg-icons"
import configData from "../config.json"
import {getProfile} from "../Logic/APIHandler_Logic";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function CardProfile_Coms(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)
    const [isUpload, setIsUpload] = useState(false)
    const [modalFotoProfile, setModalFotoProfile] = useState(false);
    const {
        
        handleSubmit,
        control
    } = useForm();

    useEffect(() => {
        setIsLoading(false)
    }, [props])

    const uploadFotoProfile = async (data) => {
        return new Promise(((resolve, reject) => {
            try {
                const formData = new FormData();
                formData.append("file", data.fotoprofile[0]);
                fetch(`${configData.BASE_URL}/user/lsm/upload/logo_profile`, {
                    method: "POST",
                    credentials: "include",
                    body: formData,
                }).then((response) => {
                    resolve(response)
                });
            } catch (error) {
                reject(error)
            }
        }))
    };

    const handleUploadProfile = async (data) => {
        setIsUpload(true)
        await uploadFotoProfile(data)
        let profile = await getProfile()
        props.data.profile = profile
        setModalFotoProfile(false)
        setIsUpload(false)
    }

    return isLoading ? "" : (
        <div className="card-profile">
            <div>
                <Modal
                    classNames={{
                        modal: "customModal",
                    }}
                    open={modalFotoProfile}
                    center
                    showCloseIcon={false}
                    closeOnOverlayClick={false}
                    onClose={() => ''}>
                    <form noValidate onSubmit={handleSubmit(handleUploadProfile)}>
                        <div className="modal-header">
                            <img src={LinkLSM} alt=""/>
                        </div>
                        <div className="modal-body">
                            <Grid item={true} xs={6} md={6}>
                                <Controller
                                    control={control}
                                    name="fotoprofile"
                                    render={({field: {onChange}}) => (
                                        <DropzoneArea
                                            showPreviews={true}
                                            showPreviewsInDropzone={false}
                                            filesLimit={1}
                                            dropzoneText={"Pilih Foto Profile (min: 140 x 140)"}
                                            onChange={onChange}
                                            useChipsForPreview
                                            previewText="File yang dipilih :"
                                            previewGridProps={{
                                                container: {spacing: 1, direction: "row"},
                                            }}
                                            previewChipProps={{
                                                classes: {root: classes.previewChip},
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </div>
                        <div className="modal-footer btn-login">
                            <Button style={{marginRight: 10}}
                                    onClick={() => {
                                        setModalFotoProfile(false);
                                    }}
                                    variant="contained">
                                Tutup
                            </Button>
                            <Button type="submit" variant="contained" disabled={isUpload}>
                                Submit {isUpload ? <CircularProgress size={10} style={{marginLeft: 10}}/> : ''}
                            </Button>
                        </div>
                    </form>
                </Modal>
            </div>
            <div className="user-profile-page">
                <div className="user-img">
                    {props.data.profile.lsm !== undefined && props.data.profile.lsm.logoProfile !== null ? (
                        <>
                            <img
                                style={{
                                    "width": "150px",
                                    "height": "150px",
                                    "border-radius": "80px",
                                    "background-clip": "padding-box",
                                    "cursor": "pointer",
                                    "background-color": '#FFFFFF',
                                }}
                                src={`${configData.BASE_URL}/api/misc/${props.data.profile.lsm.logoProfile}`}
                                alt=""
                                className="img"
                                onClick={() => {
                                    if (props.data.isGuest) setModalFotoProfile(false)
                                    else setModalFotoProfile(true);
                                }}
                            />
                        </>
                    ) : <img
                        style={{
                            "width": "150px",
                            "height": "150px",
                            "border-radius": "80px",
                            "background-clip": "padding-box",
                            "cursor": "pointer",
                            "background-color": '#FFFFFF',
                        }}
                        src={ProfilePic}
                        alt=""
                        onClick={() => {
                            if (props.data.isGuest) setModalFotoProfile(false)
                            else setModalFotoProfile(true);
                        }}
                    />}
                    {props.data.isGuest ? <Link href="#" className="button4"><span style={{marginRight: 10}}/></Link> :
                        <Link onClick={() => {
                            if (props.data.isGuest) setModalFotoProfile(false)
                            else setModalFotoProfile(true);
                        }} className="button4">
                            <PhotoCameraIcon/>
                        </Link>
                    }
                </div>
                <div className="user-status-job">
                    <ul>
                        <li>
                            <img src={Icon1} alt=""/>
                            {props.data.profile.lsm !== undefined && props.data.profile.lsm.kompetensi.length !== 0 ? (
                                <span style={{paddingLeft: 5}}>{props.data.profile.lsm.kompetensi[0].kompetensi}</span>
                            ) : <span style={{paddingLeft: 5}}>Tidak Ada Spesialisasi</span>}
                        </li>
                        <li>
                            <img src={Icon2} alt=""/>
                            {props.data.profile.lsm !== undefined ? (
                                <span style={{paddingLeft: 5}}>{props.data.profile.lsm.kota}</span>
                            ) : null}
                        </li>
                    </ul>
                </div>
                {props.data.profile.lsm === undefined ? "" : (
                    <ul className="user-social" style={{wordBreak: "break-word"}}>
                        {props.data.profile.lsm.website !== null ? (
                            <li>
                                <Link
                                    onClick={() => window.open(`https://${props.data.profile.lsm.website}`, "_blank")}>
                                    <FontAwesomeIcon icon={faGlobe}/> {props.data.profile.lsm.website}
                                </Link>
                            </li>
                        ) : ""}
                        {props.data.profile.lsm.linkedin !== null ? (
                            <li>
                                <Link
                                    onClick={() => window.open(`https://${props.data.profile.lsm.linkedin}`, "_blank")}>
                                    <FontAwesomeIcon icon={faLinkedin}/> {props.data.profile.lsm.linkedin}
                                </Link>
                            </li>
                        ) : ""}
                        {props.data.profile.lsm.facebook !== null ? (
                            <li>
                                <Link
                                    onClick={() => window.open(`https://${props.data.profile.lsm.facebook}`, "_blank")}>
                                    <FontAwesomeIcon icon={faFacebook}/> {props.data.profile.lsm.facebook}
                                </Link>
                            </li>
                        ) : null}
                        {props.data.profile.lsm.twitter !== null ? (
                            <li>
                                <Link
                                    onClick={() => window.open(`https://${props.data.profile.lsm.twitter}`, "_blank")}>
                                    <FontAwesomeIcon icon={faTwitter}/> {props.data.profile.lsm.twitter}
                                </Link>
                            </li>
                        ) : null}
                        {props.data.profile.lsm.instagram !== null ? (
                            <li>
                                <Link
                                    onClick={() => window.open(`https://${props.data.profile.lsm.instagram}`, "_blank")}>
                                    <FontAwesomeIcon icon={faInstagram}/> {props.data.profile.lsm.instagram}
                                </Link>
                            </li>
                        ) : null}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default CardProfile_Coms;
