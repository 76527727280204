export const kota = [
    {id: 0, nama: ""},
    {id: 1, nama: "Kabupaten Aceh Barat"},
    {id: 2, nama: "Kabupaten Aceh Barat Daya"},
    {id: 3, nama: "Kabupaten Aceh Besar"},
    {id: 4, nama: "Kabupaten Aceh Jaya"},
    {id: 5, nama: "Kabupaten Aceh Selatan"},
    {id: 6, nama: "Kabupaten Aceh Singkil"},
    {id: 7, nama: "Kabupaten Aceh Tamiang"},
    {id: 8, nama: "Kabupaten Aceh Tengah"},
    {id: 9, nama: "Kabupaten Aceh Tenggara"},
    {id: 10, nama: "Kabupaten Aceh Timur"},
    {id: 11, nama: "Kabupaten Aceh Utara"},
    {id: 12, nama: "Kabupaten Bener Meriah"},
    {id: 13, nama: "Kabupaten Bireuen"},
    {id: 14, nama: "Kabupaten Gayo Lues"},
    {id: 15, nama: "Kabupaten Nagan Raya"},
    {id: 16, nama: "Kabupaten Pidie"},
    {id: 17, nama: "Kabupaten Pidie Jaya"},
    {id: 18, nama: "Kabupaten Simeulue"},
    {id: 19, nama: "Kota Banda Aceh"},
    {id: 20, nama: "Kota Langsa"},
    {id: 21, nama: "Kota Lhokseumawe"},
    {id: 22, nama: "Kota Sabang"},
    {id: 23, nama: "Kota Subulussalam"},
    {id: 24, nama: "Kabupaten Asahan"},
    {id: 25, nama: "Kabupaten Batubara"},
    {id: 26, nama: "Kabupaten Dairi"},
    {id: 27, nama: "Kabupaten Deli Serdang"},
    {id: 28, nama: "Kabupaten Humbang Hasundutan"},
    {id: 29, nama: "Kabupaten Karo"},
    {id: 30, nama: "Kabupaten Labuhanbatu"},
    {id: 31, nama: "Kabupaten Labuhanbatu Selatan"},
    {id: 32, nama: "Kabupaten Labuhanbatu Utara"},
    {id: 33, nama: "Kabupaten Langkat"},
    {id: 34, nama: "Kabupaten Mandailing Natal"},
    {id: 35, nama: "Kabupaten Nias"},
    {id: 36, nama: "Kabupaten Nias Barat"},
    {id: 37, nama: "Kabupaten Nias Selatan"},
    {id: 38, nama: "Kabupaten Nias Utara"},
    {id: 39, nama: "Kabupaten Padang Lawas"},
    {id: 40, nama: "Kabupaten Padang Lawas Utara"},
    {id: 41, nama: "Kabupaten Pakpak Bharat"},
    {id: 42, nama: "Kabupaten Samosir"},
    {id: 43, nama: "Kabupaten Serdang Bedagai"},
    {id: 44, nama: "Kabupaten Simalungun"},
    {id: 45, nama: "Kabupaten Tapanuli Selatan"},
    {id: 46, nama: "Kabupaten Tapanuli Tengah"},
    {id: 47, nama: "Kabupaten Tapanuli Utara"},
    {id: 48, nama: "Kabupaten Toba Samosir"},
    {id: 49, nama: "Kota Binjai"},
    {id: 50, nama: "Kota Gunungsitoli"},
    {id: 51, nama: "Kota Medan"},
    {id: 52, nama: "Kota Padangsidempuan"},
    {id: 53, nama: "Kota Pematangsiantar"},
    {id: 54, nama: "Kota Sibolga"},
    {id: 55, nama: "Kota Tanjungbalai"},
    {id: 56, nama: "Kota Tebing Tinggi"},
    {id: 57, nama: "Kabupaten Agam"},
    {id: 58, nama: "Kabupaten Dharmasraya"},
    {id: 59, nama: "Kabupaten Kepulauan Mentawai"},
    {id: 60, nama: "Kabupaten Lima Puluh Kota"},
    {id: 61, nama: "Kabupaten Padang Pariaman"},
    {id: 62, nama: "Kabupaten Pasaman"},
    {id: 63, nama: "Kabupaten Pasaman Barat"},
    {id: 64, nama: "Kabupaten Pesisir Selatan"},
    {id: 65, nama: "Kabupaten Sijunjung"},
    {id: 66, nama: "Kabupaten Solok"},
    {id: 67, nama: "Kabupaten Solok Selatan"},
    {id: 68, nama: "Kabupaten Tanah Datar"},
    {id: 69, nama: "Kota Bukittinggi"},
    {id: 70, nama: "Kota Padang"},
    {id: 71, nama: "Kota Padangpanjang"},
    {id: 72, nama: "Kota Pariaman"},
    {id: 73, nama: "Kota Payakumbuh"},
    {id: 74, nama: "Kota Sawahlunto"},
    {id: 75, nama: "Kota Solok"},
    {id: 76, nama: "Kabupaten Banyuasin"},
    {id: 77, nama: "Kabupaten Empat Lawang"},
    {id: 78, nama: "Kabupaten Lahat"},
    {id: 79, nama: "Kabupaten Muara Enim"},
    {id: 80, nama: "Kabupaten Musi Banyuasin"},
    {id: 81, nama: "Kabupaten Musi Rawas"},
    {id: 82, nama: "Kabupaten Musi Rawas Utara"},
    {id: 83, nama: "Kabupaten Ogan Ilir"},
    {id: 84, nama: "Kabupaten Ogan Komering Ilir"},
    {id: 85, nama: "Kabupaten Ogan Komering Ulu"},
    {id: 86, nama: "Kabupaten Ogan Komering Ulu Selatan"},
    {id: 87, nama: "Kabupaten Ogan Komering Ulu Timur"},
    {id: 88, nama: "Kabupaten Penukal Abab Lematang Ilir"},
    {id: 89, nama: "Kota Lubuklinggau"},
    {id: 90, nama: "Kota Pagar Alam"},
    {id: 91, nama: "Kota Palembang"},
    {id: 92, nama: "Kota Prabumulih"},
    {id: 93, nama: "Kabupaten Bengkalis"},
    {id: 94, nama: "Kabupaten Indragiri Hilir"},
    {id: 95, nama: "Kabupaten Indragiri Hulu"},
    {id: 96, nama: "Kabupaten Kampar"},
    {id: 97, nama: "Kabupaten Kepulauan Meranti"},
    {id: 98, nama: "Kabupaten Kuantan Singingi"},
    {id: 99, nama: "Kabupaten Pelalawan"},
    {id: 100, nama: "Kabupaten Rokan Hilir"},
    {id: 101, nama: "Kabupaten Rokan Hulu"},
    {id: 102, nama: "Kabupaten Siak"},
    {id: 103, nama: "Kota Dumai"},
    {id: 104, nama: "Kota Pekanbaru"},
    {id: 105, nama: "Kabupaten Bintan"},
    {id: 106, nama: "Kabupaten Karimun"},
    {id: 107, nama: "Kabupaten Kepulauan Anambas"},
    {id: 108, nama: "Kabupaten Lingga"},
    {id: 109, nama: "Kabupaten Natuna"},
    {id: 110, nama: "Kota Batam"},
    {id: 111, nama: "Kota Tanjung Pinang"},
    {id: 112, nama: "Kabupaten Batanghari"},
    {id: 113, nama: "Kabupaten Bungo"},
    {id: 114, nama: "Kabupaten Kerinci"},
    {id: 115, nama: "Kabupaten Merangin"},
    {id: 116, nama: "Kabupaten Muaro Jambi"},
    {id: 117, nama: "Kabupaten Sarolangun"},
    {id: 118, nama: "Kabupaten Tanjung Jabung Barat"},
    {id: 119, nama: "Kabupaten Tanjung Jabung Timur"},
    {id: 120, nama: "Kabupaten Tebo"},
    {id: 121, nama: "Kota Jambi"},
    {id: 122, nama: "Kota Sungai Penuh"},
    {id: 123, nama: "Kabupaten Bengkulu Selatan"},
    {id: 124, nama: "Kabupaten Bengkulu Tengah"},
    {id: 125, nama: "Kabupaten Bengkulu Utara"},
    {id: 126, nama: "Kabupaten Kaur"},
    {id: 127, nama: "Kabupaten Kepahiang"},
    {id: 128, nama: "Kabupaten Lebong"},
    {id: 129, nama: "Kabupaten Mukomuko"},
    {id: 130, nama: "Kabupaten Rejang Lebong"},
    {id: 131, nama: "Kabupaten Seluma"},
    {id: 132, nama: "Kota Bengkulu"},
    {id: 133, nama: "Kabupaten Bangka"},
    {id: 134, nama: "Kabupaten Bangka Barat"},
    {id: 135, nama: "Kabupaten Bangka Selatan"},
    {id: 136, nama: "Kabupaten Bangka Tengah"},
    {id: 137, nama: "Kabupaten Belitung"},
    {id: 138, nama: "Kabupaten Belitung Timur"},
    {id: 139, nama: "Kota Pangkal Pinang"},
    {id: 140, nama: "Kabupaten Lampung Tengah"},
    {id: 141, nama: "Kabupaten Lampung Utara"},
    {id: 142, nama: "Kabupaten Lampung Selatan"},
    {id: 143, nama: "Kabupaten Lampung Barat"},
    {id: 144, nama: "Kabupaten Lampung Timur"},
    {id: 145, nama: "Kabupaten Mesuji"},
    {id: 146, nama: "Kabupaten Pesawaran"},
    {id: 147, nama: "Kabupaten Pesisir Barat"},
    {id: 148, nama: "Kabupaten Pringsewu"},
    {id: 149, nama: "Kabupaten Tulang Bawang"},
    {id: 150, nama: "Kabupaten Tulang Bawang Barat"},
    {id: 151, nama: "Kabupaten Tanggamus"},
    {id: 152, nama: "Kabupaten Way Kanan"},
    {id: 153, nama: "Kota Bandar Lampung"},
    {id: 154, nama: "Kota Metro"},
    {id: 155, nama: "Kabupaten Lebak"},
    {id: 156, nama: "Kabupaten Pandeglang"},
    {id: 157, nama: "Kabupaten Serang"},
    {id: 158, nama: "Kabupaten Tangerang"},
    {id: 159, nama: "Kota Cilegon"},
    {id: 160, nama: "Kota Serang"},
    {id: 161, nama: "Kota Tangerang"},
    {id: 162, nama: "Kota Tangerang Selatan"},
    {id: 163, nama: "Kabupaten Bandung"},
    {id: 164, nama: "Kabupaten Bandung Barat"},
    {id: 165, nama: "Kabupaten Bekasi"},
    {id: 166, nama: "Kabupaten Bogor"},
    {id: 167, nama: "Kabupaten Ciamis"},
    {id: 168, nama: "Kabupaten Cianjur"},
    {id: 169, nama: "Kabupaten Cirebon"},
    {id: 170, nama: "Kabupaten Garut"},
    {id: 171, nama: "Kabupaten Indramayu"},
    {id: 172, nama: "Kabupaten Karawang"},
    {id: 173, nama: "Kabupaten Kuningan"},
    {id: 174, nama: "Kabupaten Majalengka"},
    {id: 175, nama: "Kabupaten Pangandaran"},
    {id: 176, nama: "Kabupaten Purwakarta"},
    {id: 177, nama: "Kabupaten Subang"},
    {id: 178, nama: "Kabupaten Sukabumi"},
    {id: 179, nama: "Kabupaten Sumedang"},
    {id: 180, nama: "Kabupaten Tasikmalaya"},
    {id: 181, nama: "Kota Bandung"},
    {id: 182, nama: "Kota Banjar"},
    {id: 183, nama: "Kota Bekasi"},
    {id: 184, nama: "Kota Bogor"},
    {id: 185, nama: "Kota Cimahi"},
    {id: 186, nama: "Kota Cirebon"},
    {id: 187, nama: "Kota Depok"},
    {id: 188, nama: "Kota Sukabumi"},
    {id: 189, nama: "Kota Tasikmalaya"},
    {id: 190, nama: "Kabupaten Banjarnegara"},
    {id: 191, nama: "Kabupaten Banyumas"},
    {id: 192, nama: "Kabupaten Batang"},
    {id: 193, nama: "Kabupaten Blora"},
    {id: 194, nama: "Kabupaten Boyolali"},
    {id: 195, nama: "Kabupaten Brebes"},
    {id: 196, nama: "Kabupaten Cilacap"},
    {id: 197, nama: "Kabupaten Demak"},
    {id: 198, nama: "Kabupaten Grobogan"},
    {id: 199, nama: "Kabupaten Jepara"},
    {id: 200, nama: "Kabupaten Karanganyar"},
    {id: 201, nama: "Kabupaten Kebumen"},
    {id: 202, nama: "Kabupaten Kendal"},
    {id: 203, nama: "Kabupaten Klaten"},
    {id: 204, nama: "Kabupaten Kudus"},
    {id: 205, nama: "Kabupaten Magelang"},
    {id: 206, nama: "Kabupaten Pati"},
    {id: 207, nama: "Kabupaten Pekalongan"},
    {id: 208, nama: "Kabupaten Pemalang"},
    {id: 209, nama: "Kabupaten Purbalingga"},
    {id: 210, nama: "Kabupaten Purworejo"},
    {id: 211, nama: "Kabupaten Rembang"},
    {id: 212, nama: "Kabupaten Semarang"},
    {id: 213, nama: "Kabupaten Sragen"},
    {id: 214, nama: "Kabupaten Sukoharjo"},
    {id: 215, nama: "Kabupaten Tegal"},
    {id: 216, nama: "Kabupaten Temanggung"},
    {id: 217, nama: "Kabupaten Wonogiri"},
    {id: 218, nama: "Kabupaten Wonosobo"},
    {id: 219, nama: "Kota Magelang"},
    {id: 220, nama: "Kota Pekalongan"},
    {id: 221, nama: "Kota Salatiga"},
    {id: 222, nama: "Kota Semarang"},
    {id: 223, nama: "Kota Surakarta"},
    {id: 224, nama: "Kota Tegal"},
    {id: 225, nama: "Kabupaten Bangkalan"},
    {id: 226, nama: "Kabupaten Banyuwangi"},
    {id: 227, nama: "Kabupaten Blitar"},
    {id: 228, nama: "Kabupaten Bojonegoro"},
    {id: 229, nama: "Kabupaten Bondowoso"},
    {id: 230, nama: "Kabupaten Gresik"},
    {id: 231, nama: "Kabupaten Jember"},
    {id: 232, nama: "Kabupaten Jombang"},
    {id: 233, nama: "Kabupaten Kediri"},
    {id: 234, nama: "Kabupaten Lamongan"},
    {id: 235, nama: "Kabupaten Lumajang"},
    {id: 236, nama: "Kabupaten Madiun"},
    {id: 237, nama: "Kabupaten Magetan"},
    {id: 238, nama: "Kabupaten Malang"},
    {id: 239, nama: "Kabupaten Mojokerto"},
    {id: 240, nama: "Kabupaten Nganjuk"},
    {id: 241, nama: "Kabupaten Ngawi"},
    {id: 242, nama: "Kabupaten Pacitan"},
    {id: 243, nama: "Kabupaten Pamekasan"},
    {id: 244, nama: "Kabupaten Pasuruan"},
    {id: 245, nama: "Kabupaten Ponorogo"},
    {id: 246, nama: "Kabupaten Probolinggo"},
    {id: 247, nama: "Kabupaten Sampang"},
    {id: 248, nama: "Kabupaten Sidoarjo"},
    {id: 249, nama: "Kabupaten Situbondo"},
    {id: 250, nama: "Kabupaten Sumenep"},
    {id: 251, nama: "Kabupaten Trenggalek"},
    {id: 252, nama: "Kabupaten Tuban"},
    {id: 253, nama: "Kabupaten Tulungagung"},
    {id: 254, nama: "Kota Batu"},
    {id: 255, nama: "Kota Blitar"},
    {id: 256, nama: "Kota Kediri"},
    {id: 257, nama: "Kota Madiun"},
    {id: 258, nama: "Kota Malang"},
    {id: 259, nama: "Kota Mojokerto"},
    {id: 260, nama: "Kota Pasuruan"},
    {id: 261, nama: "Kota Probolinggo"},
    {id: 262, nama: "Kota Surabaya"},
    {id: 263, nama: "Kota Administrasi Jakarta Barat"},
    {id: 264, nama: "Kota Administrasi Jakarta Pusat"},
    {id: 265, nama: "Kota Administrasi Jakarta Selatan"},
    {id: 266, nama: "Kota Administrasi Jakarta Timur"},
    {id: 267, nama: "Kota Administrasi Jakarta Utara"},
    {id: 268, nama: "Kabupaten Administrasi Kepulauan Seribu"},
    {id: 269, nama: "Kabupaten Bantul"},
    {id: 270, nama: "Kabupaten Gunungkidul"},
    {id: 271, nama: "Kabupaten Kulon Progo"},
    {id: 272, nama: "Kabupaten Sleman"},
    {id: 273, nama: "Kota Yogyakarta"},
    {id: 274, nama: "Kabupaten Badung"},
    {id: 275, nama: "Kabupaten Bangli"},
    {id: 276, nama: "Kabupaten Buleleng"},
    {id: 277, nama: "Kabupaten Gianyar"},
    {id: 278, nama: "Kabupaten Jembrana"},
    {id: 279, nama: "Kabupaten Karangasem"},
    {id: 280, nama: "Kabupaten Klungkung"},
    {id: 281, nama: "Kabupaten Tabanan"},
    {id: 282, nama: "Kota Denpasar"},
    {id: 283, nama: "Kabupaten Bima"},
    {id: 284, nama: "Kabupaten Dompu"},
    {id: 285, nama: "Kabupaten Lombok Barat"},
    {id: 286, nama: "Kabupaten Lombok Tengah"},
    {id: 287, nama: "Kabupaten Lombok Timur"},
    {id: 288, nama: "Kabupaten Lombok Utara"},
    {id: 289, nama: "Kabupaten Sumbawa"},
    {id: 290, nama: "Kabupaten Sumbawa Barat"},
    {id: 291, nama: "Kota Bima"},
    {id: 292, nama: "Kota Mataram"},
    {id: 293, nama: "Kabupaten Alor"},
    {id: 294, nama: "Kabupaten Belu"},
    {id: 295, nama: "Kabupaten Ende"},
    {id: 296, nama: "Kabupaten Flores Timur"},
    {id: 297, nama: "Kabupaten Kupang"},
    {id: 298, nama: "Kabupaten Lembata"},
    {id: 299, nama: "Kabupaten Malaka"},
    {id: 300, nama: "Kabupaten Manggarai"},
    {id: 301, nama: "Kabupaten Manggarai Barat"},
    {id: 302, nama: "Kabupaten Manggarai Timur"},
    {id: 303, nama: "Kabupaten Ngada"},
    {id: 304, nama: "Kabupaten Nagekeo"},
    {id: 305, nama: "Kabupaten Rote Ndao"},
    {id: 306, nama: "Kabupaten Sabu Raijua"},
    {id: 307, nama: "Kabupaten Sikka"},
    {id: 308, nama: "Kabupaten Sumba Barat"},
    {id: 309, nama: "Kabupaten Sumba Barat Daya"},
    {id: 310, nama: "Kabupaten Sumba Tengah"},
    {id: 311, nama: "Kabupaten Sumba Timur"},
    {id: 312, nama: "Kabupaten Timor Tengah Selatan"},
    {id: 313, nama: "Kabupaten Timor Tengah Utara"},
    {id: 314, nama: "Kota Kupang"},
    {id: 315, nama: "Kabupaten Bengkayang"},
    {id: 316, nama: "Kabupaten Kapuas Hulu"},
    {id: 317, nama: "Kabupaten Kayong Utara"},
    {id: 318, nama: "Kabupaten Ketapang"},
    {id: 319, nama: "Kabupaten Kubu Raya"},
    {id: 320, nama: "Kabupaten Landak"},
    {id: 321, nama: "Kabupaten Melawi"},
    {id: 322, nama: "Kabupaten Mempawah"},
    {id: 323, nama: "Kabupaten Sambas"},
    {id: 324, nama: "Kabupaten Sanggau"},
    {id: 325, nama: "Kabupaten Sekadau"},
    {id: 326, nama: "Kabupaten Sintang"},
    {id: 327, nama: "Kota Pontianak"},
    {id: 328, nama: "Kota Singkawang"},
    {id: 329, nama: "Kabupaten Balangan"},
    {id: 330, nama: "Kabupaten Banjar"},
    {id: 331, nama: "Kabupaten Barito Kuala"},
    {id: 332, nama: "Kabupaten Hulu Sungai Selatan"},
    {id: 333, nama: "Kabupaten Hulu Sungai Tengah"},
    {id: 334, nama: "Kabupaten Hulu Sungai Utara"},
    {id: 334, nama: "Kabupaten Kotabaru"},
    {id: 335, nama: "Kabupaten Tabalong"},
    {id: 336, nama: "Kabupaten Tanah Bumbu"},
    {id: 337, nama: "Kabupaten Tanah Laut"},
    {id: 338, nama: "Kabupaten Tapin"},
    {id: 339, nama: "Kota Banjarbaru"},
    {id: 340, nama: "Kota Banjarmasin"},
    {id: 341, nama: "Kabupaten Barito Selatan"},
    {id: 342, nama: "Kabupaten Barito Timur"},
    {id: 343, nama: "Kabupaten Barito Utara"},
    {id: 344, nama: "Kabupaten Gunung Mas"},
    {id: 345, nama: "Kabupaten Kapuas"},
    {id: 346, nama: "Kabupaten Katingan"},
    {id: 347, nama: "Kabupaten Kotawaringin Barat"},
    {id: 348, nama: "Kabupaten Kotawaringin Timur"},
    {id: 349, nama: "Kabupaten Lamandau"},
    {id: 350, nama: "Kabupaten Murung Raya"},
    {id: 351, nama: "Kabupaten Pulang Pisau"},
    {id: 352, nama: "Kabupaten Sukamara"},
    {id: 353, nama: "Kabupaten Seruyan"},
    {id: 354, nama: "Kota Palangka Raya"},
    {id: 355, nama: "Kabupaten Berau"},
    {id: 356, nama: "Kabupaten Kutai Barat"},
    {id: 357, nama: "Kabupaten Kutai Kartanegara"},
    {id: 358, nama: "Kabupaten Kutai Timur"},
    {id: 359, nama: "Kabupaten Mahakam Ulu"},
    {id: 360, nama: "Kabupaten Paser"},
    {id: 361, nama: "Kabupaten Penajam Paser Utara"},
    {id: 362, nama: "Kota Balikpapan"},
    {id: 363, nama: "Kota Bontang"},
    {id: 364, nama: "Kota Samarinda"},
    {id: 365, nama: "Kabupaten Bulungan"},
    {id: 366, nama: "Kabupaten Malinau"},
    {id: 367, nama: "Kabupaten Nunukan"},
    {id: 368, nama: "Kabupaten Tana Tidung"},
    {id: 369, nama: "Kota Tarakan"},
    {id: 370, nama: "Kabupaten Boalemo"},
    {id: 371, nama: "Kabupaten Bone Bolango"},
    {id: 372, nama: "Kabupaten Gorontalo"},
    {id: 373, nama: "Kabupaten Gorontalo Utara"},
    {id: 374, nama: "Kabupaten Pohuwato"},
    {id: 375, nama: "Kota Gorontalo"},
    {id: 376, nama: "Kabupaten Bantaeng"},
    {id: 377, nama: "Kabupaten Barru"},
    {id: 378, nama: "Kabupaten Bone"},
    {id: 379, nama: "Kabupaten Bulukumba"},
    {id: 380, nama: "Kabupaten Enrekang"},
    {id: 381, nama: "Kabupaten Gowa"},
    {id: 382, nama: "Kabupaten Jeneponto"},
    {id: 383, nama: "Kabupaten Kepulauan Selayar"},
    {id: 384, nama: "Kabupaten Luwu"},
    {id: 385, nama: "Kabupaten Luwu Timur"},
    {id: 386, nama: "Kabupaten Luwu Utara"},
    {id: 387, nama: "Kabupaten Maros"},
    {id: 388, nama: "Kabupaten Pangkajene dan Kepulauan"},
    {id: 389, nama: "Kabupaten Pinrang"},
    {id: 390, nama: "Kabupaten Sidenreng Rappang"},
    {id: 391, nama: "Kabupaten Sinjai"},
    {id: 392, nama: "Kabupaten Soppeng"},
    {id: 393, nama: "Kabupaten Takalar"},
    {id: 394, nama: "Kabupaten Tana Toraja"},
    {id: 395, nama: "Kabupaten Toraja Utara"},
    {id: 396, nama: "Kabupaten Wajo"},
    {id: 397, nama: "Kota Makassar"},
    {id: 398, nama: "Kota Palopo"},
    {id: 399, nama: "Kota Parepare"},
    {id: 400, nama: "Kabupaten Bombana"},
    {id: 401, nama: "Kabupaten Buton"},
    {id: 402, nama: "Kabupaten Buton Selatan"},
    {id: 403, nama: "Kabupaten Buton Tengah"},
    {id: 404, nama: "Kabupaten Buton Utara"},
    {id: 405, nama: "Kabupaten Kolaka"},
    {id: 406, nama: "Kabupaten Kolaka Timur"},
    {id: 407, nama: "Kabupaten Kolaka Utara"},
    {id: 408, nama: "Kabupaten Konawe"},
    {id: 409, nama: "Kabupaten Konawe Kepulauan"},
    {id: 410, nama: "Kabupaten Konawe Selatan"},
    {id: 411, nama: "Kabupaten Konawe Utara"},
    {id: 412, nama: "Kabupaten Muna"},
    {id: 413, nama: "Kabupaten Muna Barat"},
    {id: 414, nama: "Kabupaten Wakatobi"},
    {id: 415, nama: "Kota Bau-Bau"},
    {id: 416, nama: "Kota Kendari"},
    {id: 417, nama: "Kabupaten Banggai"},
    {id: 418, nama: "Kabupaten Banggai Kepulauan"},
    {id: 419, nama: "Kabupaten Banggai Laut"},
    {id: 420, nama: "Kabupaten Buol"},
    {id: 421, nama: "Kabupaten Donggala"},
    {id: 422, nama: "Kabupaten Morowali"},
    {id: 423, nama: "Kabupaten Morowali Utara"},
    {id: 424, nama: "Kabupaten Parigi Moutong"},
    {id: 425, nama: "Kabupaten Poso"},
    {id: 426, nama: "Kabupaten Sigi"},
    {id: 427, nama: "Kabupaten Tojo Una-Una"},
    {id: 428, nama: "Kabupaten Toli-Toli"},
    {id: 429, nama: "Kota Palu"},
    {id: 430, nama: "Kabupaten Bolaang Mongondow"},
    {id: 431, nama: "Kabupaten Bolaang Mongondow Selatan"},
    {id: 432, nama: "Kabupaten Bolaang Mongondow Timur"},
    {id: 433, nama: "Kabupaten Bolaang Mongondow Utara"},
    {id: 434, nama: "Kabupaten Kepulauan Sangihe"},
    {id: 435, nama: "Kabupaten Kepulauan Siau Tagulandang Biaro"},
    {id: 436, nama: "Kabupaten Kepulauan Talaud"},
    {id: 437, nama: "Kabupaten Minahasa"},
    {id: 438, nama: "Kabupaten Minahasa Selatan"},
    {id: 439, nama: "Kabupaten Minahasa Tenggara"},
    {id: 440, nama: "Kabupaten Minahasa Utara"},
    {id: 441, nama: "Kota Bitung"},
    {id: 442, nama: "Kota Kotamobagu"},
    {id: 443, nama: "Kota Manado"},
    {id: 444, nama: "Kota Tomohon"},
    {id: 445, nama: "Kabupaten Majene"},
    {id: 446, nama: "Kabupaten Mamasa"},
    {id: 447, nama: "Kabupaten Mamuju"},
    {id: 448, nama: "Kabupaten Mamuju Tengah"},
    {id: 449, nama: "Kabupaten Mamuju Utara"},
    {id: 450, nama: "Kabupaten Polewali Mandar"},
    {id: 451, nama: "Kota Mamuju"},
    {id: 452, nama: "Kabupaten Buru"},
    {id: 453, nama: "Kabupaten Buru Selatan"},
    {id: 454, nama: "Kabupaten Kepulauan Aru"},
    {id: 455, nama: "Kabupaten Maluku Barat Daya"},
    {id: 456, nama: "Kabupaten Maluku Tengah"},
    {id: 457, nama: "Kabupaten Maluku Tenggara"},
    {id: 458, nama: "Kabupaten Maluku Tenggara Barat"},
    {id: 459, nama: "Kabupaten Seram Bagian Barat"},
    {id: 460, nama: "Kabupaten Seram Bagian Timur"},
    {id: 461, nama: "Kota Ambon"},
    {id: 462, nama: "Kota Tual"},
    {id: 463, nama: "Kabupaten Halmahera Barat"},
    {id: 464, nama: "Kabupaten Halmahera Tengah"},
    {id: 465, nama: "Kabupaten Halmahera Utara"},
    {id: 466, nama: "Kabupaten Halmahera Selatan"},
    {id: 467, nama: "Kabupaten Kepulauan Sula"},
    {id: 468, nama: "Kabupaten Halmahera Timur"},
    {id: 469, nama: "Kabupaten Pulau Morotai"},
    {id: 470, nama: "Kabupaten Pulau Taliabu"},
    {id: 471, nama: "Kota Ternate"},
    {id: 472, nama: "Kota Tidore Kepulauan"},
    {id: 473, nama: "Kabupaten Asmat"},
    {id: 474, nama: "Kabupaten Biak Numfor"},
    {id: 475, nama: "Kabupaten Boven Digoel"},
    {id: 476, nama: "Kabupaten Deiyai"},
    {id: 477, nama: "Kabupaten Dogiyai"},
    {id: 478, nama: "Kabupaten Intan Jaya"},
    {id: 479, nama: "Kabupaten Jayapura"},
    {id: 480, nama: "Kabupaten Jayawijaya"},
    {id: 481, nama: "Kabupaten Keerom"},
    {id: 482, nama: "Kabupaten Kepulauan Yapen"},
    {id: 483, nama: "Kabupaten Lanny Jaya"},
    {id: 484, nama: "Kabupaten Mamberamo Raya"},
    {id: 485, nama: "Kabupaten Mamberamo Tengah"},
    {id: 486, nama: "Kabupaten Mappi"},
    {id: 487, nama: "Kabupaten Merauke"},
    {id: 489, nama: "Kabupaten Mimika"},
    {id: 490, nama: "Kabupaten Nabire"},
    {id: 491, nama: "Kabupaten Nduga"},
    {id: 492, nama: "Kabupaten Paniai"},
    {id: 493, nama: "Kabupaten Pegunungan Bintang"},
    {id: 494, nama: "Kabupaten Puncak"},
    {id: 495, nama: "Kabupaten Puncak Jaya"},
    {id: 496, nama: "Kabupaten Sarmi"},
    {id: 497, nama: "Kabupaten Supiori"},
    {id: 498, nama: "Kabupaten Tolikara"},
    {id: 490, nama: "Kabupaten Waropen"},
    {id: 500, nama: "Kabupaten Yahukimo"},
    {id: 501, nama: "Kabupaten Yalimo"},
    {id: 502, nama: "Kota Jayapura"},
    {id: 503, nama: "Kabupaten Fakfak"},
    {id: 504, nama: "Kabupaten Kaimana"},
    {id: 505, nama: "Kabupaten Manokwari"},
    {id: 506, nama: "Kabupaten Manokwari Selatan"},
    {id: 507, nama: "Kabupaten Maybrat"},
    {id: 508, nama: "Kabupaten Pegunungan Arfak"},
    {id: 509, nama: "Kabupaten Raja Ampat"},
    {id: 510, nama: "Kabupaten Sorong"},
    {id: 511, nama: "Kabupaten Sorong Selatan"},
    {id: 512, nama: "Kabupaten Tambrauw"},
    {id: 513, nama: "Kabupaten Teluk Bintuni"},
    {id: 514, nama: "Kabupaten Teluk Wondama"}
]






































































