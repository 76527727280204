import {Link, useHistory} from "react-router-dom";
import "../../Styles/Database_Styles.css";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import React, {createContext, useContext} from "react";

export default function TableViewDatabase(props) {
  const history = useHistory();

  return (
    <>
      {props.dataLembaga.loading ? (
        ""
      ) : (
        <table className="tableDatabase" style={{ marginBottom: 20 }}>
          <thead>
            <tr>
              <td>No</td>
              <TableViewHeaderCell
                title="Nama Organisasi"
                field="lsm.namaOrganisasi"
              />
              <TableViewHeaderCell title="Wilayah" field="lsm.kota" />
              <td>Sektor Kegiatan</td>
            </tr>
          </thead>
          <tbody>
            {props.dataLembaga.data.map((data, index) => {
              let pathName = data.namaPanggilan ?? data.id;

              return (
                <tr>
                  <td>{index + 1 + props.dataLembaga.pages * 20}</td>
                  <td>
                    <Link
                      className="link-namalembaga"
                      to={{
                        pathname: pathName,
                        state:
                          props.dataLembaga.pages === 0
                            ? {
                                pages: 0,
                                guest: data.id !== props?.dataProfile?.id,
                                profiles: {
                                  id: data.id,
                                  panggilan: data.namaPanggilan,
                                },
                              }
                            : {
                                pages: props.dataLembaga.pages,
                                guest: data.id !== props?.dataProfile?.id,
                                profiles: {
                                  id: data.id,
                                  panggilan: data.namaPanggilan,
                                },
                              },
                      }}
                      onClick={() => {
                        history.replace({
                          state:
                            props.dataLembaga.pages === 0
                              ? {
                                  pages: 0,
                                  guest: data.id !== props?.dataProfile?.id,
                                  profiles: {
                                    id: data.id,
                                    panggilan: data.namaPanggilan,
                                  },
                                }
                              : {
                                  pages: props.dataLembaga.pages,
                                  guest: data.id !== props?.dataProfile?.id,
                                  profiles: {
                                    id: data.id,
                                    panggilan: data.namaPanggilan,
                                  },
                                },
                        });
                      }}
                    >
                      {data.lsm.namaOrganisasi}
                    </Link>
                  </td>
                  <td>{data.lsm.kota}</td>
                  <td>
                    {data.lsm.kompetensi.map((e) => e.kompetensi).join(", ")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {/* <Grid container justifyContent="center" alignItems="center">
        <ReactPaginate
          previousLabel={<p>&laquo;</p>}
          nextLabel={<p>&raquo;</p>}
          breakLabel={"..."}
          pageCount={props.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={props.handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          initialPage={history.location.state?.pages || 0}
        />
      </Grid> */}
    </>
  );
}

export const TableViewContext = createContext();
export const TableViewContextProvider = TableViewContext.Provider;

function TableViewHeaderCell({ title, field }) {
  const { key, direction, setSort } = useContext(TableViewContext) || {};
  const currentDir = key === field ? direction : 'desc';
  return (
    <td
      onClickCapture={() =>
        setSort(field, currentDir === "desc" ? "asc" : "desc")
      }
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span>{title}</span>
        {key === field ? (
          direction === "asc" ? (
            <ArrowUpwardIcon />
          ) : (
            <ArrowDownwardIcon />
          )
        ) : null}
      </div>
    </td>
  );
}
