import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Navbar from "../Components/NavigationBar_Com";
import UserData from "../Components/UserData_Com";
import Footer from "../Components/Footer_Com";
import PostTop from "../Components/PostTop_Com";
import SDM from "../Components/SDM_Com";
import ForumKontak from "../Components/ForumKontak_Com";

import configData from "../config.json";

function Kontak_Page(props) {
  const [konsorsium, setDataKonsorsium] = useState([]);
  const [poster, setDataPoster] = useState({
    dataSide: [],
    dataTop: [],
    loading: true,
  });

  useEffect(() => {
    getDataKonsorsium().then((response) => setDataKonsorsium(response));
    getPoster().then((response) => {
      setDataPoster({
        dataSide: response.filter((o) => o.location === "Side"),
        dataTop: response.filter((o) => o.location === "Top"),
        loading: false,
      });
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="main-linklsm">
        <div className="container">
          <div className="linklsm-inner">
            <Grid container spacing={5}>
              <Grid item xs={12} lg={3} md={4}>
                <Grid item xs={12}>
                  <UserData />
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} md={8}>
                <Grid item xs={12}>
                  {poster.loading
                    ? ""
                    : poster.dataTop.map((o) => {
                        return (
                          <PostTop
                            cover={`${configData.BASE_URL}/api/misc/${o.image}`}
                          />
                        );
                      })}
                  <ForumKontak />
                </Grid>
              </Grid>
              <Grid item xs={12} lg={3} md={12}>
                <Grid item xs={12}>
                  <SDM data={konsorsium} />
                  {poster.loading ? (
                    ""
                  ) : poster.dataSide > 0 ? (
                    <div className="widget widget-img">
                      {poster.dataSide.map((o) => {
                        return (
                          <img
                            src={`${configData.BASE_URL}/api/misc/${o.image}`}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        );
                      })}
                    </div>
                  ) : ""}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <footer style={{ background: "white", width: "100%", float: "left" }}>
        <div className="footer-sec">
          <div className="container">
            <Footer />
          </div>
        </div>
      </footer>
    </>
  );
}

export default Kontak_Page;

const getDataKonsorsium = async () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${configData.BASE_URL}/api/konsorsium`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        });
    } catch (error) {
      reject(error);
    }
  });
};

const getPoster = async () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${configData.BASE_URL}/api/poster?laman.equals=Kontak`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          resolve(responseJson);
        });
    } catch (error) {
      reject(error);
    }
  });
};
