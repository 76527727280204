import React, {useEffect, useState} from "react";
import Navbar from "../Components/NavigationBar_Com";
import Footer from "../Components/Footer_Com";
import {Button, CircularProgress, Grid, makeStyles} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import ProfileBackground from "../Assets/Logo/profilheader.png";
import "../Styles/Profile_Styles.css";
import CardProfile from "../Components/CardProfile_Coms";
import PostProfile from "../Components/PostProfile_Com";
import {getProfile} from "../Logic/APIHandler_Logic";
import configData from "../config.json";
import Feed from "../Components/Feeds_Com";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import LinkLSM from "../Assets/Logo/icon3.png";
import {Controller, useForm} from "react-hook-form";
import {DropzoneArea} from "material-ui-dropzone";
import {Modal} from "react-responsive-modal";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


function Profile_Page(props) {
    const [dataProfile, setDataProfile] = useState({
        profile: "",
        isGuest: true,
        openParam: [],
        isLoading: true,
    });
    const classes = useStyles();
    const location = useLocation();
    const [isUpload, setIsUpload] = useState(false);
    const [modalFotoProfile, setModalFotoProfile] = useState(false);
    const {handleSubmit, control} = useForm();

    useEffect(() => {
        const id = props?.location?.state?.profiles?.panggilan ?? props?.location?.state?.profiles?.id;
        const params = id ?? props?.match?.params?.id
        const param = params ?? location.pathname.replace("/", "")

        if (props.location?.state?.guest) {
            getDataByID(param).then((response) => {
                setDataProfile({
                    profile: response,
                    openParam: [],
                    isGuest: true,
                    isLoading: false,
                });
            });
        } else {
            getProfile()
                .then((response) => {
                    if (response.status !== 401) {
                        setDataProfile({
                            profile: response,
                            openParam: [],
                            isGuest: false,
                            isLoading: false,
                        });
                    } else {
                        getDataByID(param).then((response) => {
                            setDataProfile({
                                profile: response,
                                openParam: [],
                                isGuest: true,
                                isLoading: false,
                            });
                        });
                    }
                });
        }
    }, [props, location.pathname]);


    // Get Profile By id API
    const getDataByID = async (param) => {
        return new Promise((resolve, reject) => {
            try {
                fetch(`${configData.BASE_URL}/api/lsm/${param}`, {
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        resolve(responseJson);
                    });
            } catch (error) {
                reject(error);
            }
        });
    };

    const uploadFotoProfile = async (data) => {
        return new Promise((resolve, reject) => {
            try {
                const formData = new FormData();
                formData.append("file", data.fotoSampul[0]);
                fetch(`${configData.BASE_URL}/user/lsm/upload/foto_sampul`, {
                    method: "POST",
                    credentials: "include",
                    body: formData,
                }).then((response) => {
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    const handleUploadSampul = async (data) => {
        setIsUpload(true);
        await uploadFotoProfile(data);
        let upload = await getProfile();
        dataProfile.profile = upload;
        setDataProfile(dataProfile);
        setModalFotoProfile(false);
        setIsUpload(false);
    };

    return dataProfile.isLoading ? (
        ""
    ) : (
        <>
            <Navbar/>
            <Modal
                classNames={{
                    modal: "customModal",
                }}
                open={modalFotoProfile}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ""}
            >
                <form noValidate onSubmit={handleSubmit(handleUploadSampul)}>
                    <div className="modal-header">
                        <img src={LinkLSM} alt=""/>
                    </div>
                    <div className="modal-body">
                        <Grid item={true} xs={6} md={6}>
                            <Controller
                                control={control}
                                name="fotoSampul"
                                render={({field: {onChange}}) => (
                                    <DropzoneArea
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        filesLimit={1}
                                        dropzoneText={"Pilih Foto Sampul (min: 1400 x 315)"}
                                        onChange={onChange}
                                        useChipsForPreview
                                        previewText="File yang dipilih :"
                                        previewGridProps={{
                                            container: {spacing: 1, direction: "row"},
                                        }}
                                        previewChipProps={{
                                            classes: {root: classes.previewChip},
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </div>
                    <div className="modal-footer btn-login">
                        <Button
                            style={{marginRight: 10}}
                            onClick={() => {
                                setModalFotoProfile(false);
                            }}
                            variant="contained"
                        >
                            Tutup
                        </Button>
                        <Button type="submit" variant="contained" disabled={isUpload}>
                            Submit{" "}
                            {isUpload ? (
                                <CircularProgress size={10} style={{marginLeft: 10}}/>
                            ) : (
                                ""
                            )}
                        </Button>
                    </div>
                </form>
            </Modal>
            <div className="cover-profile">
                <img
                    src={
                        dataProfile.profile.lsm?.fotoSampul
                            ? `${configData.BASE_URL}/api/misc/${dataProfile.profile.lsm.fotoSampul}`
                            : ProfileBackground
                    }
                    alt=""
                />
                {dataProfile.isGuest ? (
                    ""
                ) : (
                    <Link
                        onClick={() => {
                            if (dataProfile.isGuest) setModalFotoProfile(false);
                            else setModalFotoProfile(true);
                        }}
                        className="button3">
                        <PhotoCameraIcon style={{float: "left", marginRight: 10}}/>
                        <span style={{marginTop: 4, float: "right"}}>
                          Edit Foto Sampul
                        </span>
                    </Link>
                )}
            </div>
            <div className="main-beranda">
                <div className="container">
                    <div className="beranda-inner">
                        <Grid container spacing={5}>
                            <Grid item xs={12} lg={3} md={12}>
                                <Grid item xs={12}>
                                    <CardProfile data={dataProfile}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={9} md={12}>
                                <Grid item xs={12}>
                                    <h2 className="title- " style={{marginBottom: 20}}>
                                        {dataProfile.profile.lsm?.namaOrganisasi}
                                    </h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <PostProfile
                                        data={dataProfile.profile}
                                        location={location.pathname}
                                        guest={dataProfile.isGuest}
                                    />
                                    <Feed data={dataProfile}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <footer style={{background: "white", width: "100%", float: "left"}}>
                <div className="footer-sec">
                    <div className="container">
                        <Footer/>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Profile_Page;
