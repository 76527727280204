import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, Typography, AccordionDetails, CircularProgress, Grid } from "@material-ui/core";
import { faq } from '../Logic/APIHandler_Logic'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function FAQ_Com(props) {
    const [expanded, setExpanded] = useState('');
    const [faqData, setFaq] = useState({
        data: [],
        loading: true
    });
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        getFaq()
    }, [])

    const getFaq = async () => {
        let response = await faq()
        setFaq({
            data: response,
            loading: false
        })
    }

    return (
        faqData.loading ? <CircularProgress /> : (
            <Grid item={true} xs={0} md={0}>
                {faqData.data.map((data, index) => {
                    return (
                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                            <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`} expandIcon={<ExpandMoreIcon />}>
                                <Typography>{data.pertanyaan}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {data.jawaban.length > 1 ?
                                        (
                                            <ol type="2">
                                                {data.jawaban.map((jawaban) => {
                                                    return (
                                                        <li>{jawaban.jawaban}</li>
                                                    )
                                                })}
                                            </ol>
                                        ) : (
                                            <>
                                                {data.jawaban.map((jawaban) => {
                                                    return (
                                                        <p>{jawaban.jawaban}</p>
                                                    )
                                                })}
                                            </>
                                        )
                                    }
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Grid>
        )
    )
}

export default FAQ_Com;