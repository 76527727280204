import {Button, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Clock from "../Assets/Logo/clock.png";
import Navbar from "../Components/NavigationBar_Com";
import {Link, useHistory, useLocation} from "react-router-dom";
import "../Styles/DetailBerita_Styles.css";
import Footer from "../Components/Footer_Com";

import configData from "../config.json";
import Moment from "react-moment";
import 'moment/locale/id';

function DetailBerita_Page(props) {
    const history = useHistory()
    const [dataBerita, setDataBerita] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const location = useLocation()
    const [circular, setCircular] = useState({
        isLoading: true,
        data: {}
    })

    useEffect(() => {
        const title =
            location
                .pathname
                .replace("/berita/", "")
        getDataBeritaByJudul(title);
        getCircularArtikel(title)
            .then((response) => {
                setCircular({
                    isLoading: false,
                    data: response
                })
            })
    }, [props, location.pathname])

    const getCircularArtikel = (slug) => {
        return new Promise((resolve, reject) => {
            try {
                fetch(
                    `${configData.BASE_URL}/api/artikel/circular/${slug}`,
                    {
                        method: "GET",
                    }
                )
                    .then((response) => response.json())
                    .then((responseJson) => {
                        resolve(responseJson)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }

    const getDataBeritaByJudul = (title) => {
        try {
            fetch(
                `${configData.BASE_URL}/api/artikel/${title}`,
                {
                    method: "GET",
                }
            )
                .then((response) => response.json())
                .then((responseJson) => {
                    setDataBerita(responseJson);
                    setIsLoading(false)
                });
        } catch (error) {
        }
    };

    return isLoading ? (
        ""
    ) : (
        <>
            <Navbar/>
            <div className="main-linklsm">
                <div className="container">
                    <div className="linklsm-inner">
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="container-berita">
                                    <div className="login-form">
                                        <h3>{dataBerita.headLine}</h3>
                                    </div>
                                    <div className="header-berita">
                                        {dataBerita?.image ? (
                                            <img
                                                src={`${configData.BASE_URL}/api/misc/${dataBerita.image}`}
                                                alt=""
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="isi-berita">
                                        <div className="detail-berita">
                                            <div className="date-berita">
                                                <img src={Clock} alt=""/>
                                                <p>
                                                    <Moment fromNow locale="id">
                                                        {dataBerita.createdDate}
                                                    </Moment>
                                                </p>
                                            </div>
                                            <p>Author : {dataBerita.author}</p>
                                        </div>
                                        <ul className="post-tags">
                                            <h4>TAGS : </h4>
                                            {dataBerita.artikelTag !== undefined
                                                ? dataBerita.artikelTag.map((data) => {
                                                    return (
                                                        <li>
                                                            <Link to="#">{data.tag}</Link>
                                                        </li>
                                                    );
                                                })
                                                : null}
                                        </ul>
                                        <div className="content-berita">
                                            <h4 style={{marginBottom: 10}}>
                                                {dataBerita.lokasi}, LinkLSM.id{" "}
                                            </h4>
                                            <div
                                                className="post__description"
                                                dangerouslySetInnerHTML={{__html: dataBerita.body}}
                                            />
                                        </div>
                                        <Grid item xs={12} style={{marginTop: 50}}>
                                            {circular.isLoading ? '' :
                                                (<div style={{display: "block"}}>
                                                    <div style={{float: "left"}} className="btn-login">
                                                        <Link
                                                            to={{
                                                                pathname: `/berita/${circular.data.prevSlug}`,
                                                                state:
                                                                    props.pages === 0
                                                                        ? {
                                                                            berita: {
                                                                                title: circular.data.prevHeadLine,
                                                                                slug: circular.data.prevSlug
                                                                            }
                                                                        }
                                                                        : {
                                                                            pages: props.pages,
                                                                            berita: {
                                                                                title: circular.data.prevHeadLine,
                                                                                slug: circular.data.prevSlug
                                                                            },
                                                                        },
                                                            }}
                                                            onClick={() => {
                                                                document.body.scrollTop = 0; // For Safari
                                                                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                                                history.replace({
                                                                    state:
                                                                        props.pages === 0
                                                                            ? {
                                                                                berita: {
                                                                                    title: circular.data.prevHeadLine,
                                                                                    slug: circular.data.prevSlug
                                                                                }
                                                                            }
                                                                            : {
                                                                                pages: props.pages,
                                                                                berita: {
                                                                                    title: circular.data.prevHeadLine,
                                                                                    slug: circular.data.prevSlug
                                                                                },
                                                                            },
                                                                });
                                                            }}>
                                                            {circular.data.prevSlug == null ? '' :
                                                                (<Button
                                                                    style={{color: "white", fontWeight: "bold"}}
                                                                    title={circular.data.prevHeadLine}
                                                                    variant="contained"
                                                                    type="submit">
                                                                    {"<"} Prev
                                                                </Button>)
                                                            }
                                                        </Link>
                                                    </div>
                                                    <div style={{float: "right"}} className="btn-login">
                                                        <Link
                                                            to={{
                                                                pathname: `/berita/${circular.data.nextSlug}`,
                                                                state:
                                                                    props.pages === 0
                                                                        ? {
                                                                            berita: {
                                                                                title: circular.data.nextHeadLine,
                                                                                slug: circular.data.nextSlug
                                                                            }
                                                                        }
                                                                        : {
                                                                            pages: props.pages,
                                                                            berita: {
                                                                                title: circular.data.nextHeadLine,
                                                                                slug: circular.data.nextSlug
                                                                            },
                                                                        },
                                                            }}
                                                            onClick={() => {
                                                                document.body.scrollTop = 0; // For Safari
                                                                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                                                history.replace({
                                                                    state:
                                                                        props.pages === 0
                                                                            ? {
                                                                                berita: {
                                                                                    title: circular.data.nextHeadLine,
                                                                                    slug: circular.data.nextSlug
                                                                                }
                                                                            }
                                                                            : {
                                                                                pages: props.pages,
                                                                                berita: {
                                                                                    title: circular.data.nextHeadLine,
                                                                                    slug: circular.data.nextSlug
                                                                                },
                                                                            },
                                                                });
                                                            }}>
                                                            {circular.data.nextSlug == null ? '' :
                                                                (<Button
                                                                    style={{color: "white", fontWeight: "bold"}}
                                                                    title={circular.data.nextHeadLine}
                                                                    variant="contained"
                                                                    type="submit">
                                                                    Next {" >"}
                                                                </Button>)
                                                            }
                                                        </Link>
                                                    </div>
                                                </div>)
                                            }
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <footer style={{background: "white", width: "100%", float: "left"}}>
                <div className="footer-sec">
                    <div className="container">
                        <Footer/>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default DetailBerita_Page;
