import React from "react";
import {Link, useHistory} from "react-router-dom";
import "../Styles/ProfileLembaga_Styles.css";
import ProfilePic from "../Assets/Logo/profil90x90.png";
import configData from "../config.json";

function ProfileLembaga_Com(props) {
    const history = useHistory();
    let pathName = props.panggilan ?? props.link

    return (
        <div className="profile-info" key={props.index}>
            <div className="profile-detail" style={{minHeight: 300}}>
                {props.image === null ? (
                    <img
                        src={ProfilePic}
                        alt=""
                        style={{
                            width: "100px",
                            height: "100px",
                            "-webkit-border-radius": "60px",
                            "-webkit-background-clip": "padding-box",
                            "-moz-border-radius": "60px",
                            "-moz-background-clip": "padding",
                            "border-radius": "60px",
                            "background-clip": "padding-box",
                            margin: "7px 0 0 5px",
                            float: "left",
                        }}
                    />
                ) : (
                    <img
                        src={`${configData.BASE_URL}/api/misc/${props.image}`}
                        alt=""
                        style={{
                            width: "100px",
                            height: "100px",
                            "-webkit-border-radius": "60px",
                            "-webkit-background-clip": "padding-box",
                            "-moz-border-radius": "60px",
                            "-moz-background-clip": "padding",
                            "border-radius": "60px",
                            "background-clip": "padding-box",
                            margin: "7px 0 0 5px",
                            float: "left",
                        }}
                    />
                )}
                <h3 style={{padding: 5}}>{props.name}</h3>
                <h4>{props.desc}</h4>
                <p>{props.city}</p>
                <Link
                    to={{
                        pathname: pathName,
                        state:
                            props.pages === 0
                                ? {
                                    pages: 0,
                                    guest: props.link !== props?.dataProfile?.id,
                                    profiles: {id: props.link, panggilan: props.panggilan},
                                }
                                : {
                                    pages: props.pages,
                                    guest: props.link !== props?.dataProfile?.id,
                                    profiles: {id: props.link, panggilan: props.panggilan},
                                },
                    }}
                    onClick={() => {
                        history.replace({
                            state:
                                props.pages === 0
                                    ? {
                                        pages: 0,
                                        guest: props.link !== props?.dataProfile?.id,
                                        profiles: {id: props.link, panggilan: props.panggilan},
                                    }
                                    : {
                                        pages: props.pages,
                                        guest: props.link !== props?.dataProfile?.id,
                                        profiles: {id: props.link, panggilan: props.panggilan},
                                    },
                        });
                    }}
                >
                    Lihat Profil
                </Link>
            </div>
        </div>
    );
}

export default ProfileLembaga_Com;
