import React, {useEffect, useState} from "react";
import {AppBar, Button, CircularProgress,} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {NavLink, useHistory, withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {useWindowDimensions} from "../Logic/Dimension_Logic";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Logo from "../Assets/Logo/logo.png";
import Beranda from "../Assets/Logo/icon1.png";
import Database from "../Assets/Logo/icon2.png";
import LinkLSM from "../Assets/Logo/icon3.png";
import ModalLogo from "../Assets/Logo/wd-logo.png";
import Swatipe from "../Assets/Logo/icon4.png";
import Berita from "../Assets/Logo/icon5.png";
import {Modal} from "react-responsive-modal";
import Kontak from "../Assets/Logo/icon6.png";
import "../Styles/Navbar_Styles.css";
import ProfilePic from "../Assets/Logo/profil90x90.png";
import Cookies from "universal-cookie";
import configData from "../config.json";
import jwt_decode from "jwt-decode";
import FAQCom from "./FAQ_Com";
import {getProfile} from "../Logic/APIHandler_Logic";

const useStyles = makeStyles({
    appBar: {
        background: "#006598",
        width: "100%",
        position: "relative",
    },
    appBarMobile: {
        position: "fixed",
        background: "#4d4d4d",
        boxShadow: "none",
        maxWidth: "1920px",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    toolbarMobile: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        padding: 10,
    },
});

function Navbar() {
    const history = useHistory()
    const [open, setOpen] = useState(false);
    const [openUser, setOpenUser] = useState(false);
    const {width} = useWindowDimensions();
    const classes = useStyles();
    const [modalSyarat, setModalSyarat] = useState(false);
    const [modalFaq, setModalFaq] = useState(false);
    const [modalSyaratTerm, setModalSyaratTerm] = useState(false);
    const [dataProfile, setProfile] = useState();
    const [modalLoading, setLoadingModal] = useState(false);

    useEffect(() => {
        let token = new Cookies().get("Profile") 
        if (token !== undefined) {
            if (token !== "null" && token !== "undefined") {
                let decode = jwt_decode(token);
                setProfile(decode.BEARER);
            }
        }
    }, []);

    const logout = async () => {
        try {
            await fetch(`${configData.BASE_URL}/auth/revoke`, {
                method: "DELETE",
                credentials: "include",
            }).then((response) => {
                localStorage.clear();
                new Cookies().remove("Profile");
                setProfile();
            });
        } catch (error) {
        }
    };

    const toggle = () => {
        setOpen(!open);
    };

    const onPaths = (paths) => {
        return (match, location) => {
            return paths.includes(location.pathname);
        };
    };

    const openUserSetting = () => {
        setOpenUser(!openUser);
    };
    const onClickAway = () => {
        setOpenUser(false);
    };

    const checkAccount = async () => {
        try {
            setLoadingModal(true)
            let response = await getProfile();
            setLoadingModal(false)
            if (response?.lsm.statusLSM === "Verified") {
                const id = response.namaPanggilan || "Profil";
                history.push(`/${id}`)
            } else if (response?.lsm.statusLSM === "Submit") {
                history.push("/verification")
            } else {
                history.push("/register")
            }
        } catch (error) {
            history.push("/login")
        }
    }

    const editProfil = async () => {
        try {
            setLoadingModal(true)
            let response = await getProfile();
            setLoadingModal(false)
            localStorage.setItem("data", JSON.stringify(response))
            history.push("/register")
        } catch (error) {
            history.push("/login")
        }
    }

    return (
        <>
            <Modal
                styles={{opacity: 0}}
                center
                open={modalLoading}
                closeOnOverlayClick={false}
                showCloseIcon={false}
                classNames={{modalContainer: "loading-modal"}}
                onClose={() => ''}>
                <CircularProgress/>
            </Modal>
            <Modal
                open={modalSyarat}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ""}>
                <div className="modal-header" style={{textAlign: "center"}}>
                    <img src={ModalLogo} alt=""/>
                </div>
                <div className="modal-body syarat">
                    <h1>Kebijakan Penggunaan dan Penyimpanan data Member</h1>
                    <p>
                        Sebagian besar dari dokumen verifikasi yang diminta merupakan
                        dokumen yang cukup sensitif dan tentunya bisa disebar secara terbuka
                        di publik. Kami menyadari benar mengenai hal tersebut, oleh sebab
                        itu LinkLSM.id memiliki kebijakan penggunaan dan penyimpanan data
                        member sebagai berikut :
                    </p>
                    <ol type="A">
                        <li>Akses, Perubahan dan Penghapusan Data oleh Member</li>
                        <ol type="1">
                            <li>
                                Setelah form isian utama diverifikasi, member hanya bisa
                                mengakses kembali form tersebut atas seizin admin, dengan
                                mekanisme pengajuan perubahan data form utama kepada admin lewat
                                form Kontak. Perubahan data form utama perlu diverifikasi ulang
                                oleh tim verifikator.
                            </li>
                            <li>
                                Tiap member hanya bisa mengakses form utama milik akun
                                masing-masing, dan tidak dapat mengakses form utama dari member
                                lainnya.
                            </li>
                            <li>
                                Member dapat mengedit secara bebas kategori informasi yang ada
                                di halaman profil (tentang, profil, SDM, dan portofolio) sesuai
                                dengan kebutuhan member.
                            </li>
                            <li>
                                Member dapat menghapus akun yang mereka miliki di platform
                                linklsm.id dengan prosedur pengajuan kepada admin lewat form
                                Kontak dan setelah melalui konfirmasi oleh tim verifikator ke
                                organisasi terkait.
                            </li>
                            <li>
                                Berdasarkan permohonan penghapusan akun yang telah
                                terkonfirmasi, maka semua data pengguna yang bersangkutan yang
                                disimpan oleh platform database linklsm.id baik secara online
                                maupun offline akan dihapus.
                            </li>
                        </ol>
                        <li>Penggunaan Data oleh LinkLSM.id</li>
                        <ol type="1">
                            <li>
                                Semua dokumen verifikasi (.jpeg/.pdf) hanya digunakan oleh
                                linklsm.id dalam proses verifikasi dan untuk pembuktian jika
                                terjadi kasus tertentu yang menuntut LinkLSM
                                mempertanggungjawabkan kebenaran status verifikasi ormas yang
                                sudah muncul di database (dan tentunya atas seizin member yang
                                bersangkutan). LinkLSM.id tidak akan menggunakan dokumen
                                verifikasi selain diluar dua hal tersebut.
                            </li>
                            <li>
                                Dokumen-dokumen tersebut tidak akan muncul dan dapat diakses
                                publik baik di laman profil member, maupun dalam mesin pencarian
                                dan filterisasi.
                            </li>
                            <li>
                                Informasi yang akan dibagikan ke publik sesuai fungsi dari
                                database sebagai marketplace adalah : logo, nama ormas, badan
                                hukum, domisili, kompetensi (semua kategori spesialisasi isu dan
                                bidang kerja), profil yang diisi ormas di laman profil
                                masing-masing (tentang dan profil, SDM, portofofolio), kontak
                                dan sosial media, serta feed yang dibuat oleh masing-masing
                                ormas.
                            </li>
                            <li>
                                Pengguna database jika ingin meminta informasi lebih detail
                                tentang member untuk tujuan procurement akan disarankan
                                menghubungi langsung member terkait.
                            </li>
                            <li>
                                Dalam rangka kerjasama dengan pihak ketiga, dalam hal ini adalah
                                Lembaga Kebijakan Pengadaan Barang/Jasa Pemerintah (LKPP), data
                                member yang dibagikan hanya mengirimkan data dari poin 3. LKPP
                                mempromosikan database linklsm dengan menaruh link akses ke
                                database di laman website LKPP yang relevan dengan Swakelola
                                Tipe III.
                            </li>
                        </ol>
                        <li>Penyimpanan dan Penghapusan Data oleh LinkLSM.id</li>
                        <ol type="1">
                            <li>
                                Semua informasi dan data yang diinput member di saat pendaftaran
                                akan disimpan di cloud LinkLSM.id.
                            </li>
                            <li>
                                Secara rutin, LinkLSM.id akan mengunduh semua dokumen verifikasi
                                (.jpeg/.pdf) yang sudah disetujui, dan kemudian menghapus
                                dokumen-dokumen tersebut dari cloud/penyimpanan online. Dokumen
                                verifikasi akan disimpan dalam database offline. Hal tersebut
                                bertujuan untuk menghindari pencurian data jika suatu saat
                                terjadi serangan yang tidak dapat diatasi. Meski demikian tim
                                pengembang database akan tetap menjaga agar database terhindar
                                dari serangan-serangan yang merugikan.
                            </li>
                            <li>
                                Jika member meminta untuk penghapusan akun, maka semua data
                                member yang disimpan oleh Linklsm.id baik secara online dan
                                offline akan dihapus.
                            </li>
                            <li>
                                Penghapusan data juga berlaku untuk member yang akunnya sudah
                                dinonaktifkan secara tetap karena melanggar kebijakan penggunaan
                                aplikasi yang berlaku.
                            </li>
                        </ol>
                    </ol>
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={() => setModalSyarat(false)} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
            <Modal
                open={modalFaq}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ""}>
                <div className="modal-header" style={{textAlign: "center"}}>
                    <img src={ModalLogo} alt=""/>
                </div>
                <div className="modal-body">
                    <FAQCom/>
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={() => setModalFaq(false)} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
            <Modal
                open={modalSyaratTerm}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ""}>
                <div className="modal-header" style={{textAlign: "center"}}>
                    <img src={ModalLogo} alt=""/>
                </div>
                <div className="modal-body syarat">
                    <h1>Kebijakan Penggunaan Aplikasi Database oleh Member</h1>
                    <p>
                        Untuk menjaga agar database dan aplikasi web linklsm.id dapat
                        berjalan sesuai fungsinya, diharapkan para member mematuhi kebijakan
                        penggunaan platform ini. Beberapa kebijakan yang perlu dipahami dan
                        dipatuhi oleh para calon member yang akan mengisi database ini
                        antara lain :
                    </p>
                    <ol type="1">
                        <li>
                            Individu yang akan membuat dan mengelola akun sudah disetujui oleh
                            organisasinya, dibuktikan dengan surat tugas resmi dari
                            organisasi.
                        </li>
                        <li>
                            Akun yang sudah mendaftar akan ditunggu untuk menyelesaikan tahap
                            pengisian form database. Jika tidak diselesaikan, akan ada
                            konfirmasi dari admin melalui email dan telepon apakah akun akan
                            dilanjutkan atau tidak. Secara berkala dalam satu bulan, setiap
                            minggu admin akan menghubungi kontak dari akun untuk menanyakan
                            progress pendaftaran. Selama tidak ada gugatan dari pihak lain,
                            akun akan tetap dapat digunakan member meskipun tidak ada respon.
                        </li>
                        <li>
                            Jika terjadi laporan atau gugatan dari suatu organisasi bahwa
                            mereka tidak bisa mendaftar karena username mereka telah dipakai,
                            akan dilakukan konfirmasi kepada dua belah pihak. Pihak yang dapat
                            menunjukkan surat resmi dari organisasi yang akan diberikan hak
                            untuk membuat akun organisasi yang dimaksud.
                        </li>
                        <li>
                            Pemegang akun harus mengisi dan mengupload semua kebutuhan
                            informasi dan dokumen verifikasi yang diminta dalam rangka mengisi
                            database ini.
                        </li>
                        <li>
                            Setiap akun saat sudah diverifikasi akan bisa mengakses serta
                            mengedit laman profil pengguna dan membuat feed. Pengguna harus
                            bijak dalam memberikan informasi di dalam profil dan feed sehingga
                            tidak melanggar UU ITE yang berkaitan dengan penyebaran informasi
                            palsu, ujaran kebencian, dan hal-hal berbau SARA.
                        </li>
                        <li>
                            Member yang melanggar kebijakan penggunaan aplikasi akan ditindak
                            secara bertahap, dari pemberian peringatan, penonaktifan sementara
                            tampilan profil, hingga penonaktifan akun secara tetap.
                        </li>
                    </ol>
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={() => setModalSyaratTerm(false)} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
            <AppBar position="static" className={classes.appBar}>
                <div className="header container">
                    <div className={width < 552 ? classes.toolbarMobile : classes.toolbar}>
                        <div className="logo-container">
                            <NavLink to="/">
                                <img src={Logo} alt="linklsm"/>
                            </NavLink>
                        </div>
                        <NavLink to="/">
                            <h3 style={{fontSize: 25, color: "white"}}>LinkLSM.id</h3>
                        </NavLink>
                        <div className={open ? "link-container open" : "link-container"}>
                            <NavLink
                                activeClassName="active-link"
                                className="link"
                                exact
                                isActive={onPaths(["/"])}
                                to="/">
                                <span>
                                  <img src={Beranda} alt="beranda"/>
                                </span>
                                <p>Beranda</p>
                            </NavLink>
                            <NavLink
                                activeClassName="active-link"
                                className="link"
                                exact
                                isActive={onPaths(["/database-lembaga"])}
                                to="/database-lembaga">
                                <span>
                                  <img src={Database} alt="database"/>
                                </span>
                                <p>Database</p>
                            </NavLink>
                            <NavLink
                                activeClassName="active-link"
                                className="link"
                                exact
                                isActive={onPaths(["/tentang-linklsm"])}
                                to="/tentang-linklsm">
                                <span>
                                  <img src={LinkLSM} alt="linklsm"/>
                                </span>
                                <p>Linklsm.id</p>
                            </NavLink>
                            <NavLink
                                activeClassName="active-link"
                                className="link"
                                exact
                                isActive={onPaths(["/swakelola-tipe-iii"])}
                                to="/swakelola-tipe-iii">
                                <span>
                                  <img src={Swatipe} alt="swatipe"/>
                                </span>
                                <p>Swa tipe III</p>
                            </NavLink>
                            <NavLink
                                activeClassName="active-link"
                                className="link"
                                exact
                                isActive={onPaths(["/berita"])}
                                to="/berita">
                                <span>
                                  <img src={Berita} alt="berita"/>
                                </span>
                                <p>Berita</p>
                            </NavLink>
                            <NavLink
                                activeClassName="active-link"
                                className="link"
                                exact
                                isActive={onPaths(["/kontak-kami"])}
                                to="/kontak-kami">
                                <span>
                                  <img src={Kontak} alt="kontak"/>
                                </span>
                                <p>Kontak</p>
                            </NavLink>
                        </div>
                        <div className="grid-mobile">
                            <ClickAwayListener onClickAway={onClickAway}>
                                <div className="user-container">
                                    <div className="user-info">
                                        {dataProfile === undefined ? (
                                            <img
                                                src={ProfilePic}
                                                alt=""
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    "-webkit-border-radius": "60px",
                                                    "-webkit-background-clip": "padding-box",
                                                    "-moz-border-radius": "60px",
                                                    "-moz-background-clip": "padding",
                                                    "border-radius": "60px",
                                                    "background-clip": "padding-box",
                                                    "background-color": "#FFFFFF",
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={
                                                    dataProfile.logoProfile === null
                                                        ? ProfilePic
                                                        : `${configData.BASE_URL}/api/misc/${dataProfile.logoProfile}`
                                                }
                                                alt=""
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    "-webkit-border-radius": "60px",
                                                    "-webkit-background-clip": "padding-box",
                                                    "-moz-border-radius": "60px",
                                                    "-moz-background-clip": "padding",
                                                    "border-radius": "60px",
                                                    "background-clip": "padding-box",
                                                    "background-color": "#FFFFFF",
                                                }}
                                            />
                                        )}
                                        {dataProfile !== undefined ? (
                                            <NavLink to="#" onClick={checkAccount} className="link">
                                                {dataProfile.namaPengguna}
                                            </NavLink>
                                        ) : (
                                            <NavLink to="/login" className="link">
                                                Login
                                            </NavLink>
                                        )}
                                        <ArrowDropDownIcon onClick={openUserSetting}/>
                                    </div>
                                    <div
                                        className={
                                            openUser ? "user-settings openUser" : "user-settings"
                                        }>
                                        <ul className="settings-menu">
                                            {dataProfile !== undefined ? (
                                                <li>
                                                    <NavLink to="#" onClick={editProfil}>
                                                        Edit Profil
                                                    </NavLink>
                                                </li>
                                            ) : ''}
                                            <li>
                                                <NavLink
                                                    to="#"
                                                    onClick={() => {
                                                        setModalSyarat(true);
                                                    }}>
                                                    Privacy
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="#"
                                                    onClick={() => {
                                                        setModalFaq(true);
                                                    }}>
                                                    FAQ
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="#"
                                                    onClick={() => {
                                                        setModalSyaratTerm(true);
                                                    }}>
                                                    Terms & Conditions
                                                </NavLink>
                                            </li>
                                        </ul>
                                        {dataProfile === undefined ? (
                                            ""
                                        ) : (
                                            <NavLink
                                                onClick={() => logout()}
                                                to="/login"
                                                className="logout-button"
                                            >
                                                <h3>Logout</h3>
                                            </NavLink>
                                        )}
                                    </div>
                                </div>
                            </ClickAwayListener>
                            <div
                                className={open ? "nav-icon1 open" : "nav-icon1"}
                                onClick={toggle}>
                                <span/>
                                <span/>
                                <span/>
                                <span/>
                            </div>
                        </div>
                    </div>
                </div>
            </AppBar>
        </>
    );
}

export default withRouter(Navbar);
