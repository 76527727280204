import React, { useEffect, useState } from "react";
import "../Styles/DaftarLembaga_Styles.css";
import "../Styles/SumberDaya_Styles.css";
import {Link} from "react-router-dom";
import {GetApp} from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMediaQuery } from "react-responsive";

function DaftarLembaga_Com(props) {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [expend, setExpend] = useState(isMobile)

  useEffect(() => {
    setExpend(isMobile)
  }, [isMobile])

  const onExpend = () => {
    setExpend(!expend)
  }

  return (
    <div className="widget daftar-lembaga">
      <div className="sd-title">
        <h3>Sumber Daya</h3>
        {!isMobile ? '' : (
          <ExpandMoreIcon onClick={() => onExpend()} />
        )}
      </div>
      {expend ? '' : 
        (
        <>
        <div className="sd-list">
        <div className="sd-info">
          <div className="sd-details">
            <p>
              Referensi terkait dengan LinkLSM.id dan Swakelola Tipe III bisa Anda akses pada tautan berikut:
            </p>
          </div>
        </div>
      </div>

      <div className="list-lembaga">
        {props.data.map((data, index) => {
          return (
            <>
              <div className="lembaga-detail">
                <div className="nama-lembaga">
                  <h4>{data.label}</h4>
                </div>
                <span>
                  <Link
                    onClick={() => {
                      window.open(data.linkDownload, "_blank");
                    }}
                  >
                    <GetApp style={{ height: 20, width: 20 }} />
                  </Link>
                </span>
              </div>
            </>
          );
        })}
      </div>
      </>
      )}
    </div>
  );
}

export default DaftarLembaga_Com;
