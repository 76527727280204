import React, { useEffect, useState } from "react";
import ProfilePic from "../Assets/Logo/mainlogo.png";
import {Link, NavLink} from "react-router-dom";
import "../Styles/UserData_Styles.css";
import Cookies from 'universal-cookie';
import configData from "../config.json";

function UserData_Com(props) {
  const [dataActive, setDataActive] = useState();
  const [dataToken, setToken] = useState();

  useEffect(() => {
    getDataLsmActive()
    let token = new Cookies().get('Profile')
    setToken(token)
  }, []);

  const getDataLsmActive = async () => {
    try {
      await fetch(`${configData.BASE_URL}/api/lsm/count/lsm_active`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setDataActive(responseJson)
        });
    } catch (error) {}
  };
  return (
    <div className="user-data">
      <div className="user-profile">
        <div className="user-details">
          <div className="user-pics">
            <img src={ProfilePic} alt="" />
          </div>
        </div>
        <div className="user-specs">
          <h3>LinkLSM.id</h3>
          <span>Platform Jejaring LSM Indonesia</span>
          <br />
          <span>Kompeten, Akuntabel, Mandiri</span>
        </div>
      </div>

      <ul className="user-status">
        <li>
          <h4>Data Organisasi Saat ini</h4>
          <span>{dataActive}</span>
        </li>
        <li>
          <NavLink
            activeClassName="active-link"
            className="link"
            exact
            to="/database-lembaga"
          >
            Lihat Organisasi
          </NavLink>
        </li>
      </ul>
      {dataToken ? (
        ""
      ) : (
        <div className="sign-link">
          <p>Bergabung di LinkLSM.id</p>
          <h3>
            <Link to="/login">DAFTAR DISINI</Link>
          </h3>
        </div>
      )}
    </div>
  );
}

export default UserData_Com;
