import { Grid, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DaftarLembaga from "../Components/DaftarLembaga_Com";
import TableViewDatabase from "../Components/SwakelolaView/TableViewDatabase";
import Footer from "../Components/Footer_Com";
import Navbar from "../Components/NavigationBar_Com";
import CardBerita from "../Components/CardBerita_Com";
import UserData from "../Components/UserData_Com";
import { useHistory } from "react-router-dom";
import configData from "../config.json";
import ReactPaginate from "react-paginate";
import { GetDataLSM, GetDataSwakelola, getSumberDaya } from "../Logic/APIHandler_Logic";
import SwatipeTable from './SwatipeTable'

function Swakelola_Page(props) {
  const [dataBerita, setDataBerita] = useState([]);
  const [dataBeritaPin, setDataBeritaPin] = useState([]);
  const [dataSumberDaya, setDataSumberDaya] = useState([]);
  const [pages, setPages] = useState(props.history.location.state?.pages || 0);
  const [pageCount, setpageCount] = useState(0);
  const [pageCountartikel, setpageCountartikel] = useState(0);
  const history = useHistory();
  const [dataLembaga, setDataLembaga] = useState({
    data: "",
    loading: true,
  });
  let limit = 5;

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      await GetDataSwakelola({ size: 20, page: 0, "parentId.specified":false,"sort":["nama","asc"]}).then(async (response) => {
        const data = await response.json();
        const count = response.headers.get("x-total-count");
        setpageCount(Math.ceil(count / 20));
        setDataLembaga({
          data: data,
          loading: false,
          pages: 0,
        });
      });
    })();
  }, []);

  useEffect(() => {
    getSumberDaya.then((result) => setDataSumberDaya(result));
    getDataBerita();
    getDataBeritaPin();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataBerita = async () => {
    try {
      let res = await fetch(
        `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=FrontPage&isPin.equals=false&page=${pages}&size=${limit}&sort=createdDate,desc`
      );
      const data = await res.json();
      const total = res.headers.get("x-total-count");
      setpageCountartikel(Math.ceil(total / limit));
      setDataBerita(data);
      setPages(0);
    } catch (error) {}
  };

  const fetchData = async (currentPage) => {
    const res = await fetch(
      `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=FrontPage&isPin.equals=false&page=${currentPage}&size=${limit}&sort=createdDate,desc`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    return data;
  };

  const getDataBeritaPin = async () => {
    try {
      await fetch(
        `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=FrontPage&isPin.equals=true`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          setDataBeritaPin(responseJson);
        });
    } catch (error) {}
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1 - 1;
    const commentsFormServer = await fetchData(currentPage);
    setDataBerita(commentsFormServer);
  };

  const handlePageClickTable = async (data) => {
    let currentPage = data.selected + 1 - 1;
    let commentsFormServer = await GetDataLSM(
      {
        page: currentPage,
        size: 20,
      },
      true
    );
    setDataLembaga({
      data: commentsFormServer,
      loading: false,
      pages: currentPage,
    });
  };

  return (
    <>
      <Navbar />
      <div className="main-linklsm">
        <div className="container">
          <div className="linklsm-inner">
            <Grid container spacing={5}>
              <Grid item xs={12} md={3}>
                <Grid item xs={12}>
                  <UserData />
                  <DaftarLembaga data={dataSumberDaya} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <div className="banner-database swakelola">
                      <p>Database Pekerjaan Swakelola Tipe III</p>
                      <Button
                        style={{
                          backgroundColor: "#53d690",
                          color: "white",
                        }}
                        onClick={() =>
                          window.open(
                            "https://linklsm.id/berita/tentang-database-linklsm.id",
                            "_blank"
                          )
                        }
                      >
                        <div className="banner-database-button">
                          Info selengkapnya
                        </div>
                      </Button>
                    </div>
                    {/* <TableViewDatabase /> */}
                  </Grid>
                  <Grid item xs={12}>
                   {history.location.state?.child?<SwatipeTable/>:<TableViewDatabase dataLembaga={dataLembaga} />}
              
                    <div style={{ marginBottom: 50, float: "right" }}>
                      <Grid
                        container03
                        justifyContent="center"
                        alignItems="center"
                      >
                        <ReactPaginate
                          previousLabel={<p>&laquo;</p>}
                          nextLabel={<p>&raquo;</p>}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClickTable}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </Grid>
                    </div>
                  </Grid>
                  {dataBeritaPin.map((data, index) => {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <CardBerita
                          headline={data.headLine}
                          slug={data.slug}
                          desc={data.deskripsi}
                          image={data.image}
                          artikelTag={data.artikelTag}
                          id={data.id}
                          pages={pages}
                        />
                      </Grid>
                    );
                  })}
                  {dataBerita.map((data, index) => {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <CardBerita
                          headline={data.headLine}
                          slug={data.slug}
                          desc={data.deskripsi}
                          image={data.image}
                          artikelTag={data.artikelTag}
                          id={data.id}
                          pages={pages}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <div style={{ marginTop: 50 }}>
                  <Grid container justifyContent="center" alignItems="center">
                    <ReactPaginate
                      previousLabel={<p>&laquo;</p>}
                      nextLabel={<p>&raquo;</p>}
                      breakLabel={"..."}
                      pageCount={pageCountartikel}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <footer style={{ background: "white", width: "100%", float: "left" }}>
        <div className="footer-sec">
          <div className="container">
            <Footer />
          </div>
        </div>
      </footer>
    </>
  );
}

export default Swakelola_Page;
