import React from "react";
import { Link, useHistory } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import "../Styles/CardBerita_Styles.css";
import configData from '../config.json'

function CardBerita_Com(props) {
  const history = useHistory()
  const readMoreEllipsis = (
    <span>
      ...{" "}
      <Link
        style={{ color: "cadetblue" }}
        to={{
          pathname: `/berita/${props.slug}`,
          state:
            props.pages === 0
              ? { berita: { id: props.id, title: props.headline, slug: props.slug } }
              : {
                  pages: props.pages,
                  berita: { id: props.id, title: props.headline, slug: props.slug },
                },
        }}
        onClick={() => {
          history.replace({
            state:
              props.pages === 0
                ? { berita: { id: props.id, title: props.headline, slug: props.slug } }
                : {
                    pages: props.pages,
                    berita: { id: props.id, title: props.headline, slug: props.slug },
                  },
          });
        }}
      >
        selengkapnya
      </Link>
    </span>
  );
  return (
    <div className="card-berita">
      <div className="product-feed">
        <div className="post-section">
          <div className="post-bar">
            <div className="post-topbar-berita">
              <div className="cover-sec" style={{ minHeight: 174 }}>
                {props.image ? (
                  <img
                    src={`${configData.BASE_URL}/api/misc/${props.image}`}
                    alt=""
                    style={{ maxWidth: 373, maxHeight: 174 }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="post-desc">
              <h3 style={{ minHeight: 50 }}>
                <Link
                  style={{ color: "black" }}
                  to={{
                    pathname: `/berita/${props.slug}`,
                    state:
                      props.pages === 0
                        ? { berita: { id: props.id, title: props.headline, slug: props.slug } }
                        : {
                            pages: props.pages,
                            berita: { id: props.id, title: props.headline, slug: props.slug },
                          },
                  }}
                  onClick={() => {
                    history.replace({
                      state:
                        props.pages === 0
                          ? { berita: { id: props.id, title: props.headline, slug: props.slug } }
                          : {
                              pages: props.pages,
                              berita: { id: props.id, title: props.headline, slug: props.slug },
                            },
                    });
                  }}
                >
                  {props.headline}
                </Link>
              </h3>
              <ul className="post-tags">
                <div style={{ marginBottom: 10 }}>
                  <li>
                    {props.artikelTag.map((e) => (
                      <Link to="#" style={{ marginRight: 5, marginBottom: 5 }}>
                        {e.tag}
                      </Link>
                    ))}
                  </li>
                </div>
              </ul>
              <div className="job_descp">
                <TruncateMarkup lines={4} ellipsis={readMoreEllipsis}>
                  <div>{props.desc}</div>
                </TruncateMarkup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardBerita_Com;
