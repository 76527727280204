import React, {useEffect, useState} from "react";
import {Button, CircularProgress, FormGroup, Grid, makeStyles, Tooltip,} from "@material-ui/core";
import {useForm} from "react-hook-form";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {useStateMachine} from "little-state-machine";
import updateAction from "./UpdateAction_Form";

import configData from "../../config.json"

const Step4 = ({nextStep, prevStep, className, dataLSM}) => {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: {errors},
    } = useForm();
    const {actions, state} = useStateMachine({updateAction});
    const [checkbox1, setCheckBox1] = useState([]);
    const [checkbox2, setCheckBox2] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [wait, setWait] = useState(false);
    const [infoBidangKerja, setInfoBidangKerja] = useState([]);
    const [infoBidangKegiatan, setInfoBidangKegiatan] = useState([]);

    const service = {
        put: async (ex) => {
            return new Promise(((resolve, reject) => {
                try {
                    fetch(`${configData.BASE_URL}/user/lsm`, {
                        method: "PUT",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(ex),
                    })
                        .then((response) => response.json())
                        .then((responseJson) => {
                            ex.lsm.bidangKerja = responseJson.lsm.bidangKerja
                            ex.lsm.kompetensi = responseJson.lsm.kompetensi
                            localStorage.setItem('data', JSON.stringify(ex))
                            resolve()
                        });
                } catch (error) {
                    reject(error)
                }
            }))
        },
        fetchDataBidangKerja: async () => {
            return new Promise((resolve, reject) => {
                try {
                    fetch(`${configData.BASE_URL}/api/bidang_kerja?size=100`, {
                        method: "GET",
                        credentials: "include",
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            setCheckBox1(response);
                            resolve()
                        })
                } catch (error) {
                    reject(error)
                }
            })
        },
        fetchDataKompetensi: async () => {
            return new Promise((resolve, reject) => {
                try {
                    fetch(`${configData.BASE_URL}/api/kompetensi/all`, {
                        method: "GET",
                        credentials: "include",
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            setCheckBox2(response);
                            resolve()
                        })
                } catch (error) {
                    reject(error)
                }
            })
        }
    }

    const onSubmit = async (data) => {
        setWait(true);
        let ex = JSON.parse(localStorage.getItem('data'))
        ex.lsm.kompetensi = infoBidangKegiatan.length > 0 ? infoBidangKegiatan : ex.lsm.kompetensi
        ex.lsm.bidangKerja = infoBidangKerja.length > 0 ? infoBidangKerja : ex.lsm.bidangKerja

        service.put(ex)
            .then(() => {
                return new Promise((resolve => {
                    actions.updateAction({});
                    window.scrollTo(0, 0);
                    nextStep();
                    setWait(false);
                    resolve()
                }))
            })
    };

    const Previous = (e) => {
        e.preventDefault();
        prevStep();
    };

    const useStyles = makeStyles((theme) => ({
        customWidth: {
            maxWidth: 300,
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        let a = service.fetchDataBidangKerja();
        let b = service.fetchDataKompetensi();
        let ex = JSON.parse(localStorage.getItem('data'))
        setInfoBidangKerja(ex.lsm.bidangKerja);
        setInfoBidangKegiatan(ex.lsm.kompetensi);
        Promise.all([a, b]).finally(() => setIsLoading(false))
    }, [dataLSM]);

    const dataCheck2 = (data) => {
        let checked = false;
        dataLSM.lsm.bidangKerja.map((element) => {
            if (data.id === element.id) {
                checked = true;
            }
        });

        return checked;
    };

    const dataCheck = (data) => {
        let checked = false;
        dataLSM.lsm.kompetensi.map((element) => {
            if (data.id === element.id) {
                checked = true;
            }
        });
        return checked;
    };

    return isLoading ? (
      ""
    ) : (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={className + " kompetensi"}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={8}>
            <h3>
              Sektor Kegiatan
              <Tooltip
                placement="right-start"
                title="Pilih 3 isu yang digeluti organisasi (maksimal 3 isu)"
                classes={{ tooltip: classes.customWidth }}
              >
                <HelpOutlineIcon />
              </Tooltip>
            </h3>
            <FormGroup>
              <Grid container>
                {checkbox2.map((data) => (
                  <Grid item xs={6}>
                    <label>
                      <input
                        name="testing"
                        type="checkbox"
                        value={infoBidangKegiatan}
                        defaultChecked={dataCheck(data)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            if (infoBidangKegiatan.length < 3) {
                              setInfoBidangKegiatan([
                                ...infoBidangKegiatan,
                                {
                                  id: data.id,
                                  kompetensi: data.kompetensi,
                                },
                              ]);
                            } else {
                              e.target.checked = false;
                              alert(
                                "Pilih 3 isu yang digeluti organisasi (maksimal 3 isu)"
                              );
                            }
                          } else {
                            setInfoBidangKegiatan(
                              infoBidangKegiatan.filter(
                                (people) => people.id !== data.id
                              )
                            );
                          }
                        }}
                      />
                      {data.kompetensi}
                    </label>
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={8}>
            <h3>
              Jenis Kegiatan
              <Tooltip
                placement="right-start"
                title="Pilih 3 bidang kegiatan yang menjadi keunggulan organisasi dalam 3 tahun terakhir, sesuai
              dengan bidang kegiatan yang ada dalam dokumen legal organisasi (maksimal 3 bidang)."
                classes={{ tooltip: classes.customWidth }}
              >
                <HelpOutlineIcon />
              </Tooltip>
            </h3>
            <FormGroup>
              <Grid container className="checkbox-bidangKegiatan">
                {checkbox1.map((data) => (
                  <Grid item xs={12}>
                    <label>
                      <input
                        name="testing"
                        type="checkbox"
                        value={infoBidangKerja}
                        defaultChecked={dataCheck2(data)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            if (infoBidangKerja.length < 3) {
                              setInfoBidangKerja([
                                ...infoBidangKerja,
                                {
                                  id: data.id,
                                  bidangKerja: data.bidangKerja,
                                },
                              ]);
                            } else {
                              e.target.checked = false;
                              alert(
                                "Pilih 3 bidang kegiatan yang menjadi keunggulan organisasi (maksimal 3 bidang)."
                              );
                            }
                          } else {
                            setInfoBidangKerja(
                              infoBidangKerja.filter(
                                (people) => people.id !== data.id
                              )
                            );
                          }
                        }}
                      />{" "}
                      {data.bidangKerja}
                    </label>
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Grid>
          <Grid item={true} xs={12} className="btn-login">
            <Button
              onClick={Previous}
              variant="contained"
              color="primary"
              disabled={wait}
            >
              Prev
            </Button>
            <Button variant="contained" type="submit" disabled={wait}>
              Next
              {wait ? (
                <CircularProgress size={10} style={{ marginLeft: 10 }} />
              ) : (
                ""
              )}
            </Button>{" "}
          </Grid>
        </Grid>
      </form>
    );
};

export default Step4;
