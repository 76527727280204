import React from 'react';
import "../Styles/PostTop_Styles.css";

function PostTop_Com(props) {
    return (
        <div className="post-topbar">
            <div className="cover-post">
                <img src={props.cover} alt=""/>
            </div>
        </div>
    );
}

export default PostTop_Com;