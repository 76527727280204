import React, {useEffect, useState} from "react";
import Clock from "../Assets/Logo/clock.png";
import ProfilePic from "../Assets/Logo/mainlogo.png";
import "../Styles/PostBar_Styles.css";

import configData from "../config.json";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Modal from "react-responsive-modal";
import {CircularProgress} from "@material-ui/core";
import Moment from "react-moment";
import 'moment/locale/id';

function Post_Feed(props) {
    const [openMore, setOpenMore] = useState([]);
    const [like, setLike] = useState(false);
    const [feeds, setFeed] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalLoading, setLoadingModal] = useState(false);

    useEffect(() => {
        service.getFeeds(props.profile.data.isGuest).then((response) => {
            let openSetting = response.map((feed) => {
                return {
                    id: feed.id,
                    isOpen: false
                }
            })
            setOpenMore(openSetting)
            setFeed(response);
            setIsLoading(false);
        })
    }, [props.profile.data.openParam]);

    const deletePosting = (id) => {
        setLoadingModal(true)
        let list = openMore.map((list) => {
            if (list.id === id) list.isOpen = false
            return list
        })
        setOpenMore(list)
        service.deleteFeeds(id)
            .then(() => {
                return new Promise((resolve => {
                    setFeed((list) => list.filter((el) => el.id !== id))
                    setOpenMore((list) => list.filter((el) => el.id !== id))
                    setLoadingModal(false)
                    resolve()
                }))
            })
    }

    const service = {
        getFeeds: async (isGuest) => {
            return new Promise(((resolve, reject) => {
                try {
                    fetch(
                        `${configData.BASE_URL}${isGuest ? `/api` : '/user'}/feed?sort=lastModifiedDate,desc${isGuest ? `&userId.equals=${props.profile.data.profile.id}` : ''}`,
                        {
                            method: "GET",
                            credentials: "include"
                        }
                    )
                        .then((response) => response.json())
                        .then((responseJson) => {
                            resolve(responseJson)
                        });
                } catch (error) {
                    reject(error)
                }
            }))
        },
        deleteFeeds: async (id) => {
            return new Promise(((resolve, reject) => {
                try {
                    fetch(
                        `${configData.BASE_URL}/user/feed/${id}`,
                        {
                            method: "DELETE",
                            credentials: "include"
                        }
                    )
                        .then((response) => {
                            resolve(response)
                        });
                } catch (error) {
                    reject(error)
                }
            }))
        }
    }

    // Handle click open more
    const handleClickOpenMore = (id) => {
        let list = openMore.map((list) => {
            if (list.id === id) list.isOpen = !list.isOpen
            else list.isOpen = false
            return list
        })
        setOpenMore(list)
    };

    // Handle like post
    const handleLike = () => {
        setLike(!like);
    };

    return isLoading ? (
        ""
    ) : (
        <>
            <Modal
                styles={{opacity: 0}}
                center
                open={modalLoading}
                closeOnOverlayClick={false}
                showCloseIcon={false}
                classNames={{modalContainer: "loading-modal"}}
                onClose={() => ''}>
                <CircularProgress/>
            </Modal>
            {feeds.map((feed) => (
                <div className="post-bar">
                    <div className="post-header">
                        <div className="user-desc">
                            {props.profile.data.profile.lsm !== undefined && props.profile.data.profile.lsm.logoProfile !== null ? (
                                <img
                                    src={`${configData.BASE_URL}/api/misc/${props.profile.data.profile.lsm.logoProfile}`}
                                    alt=""
                                    className="foto"/>
                            ) : <img src={ProfilePic} alt="" className="foto"/>}
                            <div className="user-name">
                                <h3>{props.profile.data.profile.lsm.namaOrganisasi}</h3>
                                <span>
                                    <img src={Clock} alt=""/>
                                    <Moment fromNow locale="id">{feed.createdDate}</Moment>
                                </span>
                            </div>
                        </div>
                        {props.profile.data.isGuest ? '' :
                            <div className="user-more">
                                <MoreVertIcon onClick={() => handleClickOpenMore(feed.id)}/>
                                <ul className={openMore.find((idx) => idx.id === feed.id).isOpen ? "menu-more active" : "menu-more"}
                                    key={feed.id}>
                                    <li onClick={() => deletePosting(feed.id)}>
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="post-content">
                        <div>
                            <div>
                            <p className="post__description" dangerouslySetInnerHTML={{ __html: feed.feed}}  />
                            
                                {/* <p>
                                
                                    {feed.feed}
                                </p> */}
                            </div>

                        </div>
                        {feed.gallery !== null ? (
                            <img src={`${configData.BASE_URL}/api/misc/${feed.gallery.image}`} alt=""
                                 className="foto-test"
                                 style={{width: "100%", height: "100%"}}/>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ))}
        </>
    )
}

export default Post_Feed;
