import React, {useState} from "react";
import {Link} from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import Clock from "../Assets/Logo/clock.png";
import ProfilePic from "../Assets/Logo/mainlogo.png";
import adv from "../Assets/Logo/adv.png";
import "../Styles/PostBar_Styles.css";
import 'moment/locale/id';

import configData from "../config.json";
import Moment from "react-moment";

function Post_Com(props) {
    console.log(props)
    const [truncate, setTruncate] = useState(true);

    const toggleTruncate = () => {
        setTruncate(!truncate);
    };

    const readMoreEllipsis = (linkTo) => (
        <span>
      ...{" "}
            <Link
                to={linkTo}
                className="expand-button">
        Selengkapnya
      </Link>
    </span>
    );

    return <div className="post-bar">
        <div className="post-header">
            <div className="user-desc">
                <img src={ProfilePic} alt="" className="foto"/>
                <div className="user-name">
                    <h3>{props.author}</h3>
                    <span>
                        <img src={Clock} alt=""/>
                        <Moment fromNow locale="id">{props.lastModifiedDate}</Moment>
                    </span>
                </div>
            </div>
        </div>
        <div className="post-content">
            {props?.image ? (
                <img src={`${configData.BASE_URL}/api/misc/${props.image}`} alt="" className="foto-test"/>
            ) : (
                <img src={adv} alt="" className="foto-test"/>
            )}

            <div style={{marginLeft: 110, marginTop: -100}}>
                <Link
                    to={{
                        pathname: `/berita/${props.slug}`,
                        state:
                            props.pages === 0
                                ? {berita: {id: props.id, title: props.headLine, slug: props.slug}}
                                : {
                                    pages: props.pages,
                                    berita: {id: props.id, title: props.headLine, slug: props.slug},
                                },
                    }}
                    style={{color: "black", fontWeight: "bold"}}>{props.headLine}</Link>
                {truncate ? (
                    <TruncateMarkup lines={10}
                                    ellipsis={readMoreEllipsis(`/berita/${props.slug}`)}>
                        <div style={{marginTop: 10}}>
                            <p>{props.deskripsi}</p>
                        </div>
                    </TruncateMarkup>
                ) : (
                    <div style={{marginTop: 80}}>
                        <p>
                            {props.deskripsi}
                            <span onClick={toggleTruncate} className="less-button">
                              {" Tampilkan lebih sedikit"}
                            </span>
                        </p>
                    </div>
                )}
            </div>
            <ul className="post-tags">
                <div style={{marginTop: 20}}>
                    <li>
                        {props.artikelTag.map((e) => (
                            <Link to="#" style={{marginRight: 5}}>{e.tag}</Link>
                        ))}
                    </li>
                </div>
            </ul>
        </div>
    </div>
}

export default Post_Com;
