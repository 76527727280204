export default function objectToQuery(o) {
  let result = "?";

  if (Object.keys(o).length < 1) return "";

  const keys = Object.keys(o);
  const len = keys.length;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    const value = o[key];
    if (value !== null && typeof value !== "undefined" && value !== "") {
      let temp = `${key}=`;

      if (Array.isArray(value)) {
        if (value.length > 0) temp += value.join(",");
      } else temp += value;

      if (!temp.endsWith("=")) {
        result += temp;
        if (i < len || i !== len - 1) result += "&";
      }
    }
  }

  return result.slice(0, result.length - 1);
}
