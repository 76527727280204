import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Swakelola from "../Assets/Logo/swabg.png"
import "../Styles/About_Styles.css"
import ModalLogo from "../Assets/Logo/wd-logo.png";
import FAQCom from "./FAQ_Com";
import {Button} from "@material-ui/core";
import {Modal} from "react-responsive-modal";

function About_Com(props) {
    const [modalFaq, setModalFaq] = useState(false);

    return (
        <>
            <Modal
                open={modalFaq}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ''}>
                <div className="modal-header" style={{textAlign: "center"}}>
                    <img src={ModalLogo} alt=""/>
                </div>
                <div className="modal-body">
                    <FAQCom />
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={() => setModalFaq(false)} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
            <div className="widget about-card">
                <img src={Swakelola} alt=""/>
                <div className="sign-link">
                    <Link to="/swakelola-tipe-iii">
                        Pelajari Disini
                    </Link>
                    <br/>
                    <Link to="#" onClick={() => setModalFaq(true)}>
                        FAQ
                    </Link>
                </div>
            </div>
        </>
    );
}

export default About_Com;