import { Link, useHistory } from "react-router-dom";
import "../../Styles/Database_Styles.css";
import { useState } from "react"; 
import NumberFormat from "react-number-format";


export default function TableViewSwakelola(props) {
  const history = useHistory();
   const [entri, setEntri] = useState(10);
     const [tahun, setTahun] = useState(2021);

   const handleChange = (event) => {
     setEntri(event.target.value);
   };

     const handleChangeTahun = (event) => {
       setTahun(event.target.value);
     };

  return (
    <>
      {props.dataLembaga.loading ? (
        ""
      ) : (
        <div>
          <div
            style={{
              marginBottom: 5,
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div>Tampilkan: </div>
              <select value={entri} onChange={handleChange}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
            </div>
            <div>
              <div>Tahun: </div>
              <select value={tahun} onChange={handleChangeTahun}>
                <option value={2021}>2021</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
              </select>
            </div>
            <div>
              <div>Cari: </div>
              <input />
            </div>
          </div>
          <table className="tg" style={{ marginBottom: 20 }}>
            <thead>
              <tr>
                <th rowspan="2" style={{textAlign: "left"}}>NO</th>
                <th rowspan="2" style={{textAlign: "left"}}>K/L/PD</th>
                <th colspan="2">Pengadaan Swakelola Tipe III</th>
              </tr>
              <tr>
                <th>Paket</th>
                <th>Pagu</th>
              </tr>
            </thead>
            <tbody>
              {props.dataLembaga.data.map((data, index) => {
                // let pathName = data.id;

                // if (profile !== null) {
                //   if (profile.id === data.id) {
                //     if (!!data.namaPanggilan) {
                //       pathName = `/${data.namaPanggilan}`;
                //     }
                //   } else {
                //     if (!!data.namaPanggilan) {
                //       pathName = `/${data.namaPanggilan}`;
                //     }
                //   }
                // } else {
                //   if (!!data.namaPanggilan) {
                //     pathName = `/${data.namaPanggilan}`;
                //   }
                // }
                return (
                  <tr>
                    <td>{index + 1 + props.dataLembaga.pages * 30}</td>
                    <td>
                      {data.hasChild === true ? (
                        <Link
                          className="link-namalembaga"
                          to={{
                            pathname: `/swakelola-tipe-iii/${data.nama}`,
                            state: { id: data.id, child: true },
                          }}
                          onClick={() => {
                            localStorage.setItem(
                              "swatipeid",
                              JSON.stringify({
                                id: data.id,
                              })
                            );
                            history.replace({
                              state: { child: true },
                            });
                          }}
                        >
                          {data.nama}
                        </Link>
                      ) : (
                        <>{data.nama}</>
                      )}
                    </td>
                    <td className="td-paket">{data.kegiatan}</td>
                    <td className="td-paket">
                      {data.pagu === null ? 0 : <NumberFormat value={data.pagu} displayType={'text'} thousandSeparator={true} />}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
