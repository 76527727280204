import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Filter from "../Components/Filter_Com";
import Footer from "../Components/Footer_Com";
import Navbar from "../Components/NavigationBar_Com";
import UserData from "../Components/UserData_Com";
import { GetDataLSM } from "../Logic/APIHandler_Logic";
import "../Styles/Database_Styles.css";
import "../Styles/Pagination_Styles.css";
import "../Styles/SearchBox_Styles.css";
import GridView from "../Components/DatabaseView/GridViewDatabase";
import TableViewDatabase, {
  TableViewContextProvider,
} from "../Components/DatabaseView/TableViewDatabase";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ViewListIcon from "@material-ui/icons/ViewListOutlined";
import ViewModuleIcon from "@material-ui/icons/ViewModuleOutlined";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import { useMediaQuery } from "react-responsive";

let limit = 8;
function Database_Pages(props) {
  const history = useHistory();
  const [dataLembaga, setDataLembaga] = useState({
    data: "",
    loading: true,
  });
  const [isFirst, setIsFirst] = useState({
    first: true,
  });
  const [filter, setFilter] = useState({
    sort: [],
    "lsmNamaOrganisasi.contains": "",
    "lsmBidangKerja.equals": "",
    "lsmKota.equals": "",
    "lsmBadanHukum.equals": "",
    "lsmTenagaAhliPengalamanMinimal.greaterOrEqualThan": "",
    "lsmTenagaAhliPengalamanMaksimal.lessOrEqualThan": "",
    "lsmKompetensi.in": [],
  });
  const [pageCount, setPageCount] = useState(0);
  const [view, setView] = useState(localStorage.getItem("view") ?? "module");
  const [dataProfile, setProfile] = useState();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const paramFromChild = {
    setParameter: (param) => {
      setDataLembaga({
        data: "",
        loading: true,
      });

      const queryObj = {
        "lsmNamaOrganisasi.contains": param.namaLembaga,
        "lsmBidangKerja.equals": param.kompetensi,
        "lsmKota.equals": param.wilayah,
        "lsmBadanHukum.equals": param.yayasan,
        size: view === "module" ? limit : 20,
        sort: [],
      };
      if (param.pengalaman !== "") {
        let lebihBesar = param.pengalaman.split(">");
        if (lebihBesar.length > 1) {
          queryObj["lsmTenagaAhliPengalamanMinimal.greaterOrEqualThan"] =
            lebihBesar[0].substring(0, 2).trim();
        }
        let strip = param.pengalaman.split("-");
        if (strip.length > 0) {
          queryObj["lsmTenagaAhliPengalamanMinimal.greaterOrEqualThan"] =
            strip[0].substring(0, 2).trim();
          queryObj["lsmTenagaAhliPengalamanMaksimal.lessOrEqualThan"] = strip[1]
            .substring(0, 3)
            .trim()
            .trim();
        }
      }

      queryObj["lsmKompetensi.in"] = [];
      param.bidangKerja
        .filter((o) => o.checked)
        .forEach((o) => {
          queryObj["lsmKompetensi.in"].push(o.value);
        });

      GetDataLSM(queryObj).then(async (response) => {
        const data = await response.json();
        const count = response.headers.get("x-total-count");
        setPageCount(Math.ceil(count / (view === "module" ? limit : 20)));
        setDataLembaga({
          data: data,
          loading: false,
          pages,
        });
        setFilter(queryObj);
      });
    },
  };

  const pages = dataLembaga.pages ?? history.location.state?.pages ?? 0;

  useEffect(() => {
    let token = new Cookies().get("Profile")
    if (token !== undefined) {
      if (token !== "null" && token !== "undefined") {
        let decode = jwt_decode(token);
        setProfile(decode.BEARER);
      }
    }
    (async () => {
      window.scrollTo(0, 0);
      if (props.location.state == null || props.location.state?.profiles !== undefined) {
        await GetDataLSM({page: pages, size: view === "module" ? 8 : 20}).then(
            async (response) => {
              const data = await response.json();
              const count = response.headers.get("x-total-count");
              setPageCount(Math.ceil(count / (view === "module" ? limit : 20)));
              setDataLembaga({
                data: data,
                loading: false,
                pages,
              });
            }
        );
      }
    })();
  }, [props.location.state, pages, view]);

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1 - 1;
    if (!isFirst.first) {
      let commentsFormServer = await GetDataLSM(
          {
            ...filter,
            page: currentPage,
            size: view === "module" ? limit : 20,
            "lsmKompetensi.equals": dataLembaga.kompetensi,
          },
          true
      );
      setDataLembaga({
        data: commentsFormServer,
        loading: false,
        pages: currentPage,
      });
    } else {
      setIsFirst({
        first: false
      })
    }
  };

  const handlePageChange = async (event, nextView) => {
    setView(nextView);
    setDataLembaga({
      data: [],
      loading: true,
      pages: 0,
    });
    localStorage.setItem("view", nextView);
    await GetDataLSM({
      size: nextView === "module" ? 8 : 20,
      "lsmKompetensi.equals": dataLembaga.kompetensi,
      ...filter,
    }).then(async (response) => {
      const data = await response.json();
      const count = response.headers.get("x-total-count");
      setPageCount(Math.ceil(count / (nextView === "module" ? limit : 20)));
      setDataLembaga({
        data: data,
        loading: false,
        pages: 0,
      });
    });
  };

  const setSort = async (key, direction = true) => {
    const d = direction ? "asc" : "desc";
    setFilter((v) => {
      return {
        ...v,
        sort: [key, d],
      };
    });
    await GetDataLSM({
      size: view === "module" ? 8 : 20,
      "lsmKompetensi.equals": dataLembaga.kompetensi,
      ...filter,
      sort: [key, d],
    }).then(async (response) => {
      const data = await response.json();
      const count = response.headers.get("x-total-count");
      setPageCount(Math.ceil(count / (view === "module" ? limit : 20)));
      setDataLembaga({
        data: data,
        loading: false,
        pages: 0,
      });
    });
  };

  return (
    <>
      <Navbar />
      <div className="main-database">
        <div className="container">
          <div className="database-inner">
            <Grid container spacing={5}>
              <Grid item xs={12} md={3}>
                <UserData />
                <Filter
                  pushData={history}
                  parameter={paramFromChild}
                  database={true}
                  isMobile={isMobile}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <div className="banner-database">
                  <p>
                    Database LinkLSM.id berisi daftar organisasi yang memenuhi
                    syarat pelaksana <h3>Swakelola Tipe III</h3>
                  </p>
                  <Button
                    style={{
                      backgroundColor: "#53d690",
                      color: "white",
                    }}
                    onClick={() =>
                      window.open(
                        "https://linklsm.id/berita/tentang-database-linklsm.id",
                        "_blank"
                      )
                    }
                  >
                    <div className="banner-database-button">
                      Info selengkapnya
                    </div>
                  </Button>
                </div>
                <ToggleButtonGroup
                  orientation="horizontal"
                  value={view}
                  exclusive
                  onChange={handlePageChange}
                  style={{ marginBottom: 20, float: "right" }}
                >
                  <ToggleButton value="module" aria-label="module">
                    <ViewModuleIcon />
                  </ToggleButton>
                  <ToggleButton value="list" aria-label="list">
                    <ViewListIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Grid container spacing={2}>
                  {view === "module" ? (
                    <GridView
                      dataProfile={dataProfile}
                      dataLembaga={dataLembaga}
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                    />
                  ) : (
                    <TableViewContextProvider
                      value={{
                        key: filter.sort[0],
                        sort: filter.sort[1],
                        setSort,
                      }}
                    >
                      <TableViewDatabase
                          dataLembaga={dataLembaga}
                          dataProfile={dataProfile}
                      />
                    </TableViewContextProvider>
                    // <TableViewDatabase dataLembaga={dataLembaga} />
                  )}
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                  <Paginate
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    pages={pages}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <footer style={{ background: "white", width: "100%", float: "left" }}>
        <div className="footer-sec">
          <div className="container">
            <Footer />
          </div>
        </div>
      </footer>
    </>
  );
}

export default Database_Pages;

function Paginate({ pageCount, handlePageClick, pages }) {
  return (
    <ReactPaginate
      previousLabel={<p>&laquo;</p>}
      nextLabel={<p>&raquo;</p>}
      breakLabel={"..."}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={handlePageClick}
      containerClassName={"pagination justify-content-center"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      activeClassName={"active"}
      initialPage={0}
      forcePage={pages}
    />
  );
}
