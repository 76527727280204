import {useLocation} from "react-router-dom";
import {useEffect} from "react";

import ReactGA from 'react-ga';

function GoogleAnalytics() {
    const location = useLocation()

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location])

    return null;
}

export default GoogleAnalytics