import React from "react";
import LinkLSM from "../../Assets/Logo/wd-logo.png";
import { Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router";

const Result = (props) => {
  const history = useHistory();
  return (
    <div className="sukses-page">
      <div className="sukses-page-inner">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column">
          <div className="modal-header">
            <img src={LinkLSM} alt="" />
          </div>
          <div className="modal-body" style={{textAlign: "center"}}>
            <h2>Terima Kasih.</h2>{" "}
            <h2>Mohon menunggu verifikasi dari kami melalui email, mohon cek email spam jika email kami tidak muncul di pesan utama</h2>{" "}
          </div>
          <div className="modal-footer btn-login">
            <Button onClick={() => history.push("/")} variant="contained">
              OK
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default Result;
