import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    createStyles,
    Grid,
    InputAdornment,
    makeStyles,
    TextField,
} from "@material-ui/core";
import {Modal} from "react-responsive-modal";
import {Controller, useForm} from "react-hook-form";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import {DropzoneArea} from "material-ui-dropzone";
import LinkLSM from "../../Assets/Logo/wd-logo.png";
import {useHistory} from "react-router";

import configData from "../../config.json"

const Step5 = ({nextStep, prevStep, className, dataLSM}) => {
    const history = useHistory();
    const [syarat, setSyarat] = useState(true);
    const {
        handleSubmit,
        control,
        formState: {errors},
        setValue,
    } = useForm();
    const [wait, setWait] = useState(false);
    const [modalSyarat, setModalSyarat] = useState(false);

    const handleChangeSyarat = (e) => {
        setSyarat(!syarat);
    };

    const service = {
        uploadDanaKelola: async (ex, files) => {
            return new Promise(((resolve, reject) => {
                if (files && files[0] && files[0].type !== "text/html") {
                    try {
                        const formData = new FormData();
                        formData.append("file", files[0]);
                        fetch(
                            `${configData.BASE_URL}/user/lsm/upload/dana_kelola`,
                            {
                                method: "POST",
                                credentials: "include",
                                body: formData,
                            }
                        )
                            .then((response) => response.json())
                            .then((responseJson) => {
                                ex.lsm.danaKelola = responseJson.lsm.danaKelola
                                localStorage.setItem('data', JSON.stringify(ex))
                                resolve()
                            });
                    } catch (error) {
                        reject(error)
                    }
                } else resolve()
            }))
        },
        put: async (ex) => {
            new Promise(((resolve, reject) => {
                try {
                    fetch(`${configData.BASE_URL}/user/lsm?isSubmit=true`, {
                        method: "PUT",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(ex),
                    })
                        .then((response) => response.json())
                        .then((responseJson) => {
                            ex.lsm.npwp = responseJson.lsm.npwp
                            localStorage.setItem('data', JSON.stringify(ex))
                            resolve()
                        });
                } catch (error) {
                    reject(error)
                }
            }))
        }
    }

    const onSubmit = async (data) => {
        setWait(true);
        let ex = JSON.parse(localStorage.getItem('data'))
        Promise.resolve()
            .then(() => service.uploadDanaKelola(ex, data.danaKelola))
            .then(() => {
                ex.lsm.npwp = data.npwp ? data.npwp : ex.lsm.npwp
                ex.lsm.statusLSM = "Submit"
                return service.put(ex)
            })
            .then(() => {
                return new Promise((resolve => {
                    history.push("/verification");
                    nextStep();
                    setWait(false);
                    resolve()
                }))
            })
    };

    const Previous = (e) => {
        e.preventDefault();
        prevStep();
    };

    const useStyles = makeStyles((theme) =>
        createStyles({
            previewChip: {
                minWidth: 160,
                maxWidth: 210,
            },
        })
    );


    useEffect(() => {
        setValue("npwp", dataLSM.lsm.npwp);
    }, [setValue, dataLSM.lsm.npwp]);

    const classes = useStyles();
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={className}>
            <Modal
                open={modalSyarat}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                onClose={() => ""}
            >
                <div className="modal-header" style={{textAlign: "center"}}>
                    <img src={LinkLSM} alt=""/>
                </div>
                <div className="modal-body syarat">
                    <h1>Kebijakan Penggunaan dan Penyimpanan data Member</h1>
                    <p>
                        Sebagian besar dari dokumen verifikasi yang diminta merupakan
                        dokumen yang cukup sensitif dan tentunya bisa disebar secara
                        terbuka di publik. Kami menyadari benar mengenai hal tersebut,
                        oleh sebab itu LinkLSM.id memiliki kebijakan penggunaan dan
                        penyimpanan data member sebagai berikut :
                    </p>
                    <ol type="A">
                        <li>Akses, Perubahan dan Penghapusan Data oleh Member</li>
                        <ol type="1">
                            <li>
                                Setelah form isian utama diverifikasi, member hanya bisa
                                mengakses kembali form tersebut atas seizin admin, dengan
                                mekanisme pengajuan perubahan data form utama kepada admin
                                lewat form Kontak. Perubahan data form utama perlu
                                diverifikasi ulang oleh tim verifikator.
                            </li>
                            <li>
                                Tiap member hanya bisa mengakses form utama milik akun
                                masing-masing, dan tidak dapat mengakses form utama dari
                                member lainnya.
                            </li>
                            <li>
                                Member dapat mengedit secara bebas kategori informasi yang ada
                                di halaman profil (tentang, profil, SDM, dan portofolio)
                                sesuai dengan kebutuhan member.
                            </li>
                            <li>
                                Member dapat menghapus akun yang mereka miliki di platform
                                linklsm.id dengan prosedur pengajuan kepada admin lewat form
                                Kontak dan setelah melalui konfirmasi oleh tim verifikator ke
                                organisasi terkait.
                            </li>
                            <li>
                                Berdasarkan permohonan penghapusan akun yang telah
                                terkonfirmasi, maka semua data pengguna yang bersangkutan yang
                                disimpan oleh platform database linklsm.id baik secara online
                                maupun offline akan dihapus
                            </li>
                        </ol>
                        <li>Penggunaan Data oleh LinkLSM.id</li>
                        <ol type="1">
                            <li>
                                Semua dokumen verifikasi (.jpeg/.pdf) hanya digunakan oleh
                                linklsm.id dalam proses verifikasi dan untuk pembuktian jika
                                terjadi kasus tertentu yang menuntut LinkLSM
                                mempertanggungjawabkan kebenaran status verifikasi ormas yang
                                sudah muncul di database (dan tentunya atas seizin member yang
                                bersangkutan). LinkLSM.id tidak akan menggunakan dokumen
                                verifikasi selain diluar dua hal tersebut.
                            </li>
                            <li>
                                Dokumen-dokumen tersebut tidak akan muncul dan dapat diakses
                                publik baik di laman profil member, maupun dalam mesin
                                pencarian dan filterisasi.
                            </li>
                            <li>
                                Informasi yang akan dibagikan ke publik sesuai fungsi dari
                                database sebagai marketplace adalah : logo, nama ormas, badan
                                hukum, domisili, kompetensi (semua kategori spesialisasi isu
                                dan bidang kerja), profil yang diisi ormas di laman profil
                                masing-masing (tentang dan profil, SDM, portofofolio), kontak
                                dan sosial media, serta feed yang dibuat oleh masing-masing
                                ormas.
                            </li>
                            <li>
                                Pengguna database jika ingin meminta informasi lebih detail
                                tentang member untuk tujuan procurement akan disarankan
                                menghubungi langsung member terkait.
                            </li>
                            <li>
                                Dalam rangka kerjasama dengan pihak ketiga, dalam hal ini
                                adalah Lembaga Kebijakan Pengadaan Barang/Jasa Pemerintah
                                (LKPP), data member yang dibagikan hanya mengirimkan data dari
                                poin 3. LKPP mempromosikan database linklsm dengan menaruh
                                link akses ke database di laman website LKPP yang relevan
                                dengan Swakelola Tipe III.
                            </li>
                        </ol>
                        <li>Penyimpanan dan Penghapusan Data oleh LinkLSM.id</li>
                        <ol type="1">
                            <li>
                                Semua informasi dan data yang diinput member di saat
                                pendaftaran akan disimpan di cloud LinkLSM.id
                            </li>
                            <li>
                                Secara rutin, LinkLSM.id akan mengunduh semua dokumen
                                verifikasi (.jpeg/.pdf) yang sudah disetujui, dan kemudian
                                menghapus dokumen-dokumen tersebut dari cloud/penyimpanan
                                online. Dokumen verifikasi akan disimpan dalam database
                                offline. Hal tersebut bertujuan untuk menghindari pencurian
                                data jika suatu saat terjadi serangan yang tidak dapat
                                diatasi. Meski demikian tim pengembang database akan tetap
                                menjaga agar database terhindar dari serangan-serangan yang
                                merugikan.
                            </li>
                            <li>
                                Jika member meminta untuk penghapusan akun, maka semua data
                                member yang disimpan oleh Linklsm.id baik secara online dan
                                offline akan dihapus.
                            </li>
                            <li>
                                Penghapusan data juga berlaku untuk member yang akunnya sudah
                                dinonaktifkan secara tetap karena melanggar kebijakan
                                penggunaan aplikasi yang berlaku.
                            </li>
                        </ol>
                        <li>Sanksi Penyalahgunaan Data oleh LinkLSM.id</li>
                        <ol type="1">
                            <li>
                                Sebagai wali data dokumen verifikasi dari member LinkLSM.id,
                                Konsorsium LinkLSM bersedia menerima sanksi sesuai hukum yang
                                berlaku jika menyalahgunakan data dokumen verifikasi member di
                                luar bentuk-bentuk penggunaan yang sudah disampaikan di poin
                                B.
                            </li>
                        </ol>
                    </ol>
                </div>
                <div className="modal-footer btn-login">
                    <Button onClick={() => setModalSyarat(false)} variant="contained">
                        Tutup
                    </Button>
                </div>
            </Modal>
            <Grid container spacing={3} alignItems="center">
                <Grid item={true} xs={12}>
                    <h3>Lampirkan Bukti Laporan Pajak Tahun Terakhir </h3>
                    <Controller
                        control={control}
                        name="danaKelola"
                        render={({field: {onChange}}) => (
                            <DropzoneArea
                                initialFiles={[
                                    dataLSM.lsm.danaKelola ? dataLSM.lsm.danaKelola : null,
                                ]}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                                dropzoneText="Pilih file"
                                onChange={onChange}
                                useChipsForPreview
                                previewGridProps={{
                                    container: {spacing: 1, direction: "row"},
                                }}
                                previewChipProps={{
                                    classes: {root: classes.previewChip},
                                }}
                                previewText="File yang dipilih :"
                            />
                        )}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <Controller
                        name="npwp"
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                className={"input-login"}
                                variant="outlined"
                                placeholder="Masukan No NPWP"
                                type="text"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LocationCityIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <p
                        className={errors.npwp ? "error-msg" : "error-msg exit"}
                        id="hidden">
                        {errors.npwp ? errors.npwp.message : "Mohon masukan NPWP"}
                    </p>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                    <h3>Persetujuan Pengelolaan Data Pengguna</h3>
                    <Grid item={true} xs={12} className="password-control">
                        <div className="remember-me">
                            <Checkbox value={syarat} onChange={handleChangeSyarat}/>
                            <p>
                                Ya, saya telah membaca dan setuju dengan{" "}
                                <span
                                    style={{cursor: "pointer", color: "blue"}}
                                    onClick={() => setModalSyarat(true)}>
                                    ketentuan dan kebijakan
                                  </span>
                                LinkLSM.id.
                            </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} className="btn-login">
                    <Button
                        onClick={Previous}
                        variant="contained"
                        color="primary"
                        disabled={wait}>
                        Prev
                    </Button>
                    <Button variant="contained" type="submit" disabled={wait || syarat}>
                        Submit
                        {wait ? (
                            <CircularProgress size={10} style={{marginLeft: 10}}/>
                        ) : (
                            ""
                        )}
                    </Button>{" "}
                </Grid>
            </Grid>
        </form>
    );
};

export default Step5;
