import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../Styles/Database_Styles.css";
import { GetDataSwakelola } from "../Logic/APIHandler_Logic";
import NumberFormat from 'react-number-format';

export default function TableViewSwakelolaDetail(props) {
  const history = useHistory();
  const [entri, setEntri] = useState(10);
  const [tahun, setTahun] = useState(2021);
  const [dataSwatipe, setDataSwatipe] = useState({ data: [], loading: true });

  const id =
    history.location.state?.id ||
    JSON.parse(localStorage.getItem("swatipeid")).id;

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      await GetDataSwakelola({
        size: 20,
        page: 0,
        "parentId.equals": id,
        sort: ["nama", "asc"],
      }).then(async (response) => {
        const data = await response.json();
        setDataSwatipe({
          data: data,
          loading: false,
          pages: 0,
        });
      });
    })();
  }, [id]);

  const handleChange = (event) => {
    setEntri(event.target.value);
  };

  const handleChangeTahun = (event) => {
    setTahun(event.target.value);
  };

  return (
    <>
      <div>
        <div
          style={{
            marginBottom: 5,
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>Tampilkan: </div>
            <select value={entri} onChange={handleChange}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <div>
            <div>Tahun: </div>
            <select value={tahun} onChange={handleChangeTahun}>
              <option value={2021}>2021</option>
              <option value={2022}>2022</option>
              <option value={2023}>2023</option>
            </select>
          </div>
          <div>
            <div>Cari: </div>
            <input />
          </div>
        </div>
        <table className="tg" style={{ marginBottom: 20 }}>
          <thead>
            <tr>
              <th rowspan="2">NO</th>
              <th rowspan="2">K/L/PD</th>
              <th colspan="2">Pengadaan Swakelola Tipe III</th>
            </tr>
            <tr>
              <th>Paket</th>
              <th>Pagu</th>
            </tr>
          </thead>
          <tbody>
            {dataSwatipe.data.map((data, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    {data.hasChild === true ? (
                      <Link
                        className="link-namalembaga"
                        to={{
                          pathname: `/swakelola-tipe-iii/${data.nama}`,
                          state: { id: data.id, child: true },
                        }}
                        onClick={() => {
                          localStorage.setItem(
                            "swatipeid",
                            JSON.stringify({
                              prevId: history.location.state.id,
                              id: data.id,
                            })
                          );
                          history.replace({
                            state: { child: true },
                          });
                        }}
                      >
                        {data.nama}
                      </Link>
                    ) : (
                      <>{data.nama}</>
                    )}
                  </td>
                  <td className="td-paket">{data.kegiatan}</td>
                  <td className="td-paket">
                    {data.pagu === null ? 0 : <NumberFormat value={data.pagu} displayType={'text'} thousandSeparator={true} />}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
