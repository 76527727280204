import {BrowserRouter as Router, HashRouter, Route, Switch} from "react-router-dom";

import Login from "../src/Pages/Login_Page";
import Beranda from "../src/Pages/Beranda_Page";
import Database from "../src/Pages/Database_Pages";
import LinkLSM from "../src/Pages/LinkLSM_Page";
import Berita from "../src/Pages/Berita_Page";
import Profile from "../src/Pages/Profile_Page";
import Kontak from "../src/Pages/Kontak_Page";
import Register from "./Components/Stepper/Register_Com";
import Sukses from "./Components/Stepper/Sukses_Form";
import Verifikasi from "./Components/Stepper/Result_Form";
import Konfirmasi from "./Pages/Konfirmasi_Page";
import DetailBerita from "./Pages/DetailBerita_Page";
import Swakelola from "./Pages/Swakelola_Page";

/* Global Styles */
import "../src/Styles/Default_Styles.css";
import "../src/Styles/Colour_Styles.css";
import {render} from "react-dom";
import GoogleAnalytics from "./GoogleAnalytics";
import ReactGA from "react-ga";
import configData from "./config.json"

ReactGA.initialize(configData.GOOGLE_ANALYTIC);

function App() {
  return (
    <HashRouter basename="/">
      <Router>
        <Switch>
          <Route exact path="/" component={Beranda} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/database-lembaga" component={Database} />
          <Route exact path="/tentang-linklsm" component={LinkLSM} />
          <Route exact path="/berita" component={Berita} />
          <Route exact path="/swakelola-tipe-iii" component={Swakelola} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/kontak-kami" component={Kontak} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/verification" component={Verifikasi} />
          <Route exact path="/swakelola-tipe-iii/:nama" component={Swakelola} />
          <Route exact path="/berita/:title" component={DetailBerita} />
          <Route exact path="/:id" component={Profile} />
          <Route exact path="/activation/:code" component={Sukses} />
          <Route exact path="/reset/:code" component={Konfirmasi} />
        </Switch>
        <GoogleAnalytics />
      </Router>
    </HashRouter>
  );
}
render(<App />, document.getElementById('root'));

export default App;
