import {Button, Grid} from "@material-ui/core";
import React, {useState} from "react";
import Modal from "react-responsive-modal";
import ImageIcon from "@material-ui/icons/Image";
import gambar from "../Assets/Logo/adv.png";
import "../Styles/Feeds_Styles.css";
import {CloseOutlined} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import {useForm} from "react-hook-form";
import configData from "../config.json";
import PostFeed from "./PostFeed";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Feeds(props) {
  const { handleSubmit } = useForm();
  const [isSubmit, setSubmit] = useState(false);
  const [modal, setModal] = useState(false);
  const [foto, setFoto] = useState(false);
  const [file, setFile] = useState([]);
  const [body, setBody] = useState({});

  const closeModal = () => {
    setModal(false);
    setFoto(false);
  };
  const handleUpload = (e) => {
    setFile(e);
  };

  const service = {
    post: async (body) => {
      return new Promise((resolve, reject) => {
        if (body !== "") {
          try {
            fetch(`${configData.BASE_URL}/user/feed`, {
              method: "POST",
              credentials: "include",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(body),
            })
              .then((response) => response.json())
              .then((response) => resolve(response));
          } catch (error) {
            reject(error);
          }
        } else resolve();
      });
    },
    upload: async (feed, files) => {
      return new Promise((resolve, reject) => {
        if (files.length > 0) {
          try {
            const formData = new FormData();
            formData.append("file", files[0]);
            fetch(
              `${configData.BASE_URL}/user/feed/${feed.id}/upload/photo_feed`,
              {
                method: "POST",
                credentials: "include",
                body: formData,
              }
            )
              .then((response) => response.json())
              .then((responseJson) => {
                resolve(responseJson);
              });
          } catch (error) {
            reject(error);
          }
        } else resolve(feed);
      });
    },
  };

  const postingFeed = async (data) => {
    if (body.trim() === "" && file.length === 0) {
      alert("Harus diisi")
      data.preventDefault();
      return;
    }
    data.preventDefault();
    let submitData = {
      feed: body
    }
    setSubmit(true);
    Promise.resolve()
      .then(() => service.post(submitData))
      .then((feed) => service.upload(feed, file))
      .then((feed) => {
        return new Promise((resolve) => {
          setSubmit(false);
          props.data.openParam = [
            ...props.data.openParam,
            {
              id: feed.id,
              isOpen: false,
            },
          ];
          setFile([]);
          setBody("")
          resolve();
          closeModal();
        });
      });
  };

  return (
    <>
      {props.data.isGuest ? (
        ""
      ) : (
        <>
          <Modal
            classNames={{
              modal: "modal-feeds",
              closeButton: "close-modal-feeds",
            }}
            open={modal}
            center
            showCloseIcon={true}
            onOverlayClick={closeModal}
            onClose={closeModal}
            closeOnOverlayClick={true}
          >
            <form onSubmit={postingFeed}>
              <div className="modal-inner-container">
                <div className="modal-header">
                  {" "}
                  <h2>Buat Postingan</h2>{" "}
                </div>
                <div className="modal-body">
                  <div className="text-about">
                    <ReactQuill
                        value={body}
                        onChange={setBody}
                    />
                  </div>

                  {foto ? (
                    <div className="upload-feeds">
                      <DropzoneArea
                        onChange={handleUpload}
                        filesLimit={1}
                        dropzoneClass="dropzone-feeds"
                        dropzoneText="Tambahkan Foto"
                      />
                      <CloseOutlined onClick={() => setFoto(false)} />
                    </div>
                  ) : (
                    ""
                  )}
                  <Grid
                    container
                    style={{
                      marginTop: 20,
                      border: "2px solid rgb(0,0,0,0.2)",
                      padding: 10,
                      borderRadius: 8,
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h3>Tambahkan ke Postingan Anda</h3>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        className="feeds-button other"
                        onClick={() => {
                          setFoto(true);
                        }}
                      >
                        <ImageIcon />
                        Foto
                      </Button>
                    </Grid>
                  </Grid>
                </div>
                <div className="modal-footer btn-login">
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth>
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
          <Grid container className="feeds-container" spacing={0}>
            <Grid item xs={12}>
              <Grid container className="feeds-post" spacing={0}>
                <Grid item xs={1}>
                  {props.data.profile.lsm !== undefined &&
                  props.data.profile.lsm.logoProfile !== null ? (
                    <img
                      src={`${configData.BASE_URL}/api/misc/${props.data.profile.lsm.logoProfile}`}
                      alt=""
                      className="feeds-img"
                    />
                  ) : (
                    <img src={gambar} alt="" className="feeds-img" />
                  )}
                </Grid>
                <Grid item xs={10} style={{ marginLeft: 20 }}>
                  <Button
                    fullWidth
                    className="feeds-button"
                    onClick={() => setModal(true)}>
                    Apa yang Anda pikirkan ?
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="feeds-other">
              <Grid container spacing={0} justifyContent="space-around">
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    className="feeds-button other"
                    onClick={() => {
                      setModal(true);
                      setFoto(true);
                    }}>
                    <ImageIcon />
                    Foto
                  </Button>
                </Grid>
                <Grid item xs={4} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <div style={{ marginTop: 20 }}>
        <PostFeed key={props} profile={props} />
      </div>
    </>
  );
}

export default Feeds;
