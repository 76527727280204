import React, {useEffect, useState} from "react";
import {
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    Grid,
    makeStyles,
    createStyles,
    CircularProgress,
} from "@material-ui/core";
import {useForm, Controller} from "react-hook-form";
import {useStateMachine} from "little-state-machine";
import updateAction from "./UpdateAction_Form";
import {DropzoneArea} from "material-ui-dropzone";

import configData from "../../config.json";

const Step2 = ({nextStep, prevStep, className, dataLSM}) => {
    const initData = JSON.parse(localStorage.getItem('data'))
    const {handleSubmit, control, setValue} = useForm();
    const [defaultBadanHukum, setDefaultBadanHukum] = useState("Yayasan");
    const [isLoading, setIsLoading] = useState(true);
    const {actions, state} = useStateMachine({updateAction});
    const [wait, setWait] = useState(false);

    const service = {
        uploadPengesahan: async (ex, files) => {
            return new Promise((resolve, reject) => {
                if (files && files[0] && files[0].type !== "text/html") {
                    try {
                        const formData = new FormData();
                        formData.append("file", files[0]);
                        fetch(
                            `${configData.BASE_URL}/user/lsm/upload/sk_penegasan`,
                            {
                                method: "POST",
                                credentials: "include",
                                body: formData,
                            }
                        )
                            .then((response) => response.json())
                            .then((responseJson) => {
                                ex.lsm.skPenegasan = responseJson.lsm.skPenegasan
                                localStorage.setItem('data', JSON.stringify(ex))
                                resolve()
                            });
                    } catch (error) {
                        reject(error)
                    }
                } else resolve()
            })
        },
        uploadAnggaranDasar: async (ex, files) => {
            return new Promise(((resolve, reject) => {
                if (files && files[0] && files[0].type !== "text/html") {
                    try {
                        const formData = new FormData();
                        formData.append("file", files[0]);
                        fetch(
                            `${configData.BASE_URL}/user/lsm/upload/anggaran_dasar`,
                            {
                                method: "POST",
                                credentials: "include",
                                body: formData,
                            }
                        )
                            .then((response) => response.json())
                            .then((responseJson) => {
                                ex.lsm.anggaranDasar = responseJson.lsm.anggaranDasar
                                localStorage.setItem('data', JSON.stringify(ex))
                                resolve()
                            });
                    } catch (error) {
                        reject()
                    }
                } else resolve()
            }))
        },
        uploadAnggaranRumahTangga: async (ex, files) => {
            return new Promise(((resolve, reject) => {
                if (files && files[0] && files[0].type !== "text/html") {
                    try {
                        const formData = new FormData();
                        formData.append("file", files[0]);
                        fetch(
                            `${configData.BASE_URL}/user/lsm/upload/anggaran_rumah_tangga`,
                            {
                                method: "POST",
                                credentials: "include",
                                body: formData,
                            }
                        )
                            .then((response) => response.json())
                            .then((responseJson) => {
                                ex.lsm.anggaranRumahTangga = responseJson.lsm.anggaranRumahTangga
                                localStorage.setItem('data', JSON.stringify(ex))
                                resolve()
                            });
                    } catch (error) {
                        reject(error)
                    }
                } else resolve()
            }))
        },
        put: async (ex) => {
            return new Promise(((resolve, reject) => {
                try {
                    fetch(`${configData.BASE_URL}/user/lsm`, {
                        method: "PUT",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(ex),
                    })
                        .then((response) => response.json())
                        .then((responseJson) => {
                            localStorage.setItem('data', JSON.stringify(ex))
                            resolve()
                        });
                } catch (error) {
                    reject(error)
                }
            }))
        }
    }

    // for continue event listener
    const onSubmit = async (data) => {
        setWait(true);
        let ex = JSON.parse(localStorage.getItem('data'))

        Promise.resolve()
            .then(() => service.uploadPengesahan(ex, data.pengesahan))
            .then(() => service.uploadAnggaranDasar(ex, data.anggaranDasar))
            .then(() => service.uploadAnggaranRumahTangga(ex, data.anggaranRumahTangga))
            .then(() => {
                ex.lsm.badanHukum = data.badanHukum ? data.badanHukum : ex.lsm.badanHukum
                return service.put(ex)
            })
            .then(() => {
                return new Promise((resolve => {
                    actions.updateAction({});
                    window.scrollTo(0, 0);
                    nextStep();
                    setWait(false);
                    resolve()
                }))
            })
    };

    const Previous = (e) => {
        e.preventDefault();
        prevStep();
    };

    const useStyles = makeStyles((theme) =>
        createStyles({
            previewChip: {
                minWidth: 160,
                maxWidth: 210,
            },
        })
    );

    // Get Data Profile
    const setProfile = () => {
        let ex = JSON.parse(localStorage.getItem('data'))
        setValue("badanHukum", ex.lsm.badanHukum);
        setDefaultBadanHukum(ex.lsm.badanHukum);
    };

    useEffect(() => {
        setProfile();
    }, [dataLSM]);

    const classes = useStyles();
    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate className={className}>
            <Grid container spacing={3}>
                <Grid item={true} xs={12}>
                    <div className="label-header">
                        <p>Badan Hukum Organisasi</p>
                    </div>
                    <Controller
                        control={control}
                        defaultValue={defaultBadanHukum}
                        name="badanHukum"
                        render={({field: {onChange, value}}) => (
                            <RadioGroup
                                value={value}
                                onChange={(_, data) => {
                                    onChange(data);
                                    setDefaultBadanHukum(data);
                                }}
                                className="on-off-status"
                            >
                                <FormControlLabel
                                    value="Yayasan"
                                    control={<Radio/>}
                                    label="Yayasan"
                                />
                                <FormControlLabel
                                    value="Perkumpulan"
                                    control={<Radio/>}
                                    label="Perkumpulan"
                                />
                            </RadioGroup>
                        )}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <h3>SK Pengesahan Badan Hukum Organisasi dari Kemenkumham</h3>
                    <Controller
                        control={control}
                        name="pengesahan"
                        render={({field: {onChange}}) => (
                            <DropzoneArea
                                initialFiles={[
                                    dataLSM.lsm.skPenegasan ? dataLSM.lsm.skPenegasan : null,
                                ]}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                                maxFileSize={8000000}
                                dropzoneText="Pilih file (Maksimal 8 MB)"
                                onChange={onChange}
                                useChipsForPreview
                                previewGridProps={{
                                    container: {spacing: 1, direction: "row"},
                                }}
                                previewChipProps={{
                                    classes: {root: classes.previewChip},
                                }}
                                previewText="File yang dipilih :"
                            />
                        )}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <h3>Anggaran Dasar Organisasi</h3>
                    <Controller
                        control={control}
                        name="anggaranDasar"
                        render={({field: {onChange}}) => (
                            <DropzoneArea
                                initialFiles={[
                                    dataLSM.lsm.anggaranDasar ? dataLSM.lsm.anggaranDasar : null,
                                ]}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                                maxFileSize={8000000}
                                dropzoneText="Pilih file (Maksimal 8 MB)"
                                onChange={onChange}
                                useChipsForPreview
                                previewGridProps={{
                                    container: {spacing: 1, direction: "row"},
                                }}
                                previewChipProps={{
                                    classes: {root: classes.previewChip},
                                }}
                                previewText="File yang dipilih :"
                            />
                        )}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <h3>Anggaran Rumah Tangga Organisasi</h3>
                    <Controller
                        control={control}
                        name="anggaranRumahTangga"
                        render={({field: {onChange}}) => (
                            <DropzoneArea
                                initialFiles={[
                                    dataLSM.lsm.anggaranRumahTangga ? dataLSM.lsm.anggaranRumahTangga : null,
                                ]}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                                maxFileSize={8000000}
                                dropzoneText="Pilih file (Maksimal 8 MB)"
                                onChange={onChange}
                                useChipsForPreview
                                previewGridProps={{
                                    container: {spacing: 1, direction: "row"},
                                }}
                                previewChipProps={{
                                    classes: {root: classes.previewChip},
                                }}
                                previewText="File yang dipilih :"
                            />
                        )}
                    />
                </Grid>
                <Grid item={true} xs={12} className="btn-login">
                    <Button
                        onClick={Previous}
                        variant="contained"
                        color="primary"
                        disabled={wait}>
                        Prev
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={wait}>
                        Next
                        {wait ? (
                            <CircularProgress size={10} style={{marginLeft: 10}}/>
                        ) : (
                            ""
                        )}
                    </Button>{" "}
                </Grid>
            </Grid>
        </form>
    );
};

export default Step2;
