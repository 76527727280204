import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress,
    createStyles,
    FormControlLabel,
    Grid,
    InputAdornment,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
} from "@material-ui/core";

import {DropzoneArea} from "material-ui-dropzone";
import {Controller, useForm} from "react-hook-form";
import {useStateMachine} from "little-state-machine";
import updateAction from "./UpdateAction_Form";
import {Autocomplete} from "@material-ui/lab";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PublicIcon from "@material-ui/icons/Public";

import configData from "../../config.json";
import axios from "axios";

const Step1 = ({nextStep, className, dataLSM}) => {
    const {actions} = useStateMachine({updateAction});
    const [wait, setWait] = useState(false);

    const [DataProvinsi, setDataProvinsi] = useState([]);
    const [DataKota, setDataKota] = useState([]);

    const [defaultStatusKantor, setDefaultStatusKantor] = useState("");
    const [defaultKepemilikan, setDefaultKepemilikan] = useState("");
    const [defaultKota, setDefaultKota] = useState("");
    const [defaultProvinsi, setDefaultProvinsi] = useState("");
    const [defaultKotaPusat, setDefaultKotaPusat] = useState("");
    const [defaultProvinsiPusat, setDefaultProvinsiPusat] = useState("");
    const {handleSubmit, control, setValue} = useForm();

    useEffect(() => {
        GetDataProvinsi()
            .then(async (provinceResult) => {
                let indexKota = 0;
                if (dataLSM.lsm.provinsi) {
                    indexKota = provinceResult.findIndex((e) => {
                        return e.nama === dataLSM.lsm.provinsi;
                    });
                    indexKota = indexKota !== -1 ? indexKota : 0;
                }
                const provinsi = provinceResult[indexKota];

                const citiesResult = await GetDataKota(provinsi);

                setDataProvinsi(provinceResult);
                setDataKota(citiesResult);
                return {provinceResult, citiesResult};
            })
            .then((res) => setProfile(res.provinceResult, res.citiesResult));
    }, []);

    const service = {
        uploadBuktiKepemilikan: async (ex, files) => {
            return new Promise((resolve, reject) => {
                if (files && files[0] && files[0].type !== "text/html") {
                    try {
                        const formData = new FormData();
                        formData.append("file", files[0]);
                        fetch(`${configData.BASE_URL}/user/lsm/upload/bukti_kepemilikan`, {
                            method: "POST",
                            credentials: "include",
                            body: formData,
                        })
                            .then((response) => response.json())
                            .then((responseJson) => {
                                localStorage.setItem("data", JSON.stringify(responseJson));
                                resolve(responseJson ?? ex);
                            });
                    } catch (error) {
                        reject(error);
                    }
                } else resolve();
            });
        },
        uploadSuratTugas: async (ex, files) => {
            return new Promise((resolve, reject) => {
                if (files && files[0] && files[0].type !== "text/html") {
                    try {
                        const formData = new FormData();
                        formData.append("file", files[0]);
                        fetch(`${configData.BASE_URL}/user/lsm/upload/surat_tugas`, {
                            method: "POST",
                            credentials: "include",
                            body: formData,
                        })
                            .then((response) => response.json())
                            .then((responseJson) => {
                                localStorage.setItem("data", JSON.stringify(responseJson));
                                resolve(responseJson ?? ex);
                            });
                    } catch (error) {
                        reject(error);
                    }
                } else resolve();
            });
        },
        put: async (ex) => {
            return new Promise((resolve, reject) => {
                try {
                    fetch(`${configData.BASE_URL}/user/lsm`, {
                        method: "PUT",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(ex),
                    })
                        .then((response) => response.json())
                        .then((responseJson) => {
                            localStorage.setItem("data", JSON.stringify(responseJson));
                            resolve();
                        });
                } catch (error) {
                    reject(error);
                }
            });
        },
    };

    // for continue event listener
    const onSubmit = async (data) => {
        setWait(true);
        let ex = JSON.parse(localStorage.getItem("data"));
        Promise.resolve()
            .then(() => service.uploadBuktiKepemilikan(ex, data.buktiKepemilikan))
            .then((responseJson) => service.uploadSuratTugas(responseJson, data.suratTugas))
            .then((responseJson) => {
                let ex = responseJson ?? JSON.parse(localStorage.getItem("data"));
                ex.lsm.kota = data.kota?.nama ? data.kota.nama : data.kota ? data.kota : ex.lsm.kota;
                ex.lsm.alamat = data.alamat ? data.alamat : ex.lsm.alamat;
                ex.lsm.kodePos = data.kodePos ? data.kodePos : ex.lsm.kodePos;
                ex.lsm.statusKepemilikanKantor = data.statusKepemilikanKantor;
                ex.lsm.namaOrganisasi = data.namaOrganisasi
                    ? data.namaOrganisasi
                    : ex.lsm.statusKepemilikanKantor;
                ex.lsm.statusKantor = data.statusKantor
                    ? data.statusKantor
                    : ex.lsm.statusKantor;
                ex.lsm.provinsi = data.provinsi ? data.provinsi.nama : ex.lsm.provinsi;

                if (ex.lsm.statusKantor === "KantorPusat") {
                    ex.lsm.namaOrganisasiPusat = null;
                    ex.lsm.alamatPusat = null;
                    ex.lsm.provinsiPusat = null;
                    ex.lsm.kotaPusat = null;
                    ex.lsm.kodePosPusat = null;
                } else {
                    ex.lsm.namaOrganisasiPusat = data.namaOrganisasiPusat
                        ? data.namaOrganisasiPusat
                        : ex.lsm.namaOrganisasiPusat;
                    ex.lsm.alamatPusat = data.alamatPusat
                        ? data.alamatPusat
                        : ex.lsm.alamatPusat;
                    ex.lsm.provinsiPusat = data.provinsiPusat
                        ? data.provinsiPusat.nama
                        : ex.lsm.provinsiPusat;
                    ex.lsm.kotaPusat = data.kotaPusat
                        ? data.kotaPusat
                        : ex.lsm.kotaPusat;
                    ex.lsm.kodePosPusat = data.kodePosPusat
                        ? data.kodePosPusat
                        : ex.lsm.kodePosPusat;
                }

                ex.lsm.website = data.website ? data.website : ex.lsm.website;
                ex.lsm.facebook = data.facebook ? data.facebook : ex.lsm.facebook;
                ex.lsm.twitter = data.twitter ? data.twitter : ex.lsm.twitter;
                ex.lsm.instagram = data.instagram ? data.instagram : ex.lsm.instagram;
                ex.lsm.linkedin = data.linkedin ? data.linkedin : ex.lsm.linkedin;

                return service.put(ex);
            })
            .then(() => {
                return new Promise((resolve) => {
                    setWait(false);
                    actions.updateAction({});
                    nextStep();
                    window.scrollTo(0, 0);
                    resolve();
                });
            });
    };

    const changeDatakota = async (a) => {
        const res = await GetDataKota(a);
        setDataKota(res);
    };

    const setProfile = (provinces = DataProvinsi, cities = DataKota) => {
        setValue("namaOrganisasi", dataLSM.lsm.namaOrganisasi);
        setValue("alamat", dataLSM.lsm.alamat);
        if (dataLSM.lsm.provinsi !== null) {
            const indexProvinsi = provinces.findIndex(function (e) {
                return e.nama === dataLSM.lsm.provinsi;
            });
            const defaultProvinsi = provinces[indexProvinsi];
            setValue("provinsi", defaultProvinsi);
            setDefaultProvinsi(provinces[indexProvinsi]);
        }
        if (dataLSM.lsm.kota !== null) {
            const indexKota = cities.findIndex(function (e) {
                return e.nama === dataLSM.lsm.kota;
            });

            if (indexKota !== -1) {
                const defaultKota = cities[indexKota];
                setValue("kota", defaultKota);
                setDefaultKota(cities[indexKota]);
            }
        }
        setValue("kodePos", dataLSM.lsm.kodePos);
        setValue("website", dataLSM.lsm.website);
        setValue("facebook", dataLSM.lsm.facebook);
        setValue("twitter", dataLSM.lsm.twitter);
        setValue("instagram", dataLSM.lsm.instagram);
        setValue("linkedin", dataLSM.lsm.linkedin);

        if (dataLSM.lsm.statusKantor !== null) {
            setValue("statusKantor", dataLSM.lsm.statusKantor);
            setDefaultStatusKantor(dataLSM.lsm.statusKantor);
        } else {
            setValue("statusKantor", "KantorPusat");
            setDefaultStatusKantor("KantorPusat");
        }

        if (dataLSM.lsm.statusKepemilikanKantor !== null) {
            setValue("statusKepemilikanKantor", dataLSM.lsm.statusKepemilikanKantor);
            setDefaultKepemilikan(dataLSM.lsm.statusKepemilikanKantor);
        } else {
            setValue("statusKepemilikanKantor", "Milik");
            setDefaultKepemilikan("Milik");
        }

        setValue("namaOrganisasiPusat", dataLSM.lsm.namaOrganisasiPusat);
        setValue("alamatPusat", dataLSM.lsm.alamatPusat);
        if (dataLSM.lsm.provinsiPusat !== null) {
            const indexProvinsi = provinces.findIndex(function (e) {
                return e === dataLSM.lsm.provinsiPusat;
            });
            const defaultProvinsi = provinces[indexProvinsi];
            setValue("provinsiPusat", defaultProvinsi);
            setDefaultProvinsiPusat(provinces[indexProvinsi]);
        }
        if (dataLSM.lsm.kotaPusat !== null) {
            const indexKota = cities.findIndex(function (e) {
                return e.nama === dataLSM.lsm.kotaPusat;
            });
            const defaultKota = cities[indexKota];
            setValue("kotaPusat", defaultKota);
            setDefaultKotaPusat(cities[indexKota]);
        }
        setValue("kodePosPusat", dataLSM.lsm.kodePosPusat);
        localStorage.setItem("data", JSON.stringify(dataLSM));
    };

    const useStyles = makeStyles((theme) =>
        createStyles({
            previewChip: {
                minWidth: 160,
                maxWidth: 210,
            },
        })
    );

    const classes = useStyles();

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate className={className}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="namaOrganisasi"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="outlined"
                                    placeholder="Nama Organisasi"
                                    type="text"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationCityIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="alamat"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="outlined"
                                    type="text"
                                    placeholder="Alamat Lengkap Lembaga"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PublicIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="provinsi"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <Autocomplete
                                    options={DataProvinsi}
                                    getOptionLabel={(option) => option.nama}
                                    value={defaultProvinsi}
                                    disableClearable
                                    className="autocomplete-input"
                                    onChange={(_, newValue) => {
                                        onChange(newValue);
                                        setDefaultProvinsi(newValue);
                                        changeDatakota(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Provinsi"
                                            variant="outlined"
                                            className={"input-login"}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        style={{paddingLeft: 15}}
                                                    >
                                                        <PublicIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="kota"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <Autocomplete
                                    options={DataKota}
                                    getOptionLabel={(option) => option.nama}
                                    disableClearable
                                    value={defaultKota}
                                    onChange={(_, newValue) => {
                                        onChange(newValue.nama);
                                        setDefaultKota(newValue);
                                    }}
                                    className="autocomplete-input"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Kota/Kab"
                                            variant="outlined"
                                            className={"input-login"}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        style={{paddingLeft: 15}}
                                                    >
                                                        <PublicIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="kodePos"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="outlined"
                                    placeholder="Kode Pos"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PublicIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="website"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="filled"
                                    label="Link Alamat Website Organisasi"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <p>https://</p>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="facebook"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    type="text"
                                    variant="filled"
                                    label="Link Alamat Facebook Organisasi"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <p>https://</p>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="twitter"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="filled"
                                    label="Link Alamat Twitter Organisasi"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <p>https://</p>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="instagram"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="filled"
                                    label="Link Alamat Instagram Organisasi"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <p>https://</p>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Controller
                            name="linkedin"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    className={"input-login"}
                                    variant="filled"
                                    label="Link Alamat Linkedin Organisasi"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <p>https://</p>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid item={true} xs={12}>
                        <div className="label-header">
                            <p>Status Kantor</p>
                        </div>
                        <Controller
                            rules={{required: true}}
                            control={control}
                            defaultValue={defaultStatusKantor}
                            name="statusKantor"
                            render={({field: {onChange, value}}) => (
                                <RadioGroup
                                    value={value}
                                    onChange={(_, data) => {
                                        onChange(data);
                                        setDefaultStatusKantor(data);
                                    }}
                                    className="on-off-status"
                                >
                                    <FormControlLabel
                                        value="KantorPusat"
                                        control={<Radio/>}
                                        label="Kantor Pusat"
                                    />
                                    <FormControlLabel
                                        value="KantorCabang"
                                        control={<Radio/>}
                                        label="Kantor Cabang"
                                    />
                                </RadioGroup>
                            )}
                        />
                    </Grid>
                    {defaultStatusKantor === "KantorCabang" ? (
                        <>
                            <Grid
                                item={true}
                                xs={12}
                                className={
                                    defaultStatusKantor === "KantorCabang"
                                        ? "active-form"
                                        : "hide"
                                }
                            >
                                <Controller
                                    name="namaOrganisasiPusat"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            className={"input-login"}
                                            variant="outlined"
                                            placeholder="Nama Organisasi Pusat"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LocationCityIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                className={
                                    defaultStatusKantor === "KantorCabang"
                                        ? "active-form"
                                        : "hide"
                                }
                            >
                                <Controller
                                    name="alamatPusat"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            className={"input-login"}
                                            variant="outlined"
                                            placeholder="Alamat Lengkap Lembaga"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PublicIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                className={
                                    defaultStatusKantor === "KantorCabang"
                                        ? "active-form"
                                        : "hide"
                                }
                            >
                                <Controller
                                    name="provinsiPusat"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <Autocomplete
                                            options={DataProvinsi}
                                            getOptionLabel={(option) => option.nama}
                                            className="autocomplete-input"
                                            value={defaultProvinsiPusat}
                                            openOnFocus
                                            onChange={(_, newValue) => {
                                                onChange(newValue);
                                                setDefaultProvinsiPusat(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Provinsi"
                                                    variant="outlined"
                                                    className={"input-login"}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                style={{paddingLeft: 15}}
                                                            >
                                                                <PublicIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                className={
                                    defaultStatusKantor === "KantorCabang"
                                        ? "active-form"
                                        : "hide"
                                }
                            >
                                <Controller
                                    name="kotaPusat"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <Autocomplete
                                            options={DataKota}
                                            getOptionLabel={(option) => option.nama}
                                            className="autocomplete-input"
                                            value={defaultKotaPusat}
                                            openOnFocus
                                            onChange={(_, newValue) => {
                                                onChange(newValue.nama);
                                                setDefaultKotaPusat(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Kota"
                                                    variant="outlined"
                                                    className={"input-login"}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                style={{paddingLeft: 15}}
                                                            >
                                                                <PublicIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                className={
                                    defaultStatusKantor === "KantorCabang"
                                        ? "active-form"
                                        : "hide"
                                }
                            >
                                <Controller
                                    name="kodePosPusat"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            className={"input-login"}
                                            variant="outlined"
                                            placeholder="Kode Pos"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PublicIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                    <Grid item={true} xs={12}>
                        <div className="label-header">
                            <p>Status Kepemilikan Kantor</p>
                        </div>
                        <Controller
                            rules={{required: true}}
                            control={control}
                            defaultValue={defaultKepemilikan}
                            name="statusKepemilikanKantor"
                            render={({field: {onChange, value}}) => (
                                <RadioGroup
                                    value={value}
                                    onChange={(_, data) => {
                                        onChange(data);
                                        setDefaultKepemilikan(data);
                                    }}
                                    className="on-off-status"
                                >
                                    <FormControlLabel
                                        value="Milik"
                                        control={<Radio/>}
                                        label="Milik"
                                    />
                                    <FormControlLabel
                                        value="Sewa"
                                        control={<Radio/>}
                                        label="Sewa"
                                    />
                                </RadioGroup>
                            )}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <h3>Bukti Status Kepemilikan Kantor</h3>
                        <Controller
                            control={control}
                            name="buktiKepemilikan"
                            render={({field: {onChange}}) => (
                                <DropzoneArea
                                    initialFiles={[
                                        dataLSM.lsm.buktiKepemilikan
                                            ? dataLSM.lsm.buktiKepemilikan
                                            : null,
                                    ]}
                                    showPreviews={true}
                                    showPreviewsInDropzone={false}
                                    filesLimit={1}
                                    maxFileSize={8000000}
                                    dropzoneText="Pilih file (Maksimal 8 MB)"
                                    onChange={onChange}
                                    useChipsForPreview
                                    previewGridProps={{
                                        container: {spacing: 1, direction: "row"},
                                    }}
                                    previewChipProps={{
                                        classes: {root: classes.previewChip},
                                    }}
                                    previewText="File yang dipilih :"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                    <h3>Surat Tugas Pengisi Database dari Organisasi</h3>
                    <Controller
                        control={control}
                        name="suratTugas"
                        render={({field: {onChange}}) => (
                            <DropzoneArea
                                initialFiles={[
                                    dataLSM.lsm.suratTugas ? dataLSM.lsm.suratTugas : null,
                                ]}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                                maxFileSize={8000000}
                                dropzoneText="Pilih file (Maksimal 8 MB)"
                                onChange={onChange}
                                useChipsForPreview
                                previewGridProps={{
                                    container: {spacing: 1, direction: "row"},
                                }}
                                previewChipProps={{
                                    classes: {root: classes.previewChip},
                                }}
                                previewText="File yang dipilih :"
                            />
                        )}
                    />
                </Grid>
                <Grid item={true} xs={12} className="btn-login">
                    <Button
                        variant="contained"
                        color="primary"
                        style={{visibility: "hidden"}}
                    >
                        Prev
                    </Button>
                    <Button variant="contained" type="submit" disabled={wait}>
                        Next
                        {wait ? (
                            <CircularProgress size={10} style={{marginLeft: 10}}/>
                        ) : (
                            ""
                        )}
                    </Button>{" "}
                </Grid>
            </Grid>
        </form>
    );
};

export default Step1;

async function GetDataProvinsi() {
    const res = await axios.get(
        "https://dev.farizdotid.com/api/daerahindonesia/provinsi"
    );
    return res.data.provinsi;
}

async function GetDataKota(provinsi) {
    const res = await axios.get(
        `https://dev.farizdotid.com/api/daerahindonesia/kota?id_provinsi=${provinsi.id}`
    );
    return res.data.kota_kabupaten;
}
