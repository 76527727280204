import React, {useState} from "react";
import {TextField, Grid, InputAdornment, Button, TextareaAutosize, CircularProgress} from "@material-ui/core";
import MailIcon from '@material-ui/icons/Mail';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import "../Styles/ForumKontak_Styles.css";
import "../Styles/Login_Styles.css";
import configData from "../config.json";
import {
    EReCaptchaV2Size,
    EReCaptchaV2Theme,
    ReCaptchaProvider,
    ReCaptchaV2
} from 'react-recaptcha-x';
import {useMediaQuery} from 'react-responsive';


function ForumKontak_Com(props) {

    const [isSubmit, setIsSubmit] = useState(false);
    const [captcha, setCaptcha] = useState();
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});


    const postKontak = (data) => {
        data.preventDefault()
        setIsSubmit(true)
        let body = {
            nama: data.target.name.value,
            email: data.target.email.value,
            lembaga: data.target.lembaga.value,
            pesan: data.target.pesan.value,
            captcha: captcha
        }

        sendKontak(body)
            .then((response) => {
                if (response.ok) {
                    alert('Terima kasih telah menghubungi kami Kami akan segera menjawab pesan Anda')
                    data.target.name.value = ''
                    data.target.email.value = ''
                    data.target.lembaga.value = ''
                    data.target.pesan.value = ''
                } else alert('Gagal mengirimkan pesan')

                setIsSubmit(false)
            })

    }

    const sendKontak = async (body) => {
        return new Promise(((resolve, reject) => {
            try {
                fetch(
                    `${configData.BASE_URL}/api/kontak`,
                    {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify(body)
                    }
                )
                    .then((response) => resolve(response))
            } catch (error) {
                reject(error)
            }
        }))
    };

    const v2Callback = (token) => {
        if (typeof token === 'string') {
            setCaptcha(token)
        } else if (typeof token === 'boolean' && !token) {
            console.log('token has expired, user must check the checkbox again');
        } else if (token instanceof Error) {
            console.log('error. please check your network connection');
        }
    };

    return (
        <div className="forum-kontak">
            <div className="forum-kontak-inner">
                <div className="box-forum">
                    <div className="login-form">
                        <h3>Hubungi Kami</h3>
                        <form onSubmit={postKontak}>
                            <Grid container>
                                <Grid item={true} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        name="name"
                                        placeholder="Nama"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonOutlineIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        className="input-login"
                                    />
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        name="lembaga"
                                        placeholder="Organisasi/Instansi"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonOutlineIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        className="input-login"
                                    />
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <TextField
                                        className="input-login"
                                        type="email"
                                        variant="outlined"
                                        name="email"
                                        placeholder="Email"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MailIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <TextareaAutosize
                                        style={{
                                            marginTop: 10,
                                            borderColor: 'lightgray',
                                            backgroundColor: "transparent",
                                            outline: 'none',
                                            minWidth: 150
                                        }}
                                        multiline={true}
                                        rows={5}
                                        variant="outlined"
                                        name="pesan"
                                        placeholder="Pesan"
                                        className="text-area"
                                    />
                                </Grid>
                                <Grid item={true} xs={12} className="btn-login">
                                    {isMobile ? (
                                            <ReCaptchaProvider
                                                siteKeyV2={configData.RECAPTCHA}
                                                langCode="en"
                                                hideV3Badge={false}>
                                                <ReCaptchaV2
                                                    callback={v2Callback}
                                                    theme={EReCaptchaV2Theme.Light}
                                                    size={EReCaptchaV2Size.Compact}
                                                    id="my-id"
                                                    data-test-id="my-test-id"
                                                    tabindex={0}
                                                />
                                            </ReCaptchaProvider>
                                        ) :
                                        <ReCaptchaProvider
                                            siteKeyV2={configData.RECAPTCHA}
                                            langCode="en"
                                            hideV3Badge={false}
                                        >
                                            <ReCaptchaV2
                                                callback={v2Callback}
                                                theme={EReCaptchaV2Theme.Light}
                                                size={EReCaptchaV2Size.Normal}
                                                id="my-id"
                                                data-test-id="my-test-id"
                                                tabindex={0}
                                            />
                                        </ReCaptchaProvider>}
                                </Grid>
                                <Grid item={true} xs={12} className="btn-login">
                                    <Button
                                        disabled={isSubmit}
                                        variant="contained"
                                        type="submit">
                                        Kirim Pesan {isSubmit ?
                                        <CircularProgress size={10} style={{marginLeft: 10}}/> : ''}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForumKontak_Com;
