import {Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import "../Styles/Beranda_Styles.css";
import Navbar from "../Components/NavigationBar_Com";
import UserData from "../Components/UserData_Com";
import Filter from "../Components/Filter_Com";
import Post from "../Components/Post_Com";
import About from "../Components/About_Com";
import DaftarLembaga from "../Components/DaftarLembaga_Com";
import Footer from "../Components/Footer_Com";
import CustomSlider from "../Components/Slider_Com";
import {useWindowDimensions} from "../Logic/Dimension_Logic";

import configData from "../config.json";
import { getSumberDaya } from "../Logic/APIHandler_Logic";
import { useMediaQuery } from "react-responsive";

function Beranda_Page(props) {
    const {width} = useWindowDimensions();
    const [dataSumberDaya, setDataSumberDaya] = useState([]);
    const [poster, setDataPoster] = useState({
        dataSide: [],
        loading: true
    });
    const [dataBerita, setDataBerita] = useState({
        data: [],
        loading: true
    });
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    useEffect(() => {
        getSumberDaya.then(result => setDataSumberDaya(result));
        getPoster()
            .then(response => {
                setDataPoster({
                    dataSide: response.filter(o => o.location === "Side"),
                    loading: false
                })
            })
        getDataBerita()
            .then(response => {
                setDataBerita(response);
            })
    }, [isMobile]);

    const getDataBerita = async () => {
        return new Promise(((resolve, reject) => {
            try {
                fetch(
                    `${configData.BASE_URL}/api/artikel?tipeArtikel.equals=LinkLSM&size=2&sort=createdDate,desc`,
                    {
                        method: "GET",
                    }
                )
                    .then((response) => response.json())
                    .then((responseJson) => {
                        resolve(responseJson)
                    });
            } catch (error) {
                reject(error)
            }
        }));
    };

    const getPoster = async () => {
        return new Promise(((resolve, reject) => {
            try {
                fetch(
                    `${configData.BASE_URL}/api/poster?laman.equals=Beranda`,
                    {
                        method: "GET",
                    }
                )
                    .then((response) => response.json())
                    .then((responseJson) => {
                        resolve(responseJson)
                    });
            } catch (error) {
                reject(error)
            }
        }))
    };

    return (
        <>
            <Navbar/>
            <div className="main-beranda">
                <div className="container">
                    <div className="beranda-inner">
                        <Grid container spacing={5}>
                            <Grid item xs={12} lg={3} md={4}>
                                <Grid item xs={12}>
                                    <UserData/>
                                    <Filter 
                                    database={false}
                                    isMobile={isMobile}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6} md={8}>
                                <Grid item xs={12}>
                                    <CustomSlider
                                        dots={true}
                                        draggable={false}
                                        className="slider-banner"
                                        autoplay={true}
                                        autoplaySpeed={13000}
                                        pauseOnHover={false}
                                        pauseOnFocus={false}
                                        slidesToScroll={1}
                                        slidesToShow={1}
                                        arrows={false}
                                        type="banner"
                                    />
                                    <div className="post-section">
                                        {dataBerita.loading ? '' :
                                            dataBerita.map((data, index) => {
                                                return (
                                                    <Post
                                                        author={data.author}
                                                        headLine={data.headLine}
                                                        slug={data.slug}
                                                        image={data.image}
                                                        deskripsi={data.deskripsi}
                                                        artikelTag={data.artikelTag}
                                                        lastModifiedDate={data.createdDate}
                                                        index={index}
                                                        id={data.id}
                                                    />
                                                );
                                            })
                                        }
                                        <CustomSlider
                                            dots={false}
                                            draggable={true}
                                            className="slider-profile-lembaga"
                                            autoplaySpeed={5000}
                                            autoplay={true}
                                            pauseOnFocus={true}
                                            pauseOnHover={true}
                                            slidesToScroll={1}
                                            arrows={true}
                                            slidesToShow={width > 1200 ? 3 : 2 && width > 768 ? 2 : 1}
                                            type="lembaga"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={3} md={12}>
                                <Grid item xs={12}>
                                    <About/>
                                    <DaftarLembaga data={dataSumberDaya}/>
                                    {poster.loading ? '' : poster.dataSide > 0 ? (
                                        <div className="widget widget-img">
                                            {poster.dataSide.map((o) => {
                                                return (
                                                    <img
                                                        src={`${configData.BASE_URL}/api/misc/${o.image}`}
                                                        alt="" style={{width: "100%"}}/>
                                                )
                                            })}
                                        </div>
                                    ) : ""}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <footer style={{background: "white", width: "100%", float: "left"}}>
                <div className="footer-sec">
                    <div className="container">
                        <Footer/>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Beranda_Page;

