import React, { useEffect, useState } from "react";
import "../Styles/Slider_Styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardSlider from "./CardBanner_Com";
import ProfileLembaga from "./ProfileLembaga_Com";

import configData from "../config.json";
import { GetDataLSM } from "../Logic/APIHandler_Logic";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

function Slider_Com(props) {
  const settings = {
    dots: props.dots,
    draggable: props.draggable,
    className: props.className,
    autoplaySpeed: props.autoplaySpeed,
    pauseOnHover: props.pauseOnHover,
    pauseOnFocus: props.pauseOnFocus,
    slidesToScroll: props.slidesToScroll,
    slidesToShow: props.slidesToShow,
    arrows: props.arrows,
    speed: 10,
    autoplay: props.autoplay,
    lazyload: true,
  };
  const [dataLembaga, setDataLembaga] = useState([]);
  const [dataBanner, setDataBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataProfile, setProfile] = useState();

  useEffect(() => {
    let token = new Cookies().get("Profile")
    if (token !== undefined) {
      if (token !== "null" && token !== "undefined") {
        let decode = jwt_decode(token);
        setProfile(decode.BEARER);
      }
    }
    GetDataLSM(
      { "role.equals": "ROLE_USER", "sort": "createdDate,desc", page: 0, size: 10 },
      true
    ).then((result) => {
      setDataLembaga(result)
    });
    getDataBanner.then((result) => {
      setDataBanner(result);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {props.type === "banner" ? (
        <>
          {isLoading ? (
            ""
          ) : (
            <>
              <div className="post-slider">
                <Banner data={dataBanner} settings={settings} />
              </div>
            </>
          )}
        </>
      ) : props.type === "lembaga" ? (
        <div className="post-profile-lembaga">
          {dataLembaga.length !== 0 ? (
            <>
              <div className="header-profile-lembaga">
                <h3>Data Lembaga Terkini</h3>
              </div>
              <SliderLembaga
                  data={dataLembaga}
                  settings={settings}
                  dataProfile={dataProfile}
              />
            </>
          ) : null}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

const Banner = (props) => {
  const [change, setChange] = useState(false);
  const [card, setCard] = useState([]);

  useEffect(() => {
    setCard(props.data);
  }, [props]);

  return (
    <Slider
      afterChange={() =>
        setTimeout(() => {
          setChange(true);
        }, 10000)
      }
      beforeChange={(current) => setChange(false)}
      {...props.settings}
    >
      {card.map((data, index) => (
        <CardSlider
          key={index}
          image={data.image}
          deskripsi={data.deskripsi}
          judul={data.headLine}
          label={data.labelAksi}
          url={data.url}
          change={change}
        />
      ))}
    </Slider>
  );
};

const SliderLembaga = (props) => {
  return (
    <Slider {...props.settings} infinite={props.data.length > 3}>
      {props.data.map((data, index) => {
        return (
          <ProfileLembaga
            key={index}
            dataProfile={props.dataProfile}
            name={data.lsm.namaOrganisasi}
            image={data.lsm.logoProfile}
            desc={data.desc}
            city={data.lsm.kota}
            link={data.id}
            panggilan={data.namaPanggilan}
            index={index}
            pages={0}
          />
        );
      })}
    </Slider>
  );
};

export default Slider_Com;

const getDataBanner = fetch(`${configData.BASE_URL}/api/banner`, {
  method: "GET",
}).then((response) => response.json()); ;
