import { Grid, Button } from "@material-ui/core";
import LinkLSM from "../../Assets/Logo/wd-logo.png";
import { useHistory } from "react-router";
import React, { useEffect } from "react";
import "../../Styles/Sukses_Styles.css";

import configData from "../../config.json"

function Sukses_Form({ props, match }) {
  const history = useHistory();

  useEffect(() => {
    try {
      fetch(
        `${configData.BASE_URL}/api/lsm/activation/${match.params.code}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
        });
    } catch (error) {}
  }, [match.params.code]);

  return (
    <div className="sukses-page">
      <div className="sukses-page-inner">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <div className="modal-header">
            <img src={LinkLSM} alt="" />
          </div>
          <div className="modal-body">
            <h1>Akun anda berhasil diverifikasi.</h1>{" "}
          </div>
          <div className="modal-footer btn-login">
            <Button onClick={() => history.push("/login")} variant="contained">
              Tutup
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default Sukses_Form;
