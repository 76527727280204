import {Grid, Button, TextField, InputAdornment} from "@material-ui/core";
import React, {useRef} from "react";
import LinkLSM from "../Assets/Logo/logo-header.jpeg";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router";

import configData from "../config.json"

function Konfirmasi({props, match}) {
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
        reset,
    } = useForm();
    const password = useRef({});
    password.current = watch("password", "");


    const onSubmit = async (data) => {
        try {
            await fetch(
                `${configData.BASE_URL}/api/lsm/reset_password/${match.params.code}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        reset();
                        history.push("/login");
                    } else (alert("Gagal reset password"))
                });
        } catch (error) {
        }
    };
    return (
        <div className="sukses-page">
            <div className="sukses-page-inner">
                <Grid xs={12}
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-header">
                            <img src={LinkLSM} alt=""/>
                        </div>
                        <div className="modal-body">
                            <Grid item={true} xs={12}>
                                <TextField
                                    className={
                                        errors.password ? "input-login error" : "input-login"
                                    }
                                    type="password"
                                    //   disabled={loading ? true : false}
                                    variant="outlined"
                                    name="password"
                                    placeholder="Password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HttpsOutlinedIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register("password", {
                                        required: "Mohon masukan Password.",
                                        minLength: {
                                            value: 8,
                                            message: "Password kurang dari 8 karakter.",
                                        },
                                    })}
                                />
                                <p
                                    className={errors.password ? "error-msg" : "error-msg exit"}
                                    id="hidden"
                                >
                                    {errors.password
                                        ? errors.password.message
                                        : "Password kurang dari 8 karakter."}
                                </p>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <TextField
                                    className={
                                        errors.confirmPassword ? "input-login error" : "input-login"
                                    }
                                    type="password"
                                    variant="outlined"
                                    name="confirmPassword"
                                    //   disabled={loading ? true : false}
                                    placeholder="Ulangi Password"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HttpsOutlinedIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register("confirmPassword", {
                                        validate: (value) =>
                                            value === password.current || "Mohon masukan Password.",
                                    })}
                                />
                                <p
                                    className={
                                        errors.confirmPassword ? "error-msg" : "error-msg exit"
                                    }
                                    id="hidden">
                                    Password tidak sesuai.
                                </p>
                            </Grid>
                        </div>
                        <div className="modal-footer btn-login">
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </div>
                    </form>
                </Grid>
            </div>
        </div>
    );
}

export default Konfirmasi;
